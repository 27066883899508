import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RessourceService } from 'src/app/service/ressource/ressource.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() isDetails: boolean;
  @Output() setTempEmitter = new EventEmitter<Attachment[]>();

  public attachementsGroups: ImmoRessource[] = [];
  public filesAmount = 0;
  public onloadSrc: any[] = [];
  public titelBild: Attachment;
  public innenansichten: Attachment[] = [];
  public ausenansichten: Attachment[] = [];
  public grundrisse: Attachment[] = [];
  public karteUndPlaene: Attachment[] = [];
  public bilder: Attachment[] = [];
  public documents: Attachment[] = [];
  public dialogQuerySubsription: Subscription;

  constructor(
    private aService: AttachmentService,
    private rService: RessourceService,
    private dService: DialogService,
  ) { }

  ngOnInit(): void {
    if(this.immoForm.controls.attachments.value.length > 0){
      this.orderAttachements();
      if(this.isDetails) this.setTempEmitter.emit(this.immoForm.controls.attachments.value)
    }
    this.rService.getRessource('anhang_gruppe').subscribe((result) => {
      this.attachementsGroups = result;
    })
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'remove_attachement'){
          this.removeDocument(value.submit_value);
        }
      }
    });
  }

  orderAttachements() {
    for(let document of this.immoForm.controls.attachments.value){
      let index;
      switch (document.anhang_gruppe_id) {
        case 1: this.titelBild = document; break;
        case 2: index = this.innenansichten.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.innenansichten.push(document); break;
        case 3: index = this.ausenansichten.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.ausenansichten.push(document); break;
        case 4: index = this.grundrisse.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.grundrisse.push(document); break;
        case 5: index = this.karteUndPlaene.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.karteUndPlaene.push(document); break;
        case 6: index = this.bilder.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.bilder.push(document); break;
        case 7: index = this.documents.findIndex(doc => doc.anhang_id == document.anhang_id); if(index < 0) this.documents.push(document); break;
        default: break;
      }
    }
    this.sortAttachements(this.innenansichten);
    this.sortAttachements(this.ausenansichten);
    this.sortAttachements(this.grundrisse);
    this.sortAttachements(this.karteUndPlaene);
    this.sortAttachements(this.bilder);
    this.sortAttachements(this.documents);
  }

  sortAttachements(data: any) {
    data.sort((a,b) => {
      if(a.sort_value > b.sort_value) return 1;
      if(a.sort_value < b.sort_value) return -1;
      return 0;
    });
  }

  get f() {
    return this.immoForm.controls;
  }

  async onFileDropped(data: any, anhang_gruppe_id: number) {
    if(data){
      let attachement;
      if((data.name.split('.')[1] == 'jpg' || data.name.split('.')[1] == 'png' || data.name.split('.')[1] == 'jpeg') && anhang_gruppe_id != 7){
        attachement = new Attachment(0,this.immoForm.controls.immobilien_id.value,data.name.split('.')[0],data.name.split('.')[1],anhang_gruppe_id,null,data.data)
      } else if((data.name.split('.')[1] == 'docx' || data.name.split('.')[1] == 'pdf' || data.name.split('.')[1] == 'csv') && anhang_gruppe_id == 7){
        attachement = new Attachment(0,this.immoForm.controls.immobilien_id.value,data.name.split('.')[0],data.name.split('.')[1],anhang_gruppe_id,null,data.data)
      }
      if(attachement){
        let anhang_id = await this.uploadAttachement(attachement);
        if(anhang_id){
          attachement.anhang_id = anhang_id;
          this.immoForm.controls.attachments.value.push(attachement);
          this.orderAttachements();
        }
      }
    }
  }

  async uploadFile(event: any, anhang_gruppe_id: number) {
    this.onloadSrc = [];
    if(event.target.files && event.target.files.length != 0){
      this.filesAmount = event.target.files.length;
      for(let i = 0; i < this.filesAmount; i++){
        await this.readFile(event.target.files[i], anhang_gruppe_id)
      }
      event.target.value = '';
      let temp: any[] = [];
      for(let file of this.onloadSrc){
        file.anhang_id = await this.uploadAttachement(file);
        if(file.anhang_id){
          temp.push(file);
        }
      }
      let attachements = this.immoForm.controls.attachments.value.concat(temp);
      this.immoForm.controls.attachments.setValue(attachements);
      this.orderAttachements();
    }
  }
  async readFile(file: any, anhang_gruppe_id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        if(anhang_gruppe_id == 1){
          for(let attachement of this.immoForm.controls.attachments.value){
            if(attachement.anhang_gruppe_id == 1){
              this.removeDocument(attachement);
            }
          }
        }
        this.onloadSrc.push(new Attachment(0,this.immoForm.controls.immobilien_id.value,file.name.split('.')[0],file.name.split('.')[1],anhang_gruppe_id, null, event.target.result as string))
        resolve(true)
      }
      reader.readAsDataURL(file);
      reader.onerror = (event: any) => {
        reject(false);
      }
    })
  }
  uploadAttachement(attachement: Attachment): Promise<number> {
    return new Promise<any>((resolve, reject) => {
      this.aService.createAttachment(attachement).subscribe((result) => {
        if(result){
          result[result.length - 1].anhang = attachement.anhang;
          resolve(result[result.length - 1].anhang_id)
        }
      })
    }).catch((error) => {
      console.log(error);
    });
  }

  openDialogRemoveAttachement(attachment: Attachment) {
    this.dService.openQuery(
      {
        title: 'Anhang entfernen?',
        message: '',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Entfernen',
        typ: 'remove_attachement',
        submit_value: attachment,
      }
    )
  }

  removeDocument(attachment: Attachment) {
    this.aService.deleteAttachment(attachment.anhang_id).subscribe((success) => {
      if(success){
        let index;
        switch (attachment.anhang_gruppe_id) {
          case 1: this.titelBild = null; break;
          case 2: index = this.innenansichten.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.innenansichten.splice(index, 1); break;
          case 3: index = this.ausenansichten.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.ausenansichten.splice(index, 1); break;
          case 4: index = this.grundrisse.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.grundrisse.splice(index, 1); break;
          case 5: index = this.karteUndPlaene.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.karteUndPlaene.splice(index, 1); break;
          case 6: index = this.bilder.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.bilder.splice(index, 1); break;
          case 7: index = this.documents.findIndex(doc => doc.anhang_id == attachment.anhang_id);
                  if(index > -1) this.documents.splice(index, 1); break;
          default: break;
        }
        let temp = this.immoForm.controls.attachments.value;
        index = temp.findIndex(doc => doc.anhang_id == attachment.anhang_id);
        if(index > -1){
          temp.splice(index, 1);
          this.immoForm.controls.attachments.setValue(temp);
        }
      }
    })
  }
}
