import { Flaeche } from "../Flaeche";

export class FlaecheFactory {
    static jsonFactory(rawData: any): Flaeche[] {
        let datas: Flaeche[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Flaeche {
        return new Flaeche(
            rawData.wohnflaeche,
            rawData.nutzflaeche,
            rawData.gesamtflaeche,
            rawData.grundstuecksflaeche,
            rawData.sonstflaeche,
            rawData.anzahl_zimmer,
            rawData.anzahl_schlafzimmer,
            rawData.anzahl_badezimmer,
            rawData.anzahl_sep_wc,
            rawData.anzahl_balkone,
            rawData.anzahl_terrassen,
            rawData.anzahl_logia,
            rawData.balkon_terrasse_flaeche,
            rawData.gartenflaeche,
            rawData.kellerflaeche,
            rawData.dachbodenflaeche,
            rawData.beheizbare_flaeche,
        );
    }
}
