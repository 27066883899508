<div class="dialog_container">
  <div class="icon_cancel" (click)="closeEmitter.emit(false)">&#10005;</div>
  <form [formGroup]="passwordForm" class="dialog_box">
    <div class="lbl_bold data_box_title">Passwort ändern</div>
    <div class="lbl_bold dropdown_title_lbl" [ngStyle]="{'color': submitted && passwordForm.controls.password_old.invalid ? 'var(--invalid-color)' : ''}">Altes Passwort</div>
    <div class="input_container" [ngClass]="submitted && passwordForm.controls.password_old.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/password.svg">
        <input type="{{showPasswordTwo ? 'text' : 'password'}}" placeholder="Geben Sie Ihr Passwort ein" formControlName="password_old">
        <img (click)="showPasswordTwo = !showPasswordTwo" class='icon_eye' [src]="showPasswordTwo ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
    </div>
    <div class="lbl_bold dropdown_title_lbl" [ngStyle]="{'color': submitted && passwordForm.controls.password_new.invalid ? 'var(--invalid-color)' : ''}">Neues Passwort</div>
    <div class="input_container" [ngClass]="submitted && passwordForm.controls.password_new.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/password.svg">
        <input type="{{showPasswordOne ? 'text' : 'password'}}" placeholder="Geben Sie Ihr Passwort ein" formControlName="password_new">
        <img (click)="showPasswordOne = !showPasswordOne" class='icon_eye' [src]="showPasswordOne ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
    </div>
    <div class="btn_submit" (click)="onSubmit()">Speichern</div>
</form>
</div>
