import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit, AfterViewInit {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  public max = 2765;
  public counter_1 = 2765;

  constructor() { }

  ngOnInit(): void {
    if(this.f.kurzbeschreibung.value && this.f.kurzbeschreibung.value.length > 0) this.calcCharCounter(this.f.kurzbeschreibung.value);
  }

  ngAfterViewInit(): void {}

  get f() {
    return this.immoForm.controls;
  }

  setDiscription(num: number, text: string) {
    if(num == 1){
      this.f.kurzbeschreibung.setValue(text);
      this.calcCharCounter(this.f.kurzbeschreibung.value);
    }
    if(num == 2) this.f.lagebeschreibung.setValue(text);
    if(num == 3) this.f.objektbeschreibung.setValue(text);
    if(num == 4) this.f.sonstige_angaben.setValue(text);
  }

  calcCharCounter(txt: string) {
    let count = this.max - txt.length;
    this.counter_1 = count >= 0 ? count : 0;
    if(this.counter_1 >= 0){
      this.counter_1 = this.max - txt.length;
    }
  }
}
