import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user/user.service';
@Component({
  selector: 'app-interface-titlebar',
  templateUrl: './interface-titlebar.component.html',
  styleUrls: ['./interface-titlebar.component.scss']
})
export class InterfaceTitlebarComponent implements OnInit {
  @Input() title: string;
  public showDropOne = false;
  public subscription: Subscription;
  public user: User;

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private lService: LoginService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.uService.getUser().subscribe((result) => this.user = result);
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropOne){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropOne = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.subscription) this.subscription.unsubscribe();
  }

  logout() {
    this.uService.user$.next(null);
    this.lService.logout();
  }

  goToCreate() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['immobilien/anlegen']));
  }
}
