<div>
  <div class="lbl_title_big_color lbl_title">Flächen</div>
  <form [formGroup]="immoForm" class="">
    <div class="lbl_title_big_color lbl_small_title">Anzahl der Zimmer</div>
    <div class="grid">
      <div class="column">
        <div class="lbl_bold dropdown_title_lbl">Zimmer</div>
        <div class="input_container" [ngClass]="f.anzahl_zimmer.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_zimmer">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Schlafzimmer</div>
        <div class="input_container" [ngClass]="f.anzahl_schlafzimmer.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_schlafzimmer">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Badezimmer</div>
        <div class="input_container" [ngClass]="f.anzahl_badezimmer.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_badezimmer">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Seperate WC</div>
        <div class="input_container" [ngClass]="f.anzahl_sep_wc.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_sep_wc">
        </div>
      </div>
      <div class="column">
        <div class="lbl_bold dropdown_title_lbl">Anzahl der Balkone</div>
        <div class="input_container" [ngClass]="f.anzahl_balkone.value != null ? 'inputfield_valid' : ''">
          <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_balkone">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Anzahl der Terrassen</div>
        <div class="input_container" [ngClass]="f.anzahl_terrassen.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 3" pattern="\d+" step="1" min="1" formControlName="anzahl_terrassen">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Loggia</div>
        <div class="input_container" [ngClass]="f.anzahl_logia.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2" pattern="\d+" step="1" min="1" formControlName="anzahl_logia">
        </div>
      </div>
    </div>
    <div class="lbl_title_big_color lbl_small_title">Objekt-Flächen in m²</div>
    <div class="grid">
      <div class="column">
        <div class="lbl_bold dropdown_title_lbl">Gesamtfläche</div>
        <div class="input_container" [ngClass]="f.gesamtflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 90.00" step="0.1" min="0.1" formControlName="gesamtflaeche">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Wohnfläche</div>
        <div class="input_container" [ngClass]="f.wohnflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 80.00" step="0.1" min="0.1" formControlName="wohnflaeche">
        </div>
      </div>
      <div class="column">
        <div class="lbl_bold dropdown_title_lbl">Grundstücksfläche</div>
        <div class="input_container" [ngClass]="f.grundstuecksflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 130.50" step="0.1" min="0.1" formControlName="grundstuecksflaeche">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Nutzfläche</div>
        <div class="input_container" [ngClass]="f.nutzflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 100.00" step="0.1" min="0.1" formControlName="nutzflaeche">
        </div>
      </div>
    </div>
    <div class="lbl_title_big_color lbl_small_title">Weitere Flächen in m²</div>
    <div class="grid">
      <div class="column">
        <div class="lbl_bold dropdown_title_lbl">Gartenfläche</div>
        <div class="input_container" [ngClass]="f.gartenflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 130.50" step="0.1" min="0.1" formControlName="gartenflaeche">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Kellerfläche</div>
        <div class="input_container" [ngClass]="f.kellerflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 100.00" step="0.1" min="0.1" formControlName="kellerflaeche">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Dachbodenfläche</div>
        <div class="input_container" [ngClass]="f.dachbodenflaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 20.00" step="0.1" min="0.1" formControlName="dachbodenflaeche">
        </div>
        <!-- <div class="flex_slider">
          <div class="lbl_regular">Keller</div>
          <div class="slider_container" (click)="f.keller.setValue(!f.keller.value)">
            <div class="slider_still" [ngClass]="f.keller.value ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="f.keller.value ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div>
        <div class="flex_slider">
          <div class="lbl_regular">Dachboden</div>
          <div class="slider_container" (click)="f.dachboden.setValue(!f.dachboden.value)">
            <div class="slider_still" [ngClass]="f.dachboden.value ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="f.dachboden.value ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div>
        <div class="flex_slider">
          <div class="lbl_regular">Garten</div>
          <div class="slider_container" (click)="f.garten.setValue(!f.garten.value)">
            <div class="slider_still" [ngClass]="f.garten.value ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="f.garten.value ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div> -->
      </div>
      <div class="column">
        <!-- <div class="flex_slider">
          <div class="lbl_regular">Keller</div>
          <div class="slider_container" (click)="f.keller.setValue(!f.keller.value)">
            <div class="slider_still" [ngClass]="f.keller.value ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="f.keller.value ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div> -->
        <!-- <div class="flex_slider">
          <div class="lbl_regular">Balkone & Terrassen</div>
          <div class="slider_container" (click)="f.balkone_terrassen.setValue(!f.balkone_terrassen.value)">
            <div class="slider_still" [ngClass]="f.balkone_terrassen.value ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="f.balkone_terrassen.value ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div> -->
        <div class="lbl_bold dropdown_title_lbl">Sonstige Fläche</div>
        <div class="input_container" [ngClass]="f.sonstige_flaeche.value != null ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 20.00" step="0.1" min="0.1" formControlName="sonstige_flaeche">
        </div>
        <div class="lbl_bold dropdown_title_lbl">Gesamtfläche Balkone / Terrassen</div>
        <div class="input_container" [ngClass]="f.bt_flaeche.value != null ? 'inputfield_valid' : ''">
          <input type="number" placeholder="z.B. 10.00" step="0.1" min="0.1" formControlName="bt_flaeche">
        </div>
      </div>
    </div>
  </form>
</div>
