export class Flaeche {
    constructor(
        public wohnflaeche: number,
        public nutzflaeche: number,
        public gesamtflaeche: number,
        public grundstuecksflaeche: number,
        public sonstflaeche: number,
        public anzahlZimmer: number,
        public anzahlSchlafzimmer: number,
        public anzahlBadezimmer: number,
        public anzahlSep_wc: number,
        public anzahlBalkone: number,
        public anzahlTerrassen: number,
        public anzahlLogia: number,
        public balkonTerrasseFlaeche: number,
        public gartenflaeche: number,
        public kellerflaeche: number,
        public dachbodenflaeche: number,
        public beheizbare_flaeche: number,

        public anzahlStellplaetze?: number,
        public keller?: boolean,
        public dachboden?: boolean,
        public garten?: boolean,
        public logia?: boolean,
    ){}
}
