import { Platform } from "../Platform";

export class PlatformFactory {
    static jsonFactory(rawData: any): Platform[] {
        let datas: Platform[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Platform {
        return new Platform(
            rawData.platform_id,
            rawData.platform,
        );
    }
}