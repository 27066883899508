import { User } from "../User";
import { CompanyFactory } from "./CompanyFactory";
import { TitleFactory } from "../../ressource/factory/TitleFactory";
import { SalutationFactory } from "../../ressource/factory/SalutationFactory";
import { RoleFactory } from "../../ressource/factory/RoleFactory";
import { PlatformFactory } from "../../ressource/factory/PlatformFactory";

export class UserFactory {
    static jsonFactory(rawData: any): User[] {
        let datas: User[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): User {
        return new User(
            rawData.user_id,
            CompanyFactory.jsonFactoryOne(rawData.company),
            PlatformFactory.jsonFactory(rawData.platforms),
            SalutationFactory.jsonFactoryOne(rawData.salutation),
            rawData.first_name,
            rawData.last_name,
            rawData.email,
            rawData.phone,
            RoleFactory.jsonFactory(rawData.roles),
            TitleFactory.jsonFactoryOne(rawData.title),
        );
    }
}