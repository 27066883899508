import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';
import { Nutzungsart } from 'src/app/buisness-object/ressource/Nutzungsart';
import { Objektart } from 'src/app/buisness-object/ressource/Objektart';
import { Vermarktungsart } from 'src/app/buisness-object/ressource/Vermarktungsart';
import { Zustand } from 'src/app/buisness-object/ressource/Zustand';
import { RessourceService } from 'src/app/service/ressource/ressource.service';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() nutzungsarten: ImmoRessource[];
  @Input() objektarten: ImmoRessource[];
  @Input() vermarktungsarten: ImmoRessource[];
  @Input() bundeslaender: ImmoRessource[];
  @Input() bebaubarNach: ImmoRessource[];

  public showDropOne = false;
  public showDropTwo = false;
  public showDropThree = false;
  public showDropFour = false;
  public showDropFive = false;
  public showDropSix = false;
  public showDropSeven = false;
  public showDropEight = false;
  public zustaende: ImmoRessource[] = [];
  public erschliessungen: ImmoRessource[] = [];
  public objektartenFiltered: ImmoRessource[] = [];

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  @ViewChild('dropdown_4') dropdown_4: ElementRef;
  @ViewChild('dropdown_5') dropdown_5: ElementRef;
  @ViewChild('dropdown_6') dropdown_6: ElementRef;
  @ViewChild('dropdown_7') dropdown_7: ElementRef;
  @ViewChild('dropdown_8') dropdown_8: ElementRef;
  public listener: () => void;

  constructor(
    private rService: RessourceService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropOne = false;
      if(this.showDropTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropTwo = false;
      if(this.showDropThree && !this.dropdown_3.nativeElement.contains(e.target)) this.showDropThree = false;
      if(this.showDropFour && !this.dropdown_4.nativeElement.contains(e.target)) this.showDropFour = false;
      if(this.showDropFive && !this.dropdown_5.nativeElement.contains(e.target)) this.showDropFive = false;
      if(this.showDropSix && !this.dropdown_6.nativeElement.contains(e.target)) this.showDropSix = false;
      if(this.showDropSeven && !this.dropdown_7.nativeElement.contains(e.target)) this.showDropSeven = false;
      if(this.showDropEight && !this.dropdown_8.nativeElement.contains(e.target)) this.showDropEight = false;
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  get f() {
    return this.immoForm.controls;
  }

  getDataServerside() {
    forkJoin({
      one: this.rService.getRessource('zustand'),
      two: this.rService.getRessource('erschliessung'),
    }).subscribe((result) => {
      if(result){
        this.zustaende = result.one;
        this.erschliessungen = result.two;
        this.sortRessource(this.zustaende);
        this.sortRessource(this.erschliessungen);
        if(this.immoForm.valid){
          this.setNutzungsart(this.immoForm.controls.nutzungsart.value)
        }
      }
    });
  }

  sortRessource(data: any) {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
  }

  setNutzungsart(item: ImmoRessource) {
    this.f.nutzungsart.setValue(item);
    this.objektartenFiltered = [];
    if(item.id == 1){
      for(let art of this.objektarten){
        if(art.id == 5 || art.id == 6 || art.id == 7 || art.id == 8 || art.id == 9 || art.id == 10 || art.id == 11) this.objektartenFiltered.push(art);
      }
    } else {
      for(let art of this.objektarten){
        if(art.id == 1 || art.id == 2 || art.id == 3 || art.id == 11) this.objektartenFiltered.push(art);
      }
    }
    this.sortRessource(this.objektartenFiltered);
    let index = this.objektartenFiltered.findIndex(art => art.id == 11);
    if(index > -1){
      this.objektartenFiltered.push(this.objektartenFiltered[index]);
      this.objektartenFiltered.splice(index, 1);
    }
  }

}
