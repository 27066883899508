import { Salutation } from "../Salutation";

export class SalutationFactory {
    static jsonFactory(rawData: any): Salutation[] {
        let datas: Salutation[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Salutation {
        return new Salutation(
            rawData.salutation_id,
            rawData.salutation,
        );
    }
}