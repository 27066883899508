import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Immobilie } from 'src/app/buisness-object/immobilie/Immobilie';

@Component({
  selector: 'app-immo-element',
  templateUrl: './immo-element.component.html',
  styleUrls: ['./immo-element.component.scss']
})
export class ImmoElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() immo: Immobilie;
  @Input() even: boolean;

  public showActions = false;
  public showActionStatus = false;

  @ViewChild('action_box') action_box: ElementRef;
  public listener: () => void;

  @Output() openDetailsEmitter = new EventEmitter<Immobilie>();
  @Output() editEmitter = new EventEmitter<Immobilie>();
  @Output() deleteEmitter = new EventEmitter<Immobilie>();
  @Output() changeStatusEmitter = new EventEmitter<{status: number, id: number}>();
  @Output() copyEmitter = new EventEmitter<Immobilie>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showActions){
        let isInside = this.action_box.nativeElement.contains(e.target);
        if(!isInside){
          this.showActions = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  cutStr(str: string, len: number): string {
    if(str.length > len){
      return str.slice(0,len) + ' ...'
    } else {
      return str;
    }
  }

  changeStatus(status: number) {
    this.changeStatusEmitter.emit({status: status, id: this.immo.immobilieId});
    this.showActionStatus = false;
    this.showActions = false;
  }
}
