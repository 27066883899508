import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent implements OnInit, AfterViewInit, OnDestroy {
  public showDropOne = false;
  public subscription: Subscription;
  public title = '';
  public user: User;
  public currenUrl = '';
  public prevoisUrl = '';

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private lService: LoginService,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe((value) => {
      if(value instanceof NavigationStart){
        if(value.url == '/dashboard'){
          this.title = 'Dashboard';
        } else if(value.url == '/immobilien'){
          this.title = 'Immobilien';
        } else if(value.url == '/immobilien/anlegen'){
          this.title = 'Immobilie anlegen';
        } else if(value.url == '/profile'){
          this.title = 'Profil';
        } else if(value.url == '/interface'){
          this.title = 'Schnitstellen';
        }
      }
    });
    this.uService.getUser().subscribe((user) => {
      if(user){
        this.user = user;
      }
    });
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropOne){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropOne = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
    this.subscription.unsubscribe();
  }

  logout() {
    this.lService.logout();
  }

  goToCreate() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['immobilien/anlegen']));
  }
}
