<div>
  <div class="lbl_title_big_color lbl_title">Preise in €</div>
  <form [formGroup]="immoForm" class="">
    <div class="lbl_title_big_color lbl_small_title">Preisangabe für Mietbeträge und etwaige Nebenkosten</div>
    <div class="grid">
      <div class="flex" (click)="f.preisangabe_quadratmeter.setValue(!f.preisangabe_quadratmeter.value)">
        <div class="slider_container">
          <div class="slider_still" [ngClass]="f.preisangabe_quadratmeter.value ? 'slider_still_on' : ''"></div>
          <div class="slider_move" [ngClass]="f.preisangabe_quadratmeter.value ? 'slider_off' : 'slider_on'"></div>
        </div>
        <div class="lbl_regular">Preisangaben für das ganze Objekt</div>
      </div>
      <div></div>
      <div class="flex" (click)="f.preisangabe_quadratmeter.setValue(!f.preisangabe_quadratmeter.value)">
        <div class="slider_container">
          <div class="slider_still" [ngClass]="f.preisangabe_quadratmeter.value ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.preisangabe_quadratmeter.value ? 'slider_on' : 'slider_off'"></div>
        </div>
        <div class="lbl_regular">Preisangaben je m²</div>
      </div>
      <div class="flex" (click)="selectionPreisPerAnfrage()">
        <div class="slider_container">
          <div class="slider_still" [ngClass]="f.preis_per_anfrage.value ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.preis_per_anfrage.value ? 'slider_on' : 'slider_off'"></div>
        </div>
        <div class="lbl_regular">Preis per Anfrage</div>
      </div>
    </div>
    <div *ngIf="f.vermarktungsart.value?.id == 1 || isDeveloper" class="grid">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreis</div>
        <div class="input_container" [ngClass]="f.kaufpreis.value && f.kaufpreis.errors?.valid_number?.valid != false ? 'inputfield_valid' : (f.preis_per_anfrage.value == true ? 'input_disabled' : '')">
            <input type="number" placeholder="z.B. 280000.00" formControlName="kaufpreis">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreisbrutto</div>
        <div class="input_container" [ngClass]="f.kaufpreisbrutto.value && f.kaufpreisbrutto.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 300000.00" formControlName="kaufpreisbrutto">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Erschliessungskosten</div>
        <div class="input_container"  [ngClass]="f.erschliessungskosten.value != null && f.erschliessungskosten.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 230.00" formControlName="erschliessungskosten">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreis pro m²</div>
        <div class="input_container"  [ngClass]="f.kaufpreis_pro_qm.value && f.kaufpreis_pro_qm.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 230.55" formControlName="kaufpreis_pro_qm">
        </div>
      </div>
      <div style="margin-top: 50px; ">
        <div class="lbl_bold lbl_small_title">Provision</div>
        <div class="input_container"  [ngClass]="f.provision.value && f.provision.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 1800.00" formControlName="provision">
        </div>
      </div>
    </div>
    <div *ngIf="f.vermarktungsart.value?.id == 2" class="grid">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Gesamtmiete (inkl. MwSt)</div>
        <div class="input_container" [ngClass]="f.monatlichekostenbrutto.value && f.monatlichekostenbrutto.errors?.valid_number?.valid != false ? 'inputfield_valid' : (f.preis_per_anfrage.value == true ? 'input_disabled' : '')">
            <input type="number" placeholder="z.B. 900.00" formControlName="monatlichekostenbrutto">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Betriebskosten netto</div>
        <div class="input_container" [ngClass]="f.nebenkosten.value && f.nebenkosten.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 100.00" formControlName="nebenkosten">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Mehrwertsteuer in €</div>
        <div class="input_container" [ngClass]="f.mehrwertsteuer.value && f.mehrwertsteuer.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" min="0" max="100" placeholder="z.B. 20.00" formControlName="mehrwertsteuer">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Heizkosten netto</div>
        <div class="input_container" [ngClass]="f.heitzkosten.value && f.heitzkosten.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 100.00" formControlName="heitzkosten">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Hauptmietzins/Miete netto</div>
        <div class="input_container_readonly" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="Errechnet sich durch die Eingaben" formControlName="netto_kaltemiete" readonly>
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Sonstige Kosten netto</div>
        <div class="input_container" [ngClass]="f.mietzuschlag.value && f.mietzuschlag.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 300.00" formControlName="mietzuschlag">
        </div>
      </div>
      <div style="margin-top: 50px;">
        <div class="lbl_bold lbl_small_title">Kaution</div>
        <div class="input_container" [ngClass]="f.kaution.value != null && f.kaution.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 2000.00" formControlName="kaution">
        </div>
      </div>
      <div style="margin-top: 50px; ">
        <div class="lbl_bold lbl_small_title">Provision</div>
        <div class="input_container"  [ngClass]="f.provision.value != null && f.provision.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 1500.00" formControlName="provision">
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="grid">
  <div>
    <div *ngIf="f.vermarktungsart.value?.id == 1">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreis</div>
        <div class="input_container" [ngClass]="f.kaufpreis.value && f.kaufpreis.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 280000.00" formControlName="kaufpreis">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreisbrutto</div>
        <div class="input_container" [ngClass]="f.kaufpreisbrutto.value && f.kaufpreisbrutto.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 300000.00" formControlName="kaufpreisbrutto">
        </div>
      </div>
      <div style="margin-top: 70px; ">
        <div class="lbl_bold lbl_small_title">Provision</div>
        <div class="input_container"  [ngClass]="f.provision.value && f.provision.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 1800.00" formControlName="provision">
        </div>
      </div>
    </div>
    <div *ngIf="f.vermarktungsart.value?.id == 2">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Gesamtmiete incl. MwSt</div>
        <div class="input_container" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 800.00" formControlName="netto_kaltemiete">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Betriebskosten netto</div>
        <div class="input_container" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 800.00" formControlName="netto_kaltemiete">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Heizkosten netto</div>
        <div class="input_container" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 800.00" formControlName="netto_kaltemiete">
        </div>
      </div>
      <div style="margin-top: 70px;">
        <div class="lbl_bold lbl_small_title">Kaution</div>
        <div class="input_container" [ngClass]="f.kaution.value != null && f.kaution.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 400.00" formControlName="kaution">
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="f.vermarktungsart.value?.id == 1">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Erschliessungskosten</div>
        <div class="input_container"  [ngClass]="f.erschliessungskosten.value != null && f.erschliessungskosten.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 230.00" formControlName="erschliessungskosten">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Kaufpreis pro m²</div>
        <div class="input_container"  [ngClass]="f.kaufpreis_pro_qm.value && f.kaufpreis_pro_qm.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 230.55" formControlName="kaufpreis_pro_qm">
        </div>
      </div>
    </div>
    <div *ngIf="f.vermarktungsart.value?.id == 2">
      <div>
        <div class="lbl_bold dropdown_title_lbl">Hauptmietzins/Miete netto</div>
        <div class="input_container" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 800.00" formControlName="netto_kaltemiete">
        </div>
      </div>
      <div>
        <div class="lbl_bold dropdown_title_lbl">Sonstige netto und Steuer</div>
        <div class="input_container" [ngClass]="f.netto_kaltemiete.value && f.netto_kaltemiete.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 800.00" formControlName="netto_kaltemiete">
        </div>
      </div>
      <div style="margin-top: 70px; ">
        <div class="lbl_bold lbl_small_title">Provision</div>
        <div class="input_container"  [ngClass]="f.provision.value != null && f.provision.errors?.valid_number?.valid != false ? 'inputfield_valid' : ''">
            <input type="number" placeholder="z.B. 1800.00" formControlName="provision">
        </div>
      </div>
    </div>
  </div>
</div> -->
