<div class="btn_container">
    <div class="btn_lbl" (click)="cancelCreateAction()">Abbrechen</div>
    <div class="flex">
        <div class="btn_lbl btn_draft" [ngClass]="!isValidBasedata ? 'btn_lbl_inactive' : ''" (click)="isValidBasedata ? saveAsDraftEmitter.emit() : null">Entwurf speichern</div>
        <div class="btn_submit" [ngClass]="page != 6 ? 'btn_submit_inactive' : ''" (click)="isValidBasedata ? paging(true) : null">Veröffentlichen</div>
    </div>
</div>
<div #scrollContainer class="scroll_container">
  <div class="data_box_container">
    <div class="header">
      <div class="dot_container">
          <div class="dot" [ngClass]="page == 1 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 2 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 3 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 4 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 5 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 6 ? 'dot_active' : ''"></div>
      </div>
    </div>
    <app-basic *ngIf="page == 1"
        [immoForm]="immoForm"
        [submitted]="submitted"
        [nutzungsarten]="nutzungsarten"
        [objektarten]="objektarten"
        [vermarktungsarten]="vermarktungsarten"
        [bundeslaender]="bundeslaender"
        [bebaubarNach]="bebaubarNach"
    ></app-basic>
    <app-price *ngIf="page == 2"
        [immoForm]="immoForm"
        [submitted]="submitted"
    ></app-price>
    <app-areas *ngIf="page == 3"
        [immoForm]="immoForm"
        [submitted]="submitted"
    ></app-areas>
    <app-furnishing *ngIf="page == 4"
        [immoForm]="immoForm"
        [submitted]="submitted"
        [selectedAusstattung]="selectedAusstattung"
    ></app-furnishing>
    <app-description *ngIf="page == 5"
        [immoForm]="immoForm"
        [submitted]="submitted"
    ></app-description>
    <app-attachments *ngIf="page == 6"
        [immoForm]="immoForm"
        [submitted]="submitted"
    ></app-attachments>
    <div class="footer">
      <div class="btn_lbl btn_header" [ngClass]="page == 1 ? 'btn_lbl_inactive' : ''" (click)="paging(false)">Zurück</div>
      <!-- <div class="dot_container">
          <div class="dot" [ngClass]="page == 1 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 2 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 3 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 4 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 5 ? 'dot_active' : ''"></div>
          <div class="dot" [ngClass]="page == 6 ? 'dot_active' : ''"></div>
      </div> -->
      <div class="btn_lbl btn_header" [ngClass]="!isValidBasedata ? 'btn_lbl_inactive' : ''" (click)="isValidBasedata ? paging(true) : null">{{page == 6 ? 'Veröffentlichen' : 'Weiter'}}</div>
    </div>
  </div>
</div>
