export class Filter {
  public tabFilter: number;
  public selectedValue_1: any;
  public selectedValue_2: any;
  public selectedValue_3: any;
  public selectedValue_4: any;
  public sortAscend: boolean;
  public selectedSortValue: any;
  public sortOptions: SortOption[] = [];

  constructor(
    tabFilter: number,
    sortValues: string[]
  ){
    this.tabFilter = tabFilter;
    this.selectedValue_1 = null;
    this.selectedValue_2 = null;
    this.selectedValue_3 = null;
    this.selectedValue_4 = null;
    this.sortAscend = false;
    this.setSortOptions(sortValues);
  }

  reset() {
    this.tabFilter = 1;
    this.selectedValue_1 = null;
    this.selectedValue_2 = null;
    this.selectedValue_3 = null;
    this.selectedValue_4 = null;
    this.sortAscend = false;
    this.selectedSortValue = null;
  }

  setSortOptions(sortValues: string[]) {
    for(let i = 0; i < sortValues.length; i++){
      this.sortOptions.push(new SortOption(i+1,sortValues[i]));
    }
    this.selectedSortValue = this.sortOptions[0];
  }
}

class SortOption {
  constructor(
    public id: number,
    public name: string,
  ){}
}
