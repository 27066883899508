import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Role } from 'src/app/buisness-object/ressource/Role';
import { Salutation } from 'src/app/buisness-object/ressource/Salutation';
import { Title } from 'src/app/buisness-object/ressource/Title';
import { Company } from 'src/app/buisness-object/user/Company';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';
import { RessourceService } from 'src/app/service/ressource/ressource.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  public showDropOne = false;
  public showDropTwo = false;
  public isEdit = false;
  public submitted = false;
  public showPasswordDialog = false;
  public user: User;
  public titles: Title[] = [];
  public salutations: Salutation[] = [];
  public roles: Role[] = [];
  public userForm: UntypedFormGroup;

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private uService: UserService,
    private rService: RessourceService,
    private lService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.configFormGroup();
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropOne){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropOne = false;
        }
      }
      if(this.showDropTwo){
        let isInside = this.dropdown_2.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropTwo = false;
        }
      }
    })
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  getDataServerside() {
    forkJoin([
      this.uService.getUser(),
      this.rService.getRessource('title'),
      this.rService.getRessource('salutation'),
      this.rService.getRessource('role'),
    ]).subscribe(([user, titles, salus, roles]:[User, Title[], Salutation[], Role[]]) => {
      if(user && titles && salus && roles){
        this.user = user;
        this.titles = titles;
        this.salutations = salus;
        this.roles = roles;
        this.sortAlphabetical(this.titles)
      }
    });
  }

  sortAlphabetical(data: any) {
    data.sort((a,b) => {
      if(a.title > b.title) return 1;
      if(a.title < b.title) return -1;
      return 0;
    });
    let index = data.findIndex(item => item.titleId == 1)
    if(index > -1){
      data.unshift(data[index]);
      data.splice(index + 1, 1)
    }
  }

  configFormGroup() {
    this.userForm = this.formBuilder.group({
      salutation: [null, Validators.required],
      title: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      user_phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      company_name: [null, Validators.required],
      atu: [null],
      street: [null, Validators.required],
      house_number: [null, Validators.required],
      postal_code: [null, Validators.required],
      city: [null, Validators.required],
      phone_company: [null, Validators.required],
      email_company: [null, Validators.required],
      url: [null, Validators.required],
    });
  }

  get f() {
    return this.userForm.controls;
  }

  fillUserForm() {
    this.f.title.setValue(this.user.title),
    this.f.salutation.setValue(this.user.salutation),
    this.f.first_name.setValue(this.user.firstName);
    this.f.last_name.setValue(this.user.lastName);
    this.f.user_phone.setValue(this.user.phone);
    this.f.email.setValue(this.user.email);
    this.f.company_name.setValue(this.user.company.companyName);
    this.f.atu.setValue(this.user.company.atu);
    this.f.street.setValue(this.user.company.street);
    this.f.house_number.setValue(this.user.company.houseNumber);
    this.f.postal_code.setValue(this.user.company.postalCode);
    this.f.city.setValue(this.user.company.city);
    this.f.phone_company.setValue(this.user.company.phone);
    this.f.email_company.setValue(this.user.company.email);
    this.f.url.setValue(this.user.company.url);
  }

  editUser() {
    this.fillUserForm();
    this.isEdit = true;
  }

  updateUser() {
    this.submitted = true;
    if(this.userForm.valid){
      let company = new Company(
        this.user.company.companyId,
        this.f.company_name.value,
        this.f.street.value,
        this.f.house_number.value,
        this.f.city.value,
        this.f.postal_code.value,
        this.f.email_company.value,
        this.f.phone_company.value,
        this.f.url.value,
        this.f.atu.value,
      );
      let user = new User(
        Number(localStorage.getItem('user_id')),
        company,
        [],
        this.f.salutation.value,
        this.f.first_name.value,
        this.f.last_name.value,
        this.f.email.value,
        this.f.user_phone.value,
        this.user.roles,
        this.f.title.value
      );
      this.uService.updateUser(user).subscribe((result) => {
        if(result){
          this.user = result;
          this.userForm.reset();
          this.submitted = false;
          this.isEdit = false;
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Profil aktualisiert',
            success: true
          });
        }
      })
    }
  }

  cancelEdit() {
    this.userForm.reset();
    this.submitted = false;
    this.isEdit = false;
  }

  passwordReseted(yes: boolean) {
    this.showPasswordDialog = false;
  }

  getRolename(user: User): string {
    if(user?.roles[0]?.roleId == 1) return 'Mitarbeiter'
    else if(user?.roles[0]?.roleId == 2) return 'Administrator'
    else '';
  }

  goToLink(link: string) {
    if(link.split('//')[0] == 'https:'){
      window.open(link, '_blank')
    } else {
      window.open('https://'+link, '_blank')
    }
  }
}
