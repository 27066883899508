import { UntypedFormGroup } from '@angular/forms';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, AfterViewInit {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  public isDeveloper = false;
  public preisInputDisabled = false;

  constructor() { }

  ngOnInit(): void {
    if(this.f.preis_per_anfrage.value == true){
      if(this.f.vermarktungsart.value.id == 1){
        this.f.kaufpreis.disable();
      } else if(this.f.vermarktungsart.value.id == 2){
        this.f.monatlichekostenbrutto.disable();
      }
    }
    this.isDeveloper = environment.developer;
    //this.preisInputDisabled = this.f.preis_per_anfrage.value;
  }

  ngAfterViewInit(): void {
    this.immoForm.controls['monatlichekostenbrutto'].valueChanges.subscribe(value => {this.calculateHauptmietzinsNetto()});
    this.immoForm.controls['nebenkosten'].valueChanges.subscribe(value => {this.calculateHauptmietzinsNetto()});
    this.immoForm.controls['heitzkosten'].valueChanges.subscribe(value => {this.calculateHauptmietzinsNetto()});
    this.immoForm.controls['mietzuschlag'].valueChanges.subscribe(value => {this.calculateHauptmietzinsNetto()});
    this.immoForm.controls['mehrwertsteuer'].valueChanges.subscribe(value => {this.calculateHauptmietzinsNetto()});
  }

  calculateHauptmietzinsNetto() {
    if(this.immoForm.controls.monatlichekostenbrutto.value && (
      this.immoForm.controls.nebenkosten.value ||
      this.immoForm.controls.heitzkosten.value ||
      this.immoForm.controls.mietzuschlag.value ||
      this.immoForm.controls.mehrwertsteuer.value
    )){
      let mieteNetto = this.immoForm.controls.monatlichekostenbrutto.value - this.immoForm.controls.mehrwertsteuer.value;
      mieteNetto = mieteNetto -
        this.immoForm.controls.nebenkosten.value -
        this.immoForm.controls.heitzkosten.value -
        this.immoForm.controls.mietzuschlag.value
      this.immoForm.controls.netto_kaltemiete.setValue(mieteNetto);
    } else {
      this.immoForm.controls.netto_kaltemiete.setValue(null);
    }
  }

  get f() {
    return this.immoForm.controls;
  }

  selectionPreisPerAnfrage() {
    this.f.preis_per_anfrage.setValue(!this.f.preis_per_anfrage.value);
    if(this.f.vermarktungsart.value.id == 1){
      if(this.f.preis_per_anfrage.value == true){
        this.f.kaufpreis.setValue(null);
        this.f.kaufpreis.disable();
      }
      else this.f.kaufpreis.enable();
    } else if(this.f.vermarktungsart.value.id == 2){
      if(this.f.preis_per_anfrage.value == true){
        this.f.monatlichekostenbrutto.setValue(null);
        this.f.monatlichekostenbrutto.disable();
      }
      else this.f.monatlichekostenbrutto.enable();
    }
  }
}
