import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Immobilie } from 'src/app/buisness-object/immobilie/Immobilie';
import { Filter } from 'src/app/helper/Filter';
import { PageHandler } from 'src/app/helper/PagerHandler';

@Component({
  selector: 'app-immo-list',
  templateUrl: './immo-list.component.html',
  styleUrls: ['./immo-list.component.scss']
})
export class ImmoListComponent implements OnInit, AfterViewInit, OnDestroy {
  public showDropTwo = false;
  @ViewChild('dropdown_1') dropdown_1 : ElementRef;
  public listener: () => void;
  public showSearchInput = false;

  @Input() immos: Immobilie[];
  @Input() pageInfo: string;
  @Input() pageHandler: PageHandler;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() filter: Filter;
  @Input() amount: number;
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() openDetailsEmitter = new EventEmitter<Immobilie>();
  @Output() editEmitter = new EventEmitter<Immobilie>();
  @Output() deleteEmitter = new EventEmitter<Immobilie>();
  @Output() sortEmitter = new EventEmitter<any>();
  @Output() changeStatusEmitter = new EventEmitter<any>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() copyEmitter = new EventEmitter<Immobilie>();

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropTwo){
        let isInside = this.dropdown_1.nativeElement.contains(e.target);
        if(!isInside){
          this.showDropTwo = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  openSearchAction() {
    this.showSearchInput = !this.showSearchInput;
    setTimeout(() => {
      if(this.showSearchInput) document.getElementById('search').focus();
    }, 200);
  }
}
