<div>
  <div class="lbl_title_big_color lbl_title">Objekt-Beschreibung</div>
  <form [formGroup]="immoForm" class="grid">
  <div class="lbl_bold dropdown_title_lbl" style="margin-top: 20px;">Lage</div>
    <textarea #textField_2
      id="textField_2"
      class="text_area"
      [ngClass]="!f.lagebeschreibung.value ? 'text_area_placeholder' : ''"
      [ngStyle]="{'border-color': f.lagebeschreibung.value ? 'var(--color-6)' : ''}"
      placeholder="Geben Sie hier die Lagebeschreibung zum Objekt ein."
      formControlName="lagebeschreibung"></textarea>
    <div class="lbl_bold dropdown_title_lbl">Objektbeschreibung</div>
    <textarea #textField_3
      id="textField_3"
      class="text_area"
      [ngClass]="!f.objektbeschreibung.value ? 'text_area_placeholder' : ''"
      [ngStyle]="{'border-color': f.objektbeschreibung.value ? 'var(--color-6)' : ''}"
      placeholder="Geben Sie hier die lange Beschreibung zum Objekt ein."
      formControlName="objektbeschreibung"></textarea>
    <div class="lbl_bold dropdown_title_lbl" style="margin-top: 20px;">Sonstige Angaben</div>
    <textarea #textField_4
      id="textField_4"
      class="text_area"
      [ngClass]="!f.sonstige_angaben.value ? 'text_area_placeholder' : ''"
      [ngStyle]="{'border-color': f.sonstige_angaben.value ? 'var(--color-6)' : ''}"
      placeholder="Geben Sie hier sonstige Beschreibungen zum Objekt ein."
      formControlName="sonstige_angaben"></textarea>
    <!-- <div class="column">
      <div class="flex">
        <div class="lbl_bold dropdown_title_lbl">Kurzbeschreibung</div>
        <div class="lbl_regular" style="margin-right: 15px;">{{counter_1}} Zeichen übrig</div>
      </div>
      <textarea #textField_1
        id="textField_1"
        class="text_area"
        [ngClass]="!f.kurzbeschreibung.value ? 'text_area_placeholder' : ''"
        [ngStyle]="{'border-color': f.kurzbeschreibung.value ? 'var(--color-6)' : ''}"
        placeholder="Geben Sie hier die Kurzbeschreibung zum Objekt ein..."
        [maxlength]="max"
        formControlName="kurzbeschreibung"
        (input)="setDiscription(1, textField_1.value)"></textarea>
      <div class="lbl_bold dropdown_title_lbl" style="margin-top: 20px;">Lage</div>
      <textarea #textField_2
        id="textField_2"
        class="text_area"
        [ngClass]="!f.lagebeschreibung.value ? 'text_area_placeholder' : ''"
        [ngStyle]="{'border-color': f.lagebeschreibung.value ? 'var(--color-6)' : ''}"
        placeholder="Geben Sie hier die Kurzbeschreibung zum Objekt ein..."
        formControlName="lagebeschreibung"></textarea>
    </div>
    <div class="column">
      <div class="lbl_bold dropdown_title_lbl">Objektbeschreibung</div>
      <textarea #textField_3
        id="textField_3"
        class="text_area"
        [ngClass]="!f.objektbeschreibung.value ? 'text_area_placeholder' : ''"
        [ngStyle]="{'border-color': f.objektbeschreibung.value ? 'var(--color-6)' : ''}"
        placeholder="Geben Sie hier die lange Beschreibung des Objekts ein..."
        formControlName="objektbeschreibung"></textarea>
      <div class="lbl_bold dropdown_title_lbl" style="margin-top: 20px;">Sonstige Angaben</div>
      <textarea #textField_4
        id="textField_4"
        class="text_area"
        [ngClass]="!f.sonstige_angaben.value ? 'text_area_placeholder' : ''"
        [ngStyle]="{'border-color': f.sonstige_angaben.value ? 'var(--color-6)' : ''}"
        placeholder="Geben Sie hier weitere Beschreibungen an..."
        formControlName="sonstige_angaben"></textarea>
    </div> -->
  </form>
</div>
