import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UpdateService } from './service/udpate/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'immobase-backend';

  constructor(
    private updateService: UpdateService
  ){}

  ngOnInit(): void {
    this.updateService.checkForUpdates()
  }

  showMenu(outlet: RouterOutlet): boolean {
    if(!outlet?.activatedRouteData?.isLogin){
      return true;
    }
    return false;
  }
}
