import { Role } from "../Role";

export class RoleFactory {
    static jsonFactory(rawData: any): Role[] {
        let datas: Role[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Role {
        return new Role(
            rawData.role_id,
            rawData.role_name,
        );
    }
}