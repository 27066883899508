import { BebaubarNachFactory } from "../../ressource/factory/BebaubarNachFactory";
import { ErschliessungFactory } from "../../ressource/factory/ErschliessungFactory";
import { RessourceFactory } from "../../ressource/factory/RessourceFactory";
import { VerkaufsstatusFactory } from "../../ressource/factory/VerkaufsstatusFactory";
import { ZustandFactory } from "../../ressource/factory/ZustandFactory";
import { ZustandImmobilie } from "../ZustandImmobilie";

export class ZustandImmobilieFactory {
    static jsonFactory(rawData: any): ZustandImmobilie[] {
        let datas: ZustandImmobilie[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): ZustandImmobilie {
        return new ZustandImmobilie(
            rawData.baujahr,
            RessourceFactory.jsonFactoryOne(rawData.zustand,''),
            rawData.ist_altbau,
            RessourceFactory.jsonFactoryOne(rawData.bebaubar_nach,''),
            RessourceFactory.jsonFactoryOne(rawData.erschliessung,''),
            RessourceFactory.jsonFactoryOne(rawData.verkaufsstatus,''),
            rawData.mietdauer,
            rawData.min_mietdauer,
            rawData.max_mietdauer,
        );
    }
}
