import { RessourceFactory } from './../../buisness-object/ressource/factory/RessourceFactory';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { TitleFactory } from 'src/app/buisness-object/ressource/factory/TitleFactory';
import { SalutationFactory } from 'src/app/buisness-object/ressource/factory/SalutationFactory';
import { RoleFactory } from 'src/app/buisness-object/ressource/factory/RoleFactory';
import { PlatformFactory } from 'src/app/buisness-object/ressource/factory/PlatformFactory';
import { NutzungsartFactory } from 'src/app/buisness-object/ressource/factory/NutzungsartFactory';

@Injectable({
  providedIn: 'root'
})
export class RessourceService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getRessource(typ: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/ressource/' + typ, {headers});
    return observable.pipe(
      map((result: any) => {
        return this.getData(result, typ);
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  getData(result: any, typ: string): any {
    if(typ == 'title') return TitleFactory.jsonFactory(result.title);
    else if(typ == 'salutation') return SalutationFactory.jsonFactory(result.salutation);
    else if(typ == 'role') return RoleFactory.jsonFactory(result.role);
    else if(typ == 'platform') return PlatformFactory.jsonFactory(result.platform);
    else if(typ == 'nutzungsart') return RessourceFactory.jsonFactory(result.nutzungsart);
    else if(typ == 'verkaufsstatus') return RessourceFactory.jsonFactory(result.verkaufsstatus);
    else if(typ == 'objektart') return RessourceFactory.jsonFactory(result.objektart);
    else if(typ == 'vermarktungsart') return RessourceFactory.jsonFactory(result.vermarktungsart);
    else if(typ == 'ausricht_balkon_terrasse') return RessourceFactory.jsonFactory(result.ausricht_balkon_terrasse);
    else if(typ == 'ausbaustufe') return RessourceFactory.jsonFactory(result.ausbaustufe)
    else if(typ == 'bauweise') return RessourceFactory.jsonFactory(result.bauweise);
    else if(typ == 'dachform') return RessourceFactory.jsonFactory(result.dachform);
    else if(typ == 'energietyp') return RessourceFactory.jsonFactory(result.energietyp);
    else if(typ == 'stellplatzart') return RessourceFactory.jsonFactory(result.stellplatzart);
    else if(typ == 'heizungsart') return RessourceFactory.jsonFactory(result.heizungsart);
    else if(typ == 'befeuerung') return RessourceFactory.jsonFactory(result.befeuerung);
    else if(typ == 'ausstattung') return RessourceFactory.jsonFactory(result.ausstattung, 'ausstattung');
    else if(typ == 'ausstattung_kategorie') return RessourceFactory.jsonFactory(result.ausstattung_kategorie);
    else if(typ == 'erschliessung') return RessourceFactory.jsonFactory(result.erschliessung);
    else if(typ == 'zustand') return RessourceFactory.jsonFactory(result.zustand);
    else if(typ == 'bundesland') return RessourceFactory.jsonFactory(result.bundesland);
    else if(typ == 'bebaubar_nach') return RessourceFactory.jsonFactory(result.bebaubar_nach);
    else if(typ == 'moebliert') return RessourceFactory.jsonFactory(result.moebliert);
    else if(typ == 'unterkellert') return RessourceFactory.jsonFactory(result.unterkellert);
    else if(typ == 'anhang_gruppe') return RessourceFactory.jsonFactory(result.anhang_gruppe);
  }
}
