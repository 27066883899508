<div>
  <div class="lbl_title_big_color lbl_title">Anhänge</div>
  <form [formGroup]="immoForm" class="grid">
    <div class="column">
      <div class="lbl_bold">Titelbild</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,1)">
        <div class="no_image_wrapper" *ngIf="!titelBild">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie ein Bild hier her,</div>
          <div class="lbl_regular">um es hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="titelBild" [src]="titelBild.anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="titelBild" (click)="openDialogRemoveAttachement(titelBild)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_titelbild.click()">Datei auswählen</div>
      <input #selectFile_titelbild type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,1)" multiple="false" style="display: none;">
    </div>
    <div class="column">
      <div class="lbl_bold">Innenansichten</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,2)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_innenansichten.click()">Dateien auswählen</div>
      <input #selectFile_innenansichten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event, 2)" multiple="" style="display: none;">
      <div *ngIf="innenansichten.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of innenansichten">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Außenansichten</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,3)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_ausenansichten.click()">Dateien auswählen</div>
      <input #selectFile_ausenansichten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event, 3)" multiple="" style="display: none;">
      <div *ngIf="ausenansichten.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of ausenansichten">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Grundriss</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,4)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_grundriss.click()">Dateien auswählen</div>
      <input #selectFile_grundriss type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,4)" multiple="" style="display: none;">
      <div *ngIf="grundrisse.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of grundrisse">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Karten & Lagepläne</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,5)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_karten.click()">Dateien auswählen</div>
      <input #selectFile_karten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,5)" multiple="" style="display: none;">
      <div *ngIf="karteUndPlaene.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of karteUndPlaene">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Bilder</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,6)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_bilder.click()">Dateien auswählen</div>
      <input #selectFile_bilder type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,6)" multiple="" style="display: none;">
      <div *ngIf="bilder.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of bilder">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Dokumente</div>
      <div class="dropbox_doc" appDnd (fileDroppedEmitter)="onFileDropped($event,7)">
        <img class="box_icon" src="../../../../../assets/upload.svg">
        <div class="lbl_regular">Ziehen Sie Dokumente hier her,</div>
        <div class="lbl_regular">um sie hochzuladen.</div>
      </div>
      <div class="btn_lbl" (click)="selectFile_dokumente.click()">Dateien auswählen</div>
      <input #selectFile_dokumente type="file" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event,7)" multiple="" style="display: none;">
      <div class="lbl_regular" *ngIf="documents.length == 0">Hochgeladene Dokumente erscheinen hier.</div>
      <div class="docs_container">
        <div *ngFor="let doc of documents" class="view_document">
          <img class="doc_icon" src="../../../../../assets/document.svg">
          <div class="doc_txt">{{doc.anhang_title}}.{{doc.format}}</div>
          <div class="doc_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>




<!-- <div>
  <div class="lbl_title_big_color lbl_title">Anhänge</div>
  <form [formGroup]="immoForm" class="grid">
    <div class="column">
      <div class="lbl_bold">Titelbild</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,1)">
        <div class="no_image_wrapper" *ngIf="!titelBild">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie ein Bild hier her,</div>
          <div class="lbl_regular">um es hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="titelBild" [src]="titelBild.anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="titelBild" (click)="openDialogRemoveAttachement(titelBild)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_titelbild.click()">Datei auswählen</div>
      <input #selectFile_titelbild type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,1)" multiple="false" style="display: none;">
    </div>
    <div class="column">
      <div class="lbl_bold">Innenansichten</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,2)">
        <div class="no_image_wrapper">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
      </div>
      <div class="btn_lbl" (click)="selectFile_innenansichten.click()">Dateien auswählen</div>
      <input #selectFile_innenansichten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event, 2)" multiple="" style="display: none;">
      <div *ngIf="innenansichten.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of innenansichten">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Außenansichten</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,3)">
        <div class="no_image_wrapper" *ngIf="ausenansichten.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="ausenansichten.length != 0" [src]="ausenansichten[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="ausenansichten.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_ausenansichten.click()">Dateien auswählen</div>
      <input #selectFile_ausenansichten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event, 3)" multiple="" style="display: none;">
      <div *ngIf="ausenansichten.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of ausenansichten | slice:1">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Grundriss</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,4)">
        <div class="no_image_wrapper" *ngIf="grundrisse.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="grundrisse.length != 0" [src]="grundrisse[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="grundrisse.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_grundriss.click()">Dateien auswählen</div>
      <input #selectFile_grundriss type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,4)" multiple="" style="display: none;">
      <div *ngIf="grundrisse.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of grundrisse | slice:1">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Karten & Lagepläne</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,5)">
        <div class="no_image_wrapper" *ngIf="karteUndPlaene.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="karteUndPlaene.length != 0" [src]="karteUndPlaene[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="karteUndPlaene.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_karten.click()">Dateien auswählen</div>
      <input #selectFile_karten type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,5)" multiple="" style="display: none;">
      <div *ngIf="karteUndPlaene.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of karteUndPlaene | slice:1">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Bilder</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event,6)">
        <div class="no_image_wrapper" *ngIf="bilder.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="bilder.length != 0" [src]="bilder[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 3%; left: 98%;" *ngIf="bilder.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile_bilder.click()">Dateien auswählen</div>
      <input #selectFile_bilder type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event,6)" multiple="" style="display: none;">
      <div *ngIf="bilder.length == 0" class="no_file_txt">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of bilder | slice:1">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(image)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Dokumente</div>
      <div class="dropbox_doc" appDnd (fileDroppedEmitter)="onFileDropped($event,7)">
        <img class="box_icon" src="../../../../../assets/upload.svg">
        <div class="lbl_regular">Ziehen Sie Dokumente hier her,</div>
        <div class="lbl_regular">um sie hochzuladen.</div>
      </div>
      <div class="btn_lbl" (click)="selectFile_dokumente.click()">Dateien auswählen</div>
      <input #selectFile_dokumente type="file" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event,7)" multiple="" style="display: none;">
      <div class="lbl_regular" *ngIf="documents.length == 0">Hochgeladene Dokumente erscheinen hier.</div>
      <div class="docs_container">
        <div *ngFor="let doc of documents" class="view_document">
          <img class="doc_icon" src="../../../../../assets/document.svg">
          <div class="doc_txt">{{doc.anhang_title}}.{{doc.format}}</div>
          <div class="doc_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </form>
</div> -->


<!-- <div>
  <div class="lbl_title_big_color lbl_title">Anhänge</div>
  <form [formGroup]="immoForm" class="grid">
    <div class="column">
      <div class="lbl_bold">Titelbild</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event)">
        <div class="no_image_wrapper" *ngIf="images.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie ein Bild hier her,</div>
          <div class="lbl_regular">um es hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="images.length != 0" [src]="images[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 2%; left: 97%;" *ngIf="images.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile.click()">Dateien auswählen</div>
      <input #selectFile type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event)" multiple="" style="display: none;">
      <div class="images_container">
        <div class="view_image" *ngFor="let image of images | slice:1" (click)="setAsTitleImage(image)">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Bilder</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event)">
        <div class="no_image_wrapper" *ngIf="images.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="images.length != 0" [src]="images[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 2%; left: 97%;" *ngIf="images.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile.click()">Dateien auswählen</div>
      <input #selectFile type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event)" multiple="" style="display: none;">
      <div *ngIf="images.length == 0">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
        <div class="lbl_regular">Sie können dann Ihr Titelbild auswählen.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of images | slice:1" (click)="setAsTitleImage(image)">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Innansichten</div>
      <div class="dropbox_image" appDnd (fileDroppedEmitter)="onFileDropped($event)">
        <div class="no_image_wrapper" *ngIf="images.length == 0">
          <img class="box_icon" src="../../../../../assets/upload.svg">
          <div class="lbl_regular">Ziehen Sie Bilder hier her,</div>
          <div class="lbl_regular">um sie hochzuladen.</div>
        </div>
        <img class="title_image" *ngIf="images.length != 0" [src]="images[0].anhang">
      </div>
      <div class="image_remove_icon" style="top: 2%; left: 97%;" *ngIf="images.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <div class="btn_lbl" (click)="selectFile.click()">Dateien auswählen</div>
      <input #selectFile type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event)" multiple="" style="display: none;">
      <div *ngIf="images.length == 0">
        <div class="lbl_regular">Hochgeladene Fotos erscheinen hier.</div>
        <div class="lbl_regular">Sie können dann Ihr Titelbild auswählen.</div>
      </div>
      <div class="images_container">
        <div class="view_image" *ngFor="let image of images | slice:1" (click)="setAsTitleImage(image)">
          <img class="image" [src]="image.anhang">
          <div class="image_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="lbl_bold">Dokumente</div>
      <div class="dropbox_doc" appDnd (fileDroppedEmitter)="onFileDropped($event)">
        <img class="box_icon" src="../../../../../assets/upload.svg">
        <div class="lbl_regular">Ziehen Sie Dokumente hier her,</div>
        <div class="lbl_regular">um sie hochzuladen.</div>
      </div>
      <div class="btn_lbl" (click)="selectFile_2.click()">Dateien auswählen</div>
      <input #selectFile_2 type="file" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="uploadFile($event)" multiple="" style="display: none;">
      <div class="lbl_regular" *ngIf="documents.length == 0">Hochgeladene Dokumente erscheinen hier.</div>
      <div class="docs_container">
        <div *ngFor="let doc of documents" class="view_document">
          <img class="doc_icon" src="../../../../../assets/document.svg">
          <div class="doc_txt">{{doc.anhang_title}}.{{doc.format}}</div>
          <div class="doc_remove_icon" (click)="openDialogRemoveAttachement(doc)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <g id="cancel_icon" transform="translate(-570.5 -1251.5)">
                <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
                <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </form>
</div> -->
