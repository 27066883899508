import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public showPassword_1 = false;
  public showPassword_2 = false;
  public form: UntypedFormGroup;
  public submitted = false;
  public success = false;
  public invalidToken = false;
  public tokenExpired = false;
  public routeUrl: string;
  public token: string = '';
  public routeSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private lService: LoginService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password_1: [null,[Validators.required,Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}$')]],
      password_2: [null,[Validators.required,Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}$')]],
    })
    this.routeUrl = this.activateRoute.snapshot.url[0].path;
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(!params.token){
        this.invalidToken = true;
      } else {
        this.token = params.token;
      }
    })
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid || this.form.controls.password_1.value != this.form.controls.password_2.value){
      return;
    } else {
      this.lService.resetPassword(this.token, this.form.controls.password_1.value).subscribe((response) => {
        if(response){
          this.success = true;
        } else {
          this.tokenExpired = true;
        }
      })
    }
  }

  toLogin() {
    this.router.navigate(['login']);
  }

  goToLink() {
    window.open('https://software-entwicklung-graz.at/', '_blank')
  }
}
