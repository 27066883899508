import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RessourceService } from 'src/app/service/ressource/ressource.service';
import { forkJoin, of } from 'rxjs';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';

@Component({
  selector: 'app-furnishing',
  templateUrl: './furnishing.component.html',
  styleUrls: ['./furnishing.component.scss']
})
export class FurnishingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() selectedAusstattung: ImmoRessource[];

  public showDropOne = false;
  public showDropTwo = false;
  public showDropThree = false;
  public showDropFour = false;
  public showDropFive = false;
  public showDropSix = false;
  public showDropSeven = false;
  public showDropEight = false;
  public showDropNine = false;
  public showDropTen = false;
  public showDropEleven = false;
  public ausrichtungenBalkonTerrasse: ImmoRessource[] = [];
  public ausbaustufen: ImmoRessource[] = [];
  public bauweisen: ImmoRessource[] = [];
  public dachformen: ImmoRessource[] = [];
  public energietypen: ImmoRessource[] = [];
  public stellplatzarten: ImmoRessource[] = [];
  public kuechen: ImmoRessource[] = [];
  public heizungsarten: ImmoRessource[] = [];
  public befeuerungen: ImmoRessource[] = [];
  public ausstattungen: ImmoRessource[] = [];
  public ausstattungsKategorien: ImmoRessource[] = [];
  public moeblierungen: ImmoRessource[] = [];
  public keller: ImmoRessource[] = [];
  public ressources: ImmoRessource[][] = [];

  public austattungenBad: ImmoRessource[] = [];
  public austattungenSonstige: ImmoRessource[] = [];
  public austattungenKueche: ImmoRessource[] = [];
  public austattungenBoden: ImmoRessource[] = [];

  @ViewChild('dropdown_1') dropdown_1: ElementRef;
  @ViewChild('dropdown_2') dropdown_2: ElementRef;
  @ViewChild('dropdown_3') dropdown_3: ElementRef;
  @ViewChild('dropdown_4') dropdown_4: ElementRef;
  @ViewChild('dropdown_5') dropdown_5: ElementRef;
  @ViewChild('dropdown_6') dropdown_6: ElementRef;
  @ViewChild('dropdown_7') dropdown_7: ElementRef;
  @ViewChild('dropdown_8') dropdown_8: ElementRef;
  @ViewChild('dropdown_9') dropdown_9: ElementRef;
  @ViewChild('dropdown_10') dropdown_10: ElementRef;
  public listener: () => void;

  constructor(
    private renderer: Renderer2,
    private rService: RessourceService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  getDataServerside() {
    forkJoin({
      one: this.rService.getRessource('ausricht_balkon_terrasse'),
      two: this.rService.getRessource('ausbaustufe'),
      three: this.rService.getRessource('bauweise'),
      four: this.rService.getRessource('dachform'),
      five: this.rService.getRessource('energietyp'),
      six: this.rService.getRessource('stellplatzart'),
      seven: this.rService.getRessource('heizungsart'),
      eight: this.rService.getRessource('befeuerung'),
      nine: this.rService.getRessource('ausstattung'),
      ten: this.rService.getRessource('ausstattung_kategorie'),
      eleven: this.rService.getRessource('moebliert'),
    }).subscribe((result) => {
        if(result){
          this.ausrichtungenBalkonTerrasse = result.one;
          this.ausbaustufen = result.two;
          this.bauweisen = result.three;
          this.dachformen = result.four;
          this.energietypen = result.five;
          this.stellplatzarten = result.six;
          this.heizungsarten = result.seven;
          this.befeuerungen = result.eight;
          this.ausstattungen = result.nine;
          this.ausstattungsKategorien = result.ten;
          this.moeblierungen = result.eleven;
          this.splitAusstatungen();
          this.sortAll();
          this.setAusstattungSelection();
      }
    }, (err) => console.log(err));
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropOne = false;
      if(this.showDropTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropTwo = false;
      if(this.showDropThree && !this.dropdown_3.nativeElement.contains(e.target)) this.showDropThree = false;
      if(this.showDropFour && !this.dropdown_4.nativeElement.contains(e.target)) this.showDropFour = false;
      if(this.showDropFive && !this.dropdown_5.nativeElement.contains(e.target)) this.showDropFive = false;
      if(this.showDropSix && !this.dropdown_6.nativeElement.contains(e.target)) this.showDropSix = false;
      if(this.showDropSeven && !this.dropdown_7.nativeElement.contains(e.target)) this.showDropSeven = false;
      if(this.showDropEight && !this.dropdown_8.nativeElement.contains(e.target)) this.showDropEight = false;
      if(this.showDropNine && !this.dropdown_9.nativeElement.contains(e.target)) this.showDropNine = false;
      if(this.showDropTen && !this.dropdown_10.nativeElement.contains(e.target)) this.showDropTen = false;
    });
    if(this.f.sonstige_angaben_austattung.value && this.f.sonstige_angaben_austattung.value.length > 0){
      document.getElementById('textField').innerHTML = this.f.sonstige_angaben_austattung.value;
    }
  }

  ngOnDestroy(): void {
    if(this.listener){
      this.listener();
    }
  }

  splitAusstatungen() {
    for(let ausstattung of this.ausstattungen){
      if(ausstattung.categorie_id == 1) this.austattungenBad.push(ausstattung);
      else if(ausstattung.categorie_id == 2) this.austattungenSonstige.push(ausstattung);
      else if(ausstattung.categorie_id == 3) this.austattungenKueche.push(ausstattung);
      else if(ausstattung.categorie_id == 4) this.austattungenBoden.push(ausstattung);
    }
    this.austattungenBad = this.sortAusstattung(this.austattungenBad);
    this.austattungenSonstige = this.sortAusstattung(this.austattungenSonstige);
    this.austattungenKueche = this.sortAusstattung(this.austattungenKueche);
    this.austattungenBoden = this.sortAusstattung(this.austattungenBoden);
  }
  sortAusstattung(items: any[]): any[] {
    items = items.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    return items;
  }

  setAusstattungSelection() {
    if(this.immoForm.controls.ausstattung.value){
      for(let item of this.ausstattungen){
        for(let selected of this.immoForm.controls.ausstattung.value){
          if(item.id == selected.id){
            item.is_selected = true;
          }
        }
      }
    }
  }

  sortAll() {
    this.ausrichtungenBalkonTerrasse.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.ausbaustufen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.bauweisen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.bauweisen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.dachformen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.energietypen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.stellplatzarten.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.heizungsarten.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
    this.befeuerungen.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
  }

  get f() {
    return this.immoForm.controls;
  }

  setRessource(typ: string, ressource: ImmoRessource) {
    if(typ == 'ausrichtung_balkon_terrasse') this.f.ausrichtung_balkon_terrasse.setValue(ressource);
    if(typ == 'ausbaustufe') this.f.ausbaustufe.setValue(ressource);
    if(typ == 'bauweise') this.f.bauweise.setValue(ressource);
    if(typ == 'dachform') this.f.dachform.setValue(ressource);
    if(typ == 'energietyp') this.f.energietyp.setValue(ressource);
    if(typ == 'stellplatzart') this.f.stellplatzart.setValue(ressource);
    if(typ == 'kueche') this.f.kueche.setValue(ressource);
    if(typ == 'heizungsart') this.f.heizungsart.setValue(ressource);
    if(typ == 'befeuerung') this.f.befeuerung.setValue(ressource);
  }

  setDiscription(text: string) {
    this.f.sonstige_angaben_austattung.setValue(text);
  }

  handleAusstattung(ausstattung: ImmoRessource) {
    ausstattung.is_selected = !ausstattung.is_selected;
    if(ausstattung.is_selected){
      //this.selectedAusstattung.push(ausstattung);
      //this.f.ausstattung.setValue(this.selectedAusstattung);
      this.f.ausstattung.value.push(ausstattung);
    } else {
      let index = this.f.ausstattung.value.findIndex(item => item.id == ausstattung.id);
      if(index > -1) this.f.ausstattung.value.splice(index,1);
      // let index = this.selectedAusstattung.findIndex(item => item.id == ausstattung.id);
      // if(index > -1) this.selectedAusstattung.splice(index,1);
      // this.f.ausstattung.setValue(this.selectedAusstattung);
    }
  }

  // resizeGridItem(item){
  //   let grid = document.getElementById("grid");
  //   let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  //   let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  //   let innerItems = item.querySelectorAll('.checkbox_container')
  //   let rowSpan = 0;
  //   for(let i of innerItems){
  //     rowSpan += Math.ceil((i.getBoundingClientRect().height+rowGap)/(rowHeight+rowGap))
  //   }
  //   item.style.gridRowEnd = "span "+rowSpan;
  // }

  // resizeAllGridItems(){
  //   const allItems = document.querySelectorAll(".category_container");
  //   for (var i = 0; i < allItems.length; i++){
  //     this.resizeGridItem(allItems[i]);
  //   }
  // }
}
