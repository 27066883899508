import { Company } from "../Company";

export class CompanyFactory {
    static jsonFactory(rawData: any): Company[] {
        let datas: Company[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Company {
        return new Company(
            rawData.company_id,
            rawData.company_name,
            rawData.street,
            String(rawData.house_number),
            rawData.city,
            rawData.postal_code,
            rawData.email,
            rawData.phone,
            rawData.url,
            rawData.atu_number,
        );
    }
}
