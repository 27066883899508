import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalVariables } from 'src/app/helper/GlobalVariables';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  public activeMenu = 2;
  public subscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public appVersion;

  constructor(
    private router: Router,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.appVersion = environment.appVersion;
    this.subscription = this.router.events.subscribe((value) => {
      if(value instanceof NavigationStart){
        this.setMenu(value.url);
      }
    });
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_create_immo'){
          GlobalVariables.is_create_process = false;
          this.loadPage(value.submit_value);
        }
      }
    });
  }

  setMenu(url: string) {
    if(url.includes('/dashboard')) this.activeMenu = 1;
    else if(url.includes('/immobilien')) this.activeMenu = 2;
    else if(url.includes('/interface')) this.activeMenu = 3;
    else if(url.includes('/profile')) this.activeMenu = 4;
  }

  reload(page: string) {
    if(this.router.url == '/immobilien' && GlobalVariables.is_create_process){
      this.dialogService.openQuery(
        {
          title: 'Immobilie verwerfen?',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwerfen',
          typ: 'cancel_create_immo',
          submit_value: page,
          showClose: true,
        }
      );
    } else {
      this.loadPage(page);
    }
  }

  loadPage(page: string) {
    if(this.router.url.includes(page)){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([page]));
    } else {
      this.router.navigate([page]);
    }
  }

  isActive(num: number): boolean{
    return num == this.activeMenu;
  }

  goToLink() {
    window.open('https://software-entwicklung-graz.at/', '_blank')
  }
}
