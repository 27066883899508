<div id="dialog_window" *ngIf="show">
    <div id="dialog_container">
        <div class="lbl_bold">{{title}}</div>
        <div class="lbl_regular message">{{message}}</div>
        <div class="btn_container">
          <div class="btn_cancel" (click)="show=false">{{btnCancelTxt}}</div>
          <div class="btn_submit" (click)="submit()">{{btnSubmitTxt}}</div>
          <!-- <div class="btn_lbl" style="margin-bottom: 15px;" (click)="show=false">{{btnCancelTxt}}</div> -->
        </div>
    </div>
  </div>
