import { Heizungsart } from "../ressource/Heizungsart";
import { ImmoRessource } from "../ressource/ImmoRessource";
import { Kueche } from "../ressource/Kueche";

export class Zusatz {
    constructor(
        public kueche: ImmoRessource,
        public heizungsart: ImmoRessource,
        public befeuerung: ImmoRessource,
        public stellplatzart: ImmoRessource,
        public ausricht_balkon_terrasse: ImmoRessource,
        public moebliert: ImmoRessource,
        public dachform: ImmoRessource,
        public bauweise: ImmoRessource,
        public ausbaustufe: ImmoRessource,
        public energietyp: ImmoRessource,
        public angeschl_gastronomie: ImmoRessource,
        public hallenhoehe: number,
    ){}
}
