export class Preise {
    constructor(
        public isQm: boolean,
        public nettokaltmiete: number,
        public nebenkosten: number,
        public heizkosten: number,
        public mietzuschlaege: number,
        public provisionbrutto: number,
        public kaution: number,
        public kaufpreis: number,
        public kaufpreisbrutto: number,
        public monatlichekostenbrutto: number,
        public kaufpreis_pro_qm: number,
        public erschliessungskosten: number,
        public mehrwertsteuer: number,
        public preis_per_anfrage?: boolean
    ){}
}
