import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginService } from './service/login/login.service';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { ImmoAdministrationComponent } from './view/immo-administration/immo-administration.component';
import { InterfaceComponent } from './view/interface/interface.component';
import { LoginComponent } from './view/login/login.component';
import { ProfileComponent } from './view/profile/profile.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'immobilien',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { isLogin: true }
  },
  // {
  //   path: 'dashboard',
  //   component: DashboardComponent,
  //   canActivate: [LoginService]
  // },
  {
    path: 'immobilien',
    component: ImmoAdministrationComponent,
    canActivate: [LoginService]
  },
  {
    path: 'immobilien/anlegen',
    component: ImmoAdministrationComponent,
    canActivate: [LoginService]
  },
  {
    path: 'immobilien/:immobilieId',
    component: ImmoAdministrationComponent,
    canActivate: [LoginService]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [LoginService]
  },
  // {
  //   path: 'interface',
  //   component: InterfaceComponent,
  //   canActivate: [LoginService]
  // },
  {
    path: 'user/forgetpassword',
    component: ResetPasswordComponent,
    data: { isLogin: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
