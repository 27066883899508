import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserFactory } from 'src/app/buisness-object/user/factory/UserFactory';
import { User } from 'src/app/buisness-object/user/User';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getUser(): Observable<User> {
    if(this.user$.getValue()){
      return of(this.user$.getValue())
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token')
      });
      const observable = this.http.get(environment.api + '/back/user', {headers});
      return observable.pipe(
        map((result: any) => {
          let data = UserFactory.jsonFactoryOne(result.user);
          this.user$.next(data);
          return data;
        }),
        catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            console.log(error);
            this.dService.showNotification({
              title: 'Fehlgeschlagen',
              message: error.error.error_message,
              success: false
            });
          }
          return [];
        })
      );
    }
  }

  public getUsers(): Observable<User[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/appuser', {headers});
    return observable.pipe(
      map((result: any) => {
        let data = UserFactory.jsonFactory(result.users);
        return data;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateUser(user: User): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let body = user.getJson();
    const observable = this.http.put(environment.api + '/back/user', body, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = UserFactory.jsonFactoryOne(result.user);
        return data;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updatePassword(password_new: string, password_old: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/back/user/password', {old_password : password_old, new_password: password_new}, {headers});
    return observable.pipe(
      map((result: any) => {
        return true;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }
}
