export class Company {
    constructor(
        public companyId: number,
        public companyName: string,
        public street: string,
        public houseNumber: string,
        public city: string,
        public postalCode: number,
        public email: string,
        public phone: string,
        public url: string,
        public atu: string
    ){}
}