import { Attachment } from "../Attachment";

export class AttachmentFactory {
  static jsonFactory(rawData: any): Attachment[] {
    let datas: Attachment[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
}

static jsonFactoryOne(rawData: any): Attachment {
    return new Attachment(
        rawData.anhang_id,
        rawData.immobilie_id,
        rawData.anhang_titel,
        rawData.format,
        rawData.anhang_gruppe_id,
        rawData.sort_value,
        rawData.anhang,
    );
  }
}
