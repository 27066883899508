<div id="titlebar_container">
  <div class="lbl_bold">{{title}}</div>
  <div class="end_container">
    <div class="action_container">
      <div class="action_add" (click)="goToCreate()" style="visibility: hidden;">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="addnewblue" transform="translate(-570.5 -1251.5)">
            <path id="Linie_2" data-name="Linie 2" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(578.5 1252.5)" />
            <path id="Linie_3" data-name="Linie 3" d="M0,15a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V14A1,1,0,0,1,0,15Z" transform="translate(585.5 1259.5) rotate(90)"/>
          </g>
        </svg>
      </div>
      <!-- <div class="action_search">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13.118 13.588">
          <path id="searchblue" d="M12.919,12.376,9.685,9.013a5.484,5.484,0,1,0-4.2,1.959,5.427,5.427,0,0,0,3.143-.993l3.258,3.389a.716.716,0,1,0,1.031-.992M5.486,1.431A4.055,4.055,0,1,1,1.431,5.486,4.059,4.059,0,0,1,5.486,1.431"/>
        </svg>
      </div> -->
    </div>
    <div #dropdown_1 class="dropdown_container">
        <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : ''" (click)="showDropOne = !showDropOne">
            <div class="dropdown_header_lbl lbl_bold">{{user?.firstName}} {{user?.lastName}}</div>
            <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
        </div>
        <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
            <div class="dropdown_body_item lbl_regular" (click)="logout()">Abmelden</div>
        </div>
    </div>
  </div>
</div>
