<div class="btn_lbl" *ngIf="!isEdit" style="margin-bottom: 20px" (click)="closeDetailsEmitter.emit()">zurück</div>
<div id="header">
  <div class="btn_container">
    <div class="btn_tab" [ngClass]="showObjectInfo ? 'btn_tab_active' : ''" style="margin-right: 10px;" (click)="showObjectInfo = true">Objekt-Informationen</div>
    <div class="btn_tab" [ngClass]="!showObjectInfo ? 'btn_tab_active' : ''" (click)="showObjectInfo = false">Austattung</div>
  </div>
  <div class="btn_container" *ngIf="!isEdit">
    <div class="btn_submit" (click)="openEdit()">Bearbeiten</div>
    <div class="btn_lbl" (click)="deleteEmitter.emit(immo)">Immobilie löschen</div>
  </div>
  <div class="btn_container" *ngIf="isEdit">
    <div class="btn_submit" (click)="updateImmo()">Speichern</div>
    <div class="btn_lbl" (click)="closeEdit()">Abbrechen</div>
  </div>
  <!-- <div class="btn_lbl" *ngIf="!isEdit" (click)="closeDetailsEmitter.emit()">zurück</div> -->
</div>
<!-- Objekt Information -->
<div id="body_info" *ngIf="!isEdit && showObjectInfo">
  <div id="title_container">
    <div>
      <div class="lbl_title_big_color" style="margin-bottom: 0;">{{immo.objektTitel}}</div>
      <div class="lbl_regular">{{immo.objektnr}}</div>
    </div>
  </div>
  <div class="column">
    <div class="image_container">
      <div class="title_image_container" [ngStyle]="{'border': titelBild ? 'none' : '1px dashed #A4A5A5'}">
        <img *ngIf="titelBild" [src]="titelBild.anhang">
        <div *ngIf="!titelBild" class="lbl_regular">Kein Titelbild ausgewählt.</div>
      </div>
      <div *ngIf="innenansichten.length > 0">
        <div class="attachement_group_title lbl_regular">Innenansichten</div>
        <div class="other_images_container" >
          <img class="other_image" *ngFor="let doc of innenansichten" [src]="doc.anhang">
        </div>
      </div>
      <div *ngIf="ausenansichten.length > 0">
        <div class="attachement_group_title lbl_regular">Außenansichten</div>
        <div class="other_images_container" >
          <img class="other_image" *ngFor="let doc of ausenansichten" [src]="doc.anhang">
        </div>
      </div>
      <div *ngIf="grundrisse.length > 0">
        <div class="attachement_group_title lbl_regular">Grundriss</div>
        <div class="other_images_container" >
          <img class="other_image" *ngFor="let doc of grundrisse" [src]="doc.anhang">
        </div>
      </div>
      <div *ngIf="karteUndPlaene.length > 0">
        <div class="attachement_group_title lbl_regular">Karten & Pläne</div>
        <div class="other_images_container" >
          <img class="other_image" *ngFor="let doc of karteUndPlaene" [src]="doc.anhang">
        </div>
      </div>
      <div *ngIf="bilder.length > 0" class="attachement_group_wrapper">
        <div class="attachement_group_title lbl_regular">Bilder</div>
        <div class="other_images_container">
          <img class="other_image" *ngFor="let doc of bilder" [src]="doc.anhang">
        </div>
      </div>
      <div  *ngIf="documents.length > 0" class="attachement_group_wrapper">
        <div class="attachement_group_title lbl_regular">Dokumente</div>
        <div class="other_images_container">
          <div class="view_document" *ngFor="let doc of documents" (click)="export(doc)">
            <img class="doc_icon" src="../../../../../assets/document.svg">
            <div class="doc_txt">{{doc.anhang_title}}.{{doc.format}}</div>
            <div class="doc_hover_element">Herunterladen</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Objektbeschreibung -->
    <div class="data_box">
      <div class="lbl_title_big_color">Objektbeschreibung</div>
      <div class="lbl_regular text_box">{{immo.objektbeschreibung ? immo.objektbeschreibung : '---'}}</div>
    </div>
    <!-- Lage -->
    <div class="data_box">
      <div class="lbl_title_big_color">Lage</div>
      <div class="lbl_regular text_box">{{immo.lageBescreibung ? immo.lageBescreibung : '---'}}</div>
    </div>
    <!-- Sonstige Angaben -->
    <div class="data_box">
      <div class="lbl_title_big_color">Sonstige Angaben</div>
      <div class="lbl_regular text_box">{{immo.sonstigeAngaben ? immo.sonstigeAngaben : '---'}}</div>
    </div>
  </div>
  <div class="column">
    <!-- Objekt-Kategorie -->
    <div class="data_box">
      <div class="lbl_title_big_color">Objekt-Kategorie</div>
      <div class="data_box_grid_3">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Nutzungsart</div>
          <div class="lbl_regular">{{immo.nutzungsart.name}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Vermarkungsart</div>
          <div class="lbl_regular">{{immo.vermarktungsart.name}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Objektart</div>
          <div class="lbl_regular">{{immo.objektart.name}}</div>
        </div>
      </div>
    </div>
    <!-- Standortinformationen -->
    <div class="data_box">
      <div class="lbl_title_big_color">Standortinformationen</div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Bundesland</div>
          <div class="lbl_regular">{{immo.geo.bundesland.name}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Straße</div>
          <div class="lbl_regular">{{immo.geo.street ? immo.geo.street : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Hausnummer</div>
          <div class="lbl_regular">{{immo.geo.housNumber ? immo.geo.housNumber : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Ort</div>
          <div class="lbl_regular">{{immo.geo.city ? immo.geo.city : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">PLZ</div>
          <div class="lbl_regular">{{immo.geo.postalCode}}</div>
        </div>
      </div>
      <div class="data_box_grid" style="margin-bottom: 0;">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Breitengrad</div>
          <div class="lbl_regular">{{immo.geo.latitude ? immo.geo.latitude : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Längengrad</div>
          <div class="lbl_regular">{{immo.geo.longitude ? immo.geo.longitude : '---'}}</div>
        </div>
      </div>
    </div>
    <!-- Zustand -->
    <div class="data_box">
      <div class="lbl_title_big_color">Zustand</div>
      <div class="data_box_grid_3">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Zustand</div>
          <div class="lbl_regular">{{immo.getZustandZustand()}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Baujahr</div>
          <div class="lbl_regular">{{immo.getBaujahr()}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Gebäudetyp</div>
          <div class="lbl_regular">{{immo.getAlter()}}</div>
        </div>
      </div>
    </div>
    <!-- Preis -->
    <div class="data_box">
      <div class="lbl_title_big_color" style="margin-bottom: 0;">Preise in €</div>
      <div class="lbl_italic_light" style="margin-bottom: 20px;">{{!immo.preise.isQm ? 'Angabe für das ganze Objekt' : 'Preisangaben je m²'}}</div>
      <div class="data_box_grid" *ngIf="immo.vermarktungsart.id == 1">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Kaufpreis</div>
          <div class="lbl_regular">{{immo.preise.preis_per_anfrage ? 'per Anfrage' : (immo.preise.kaufpreis ? (immo.preise.kaufpreis | currency: 'EUR') : '---')}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Erschliessungskosten</div>
          <div class="lbl_regular">{{immo.preise.erschliessungskosten ? (immo.preise.erschliessungskosten | currency: 'EUR') : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid" *ngIf="immo.vermarktungsart.id == 2">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Gesamtmiete (inkl. MwSt)</div>
          <div class="lbl_regular">{{immo.preise.preis_per_anfrage ? 'per Anfrage' : (immo.preise.monatlichekostenbrutto ? (immo.preise.monatlichekostenbrutto | currency: 'EUR') : '---')}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Betriebskosten netto</div>
          <div class="lbl_regular">{{immo.preise.nebenkosten ? (immo.preise.nebenkosten | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Mehrwertsteuer</div>
          <div class="lbl_regular">{{immo.preise.mehrwertsteuer ? (immo.preise.mehrwertsteuer | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Heizkosten netto</div>
          <div class="lbl_regular">{{immo.preise.heizkosten ? (immo.preise.heizkosten | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Hauptmietzins/Miete netto</div>
          <div class="lbl_regular">{{immo.preise.nettokaltmiete ? (immo.preise.nettokaltmiete | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Sonstige Kosten netto</div>
          <div class="lbl_regular">{{immo.preise.mietzuschlaege ? (immo.preise.mietzuschlaege | currency: 'EUR') : '---'}}</div>
        </div>


        <!-- <div class="flex_vertical">
          <div class="lbl_italic_light">Netto Kaltemiete</div>
          <div class="lbl_regular">{{immo.preise.nettokaltmiete ? (immo.preise.nettokaltmiete | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Nebenkosten</div>
          <div class="lbl_regular">{{immo.preise.nebenkosten ? (immo.preise.nebenkosten | currency: 'EUR') : '---'}}</div>
        </div> -->
      </div>
      <div class="data_box_grid" style="margin-bottom: 50px;" *ngIf="immo.vermarktungsart.id == 1">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Kaufpreisbrutto</div>
          <div class="lbl_regular">{{immo.preise.kaufpreisbrutto ? (immo.preise.kaufpreisbrutto | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Kaufpreis pro m²</div>
          <div class="lbl_regular">{{immo.preise.kaufpreis_pro_qm ? (immo.preise.kaufpreis_pro_qm | currency: 'EUR') : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid" style="margin-bottom: 50px;" *ngIf="immo.vermarktungsart.id == 2">
        <!-- <div class="flex_vertical">
          <div class="lbl_italic_light">Heizkosten</div>
          <div class="lbl_regular">{{immo.preise.heizkosten ? (immo.preise.heizkosten | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Mietzuschläge</div>
          <div class="lbl_regular">{{immo.preise.mietzuschlaege ? (immo.preise.mietzuschlaege | currency: 'EUR') : '---'}}</div>
        </div> -->
      </div>
      <div class="data_box_grid" style="margin-bottom: 0;">
        <div class="flex_vertical" *ngIf="immo.vermarktungsart.id == 2">
          <div class="lbl_title_big_color">Kaution</div>
          <div class="lbl_regular">{{immo.preise.kaution ? (immo.preise.kaution | currency: 'EUR') : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_title_big_color">Provision</div>
          <div class="lbl_regular">{{immo.preise.provisionbrutto ? (immo.preise.provisionbrutto | currency: 'EUR') : '---'}}</div>
        </div>
      </div>
    </div>
    <!-- Anzahl der Zimmer -->
    <div class="data_box">
      <div class="lbl_title_big_color">Anzahl der Zimmer</div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Zimmer gesamt</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlZimmer ? immo.flaeche.anzahlZimmer : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Schlafzimmer</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlSchlafzimmer ? immo.flaeche.anzahlSchlafzimmer : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Badezimmer</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlBadezimmer ? immo.flaeche.anzahlBadezimmer : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Separate WC</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlSep_wc ? immo.flaeche.anzahlSep_wc : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Terrassen</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlTerrassen ? immo.flaeche.anzahlTerrassen : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Balkone</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlBalkone ? immo.flaeche.anzahlBalkone : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid" style="margin-bottom: 0;">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Loggia</div>
          <div class="lbl_regular">{{immo.flaeche.anzahlLogia ? immo.flaeche.anzahlLogia : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Stellplätze</div>
          <div class="lbl_regular">---</div>
        </div>
      </div>
    </div>
    <!-- Objekt Flächen in m2 -->
    <div class="data_box">
      <div class="lbl_title_big_color">Objekt Flächen in m²</div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Gesamtfläche</div>
          <div class="lbl_regular">{{immo.flaeche.gesamtflaeche ? immo.flaeche.gesamtflaeche + '  m²' : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Grundstücksfläche</div>
          <div class="lbl_regular">{{immo.flaeche.grundstuecksflaeche ? immo.flaeche.grundstuecksflaeche + '  m²' : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Wohnfläche</div>
          <div class="lbl_regular">{{immo.flaeche.wohnflaeche ? immo.flaeche.wohnflaeche + '  m²' : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Nutzfläche</div>
          <div class="lbl_regular">{{immo.flaeche.nutzflaeche ? immo.flaeche.nutzflaeche + '  m²' : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Beheizbare Fläche</div>
          <div class="lbl_regular">---</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Keller</div>
          <div class="lbl_regular">{{immo.flaeche.kellerflaeche ? immo.flaeche.kellerflaeche + '  m²' : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Dachboden</div>
          <div class="lbl_regular">{{immo.flaeche.dachbodenflaeche ? immo.flaeche.dachbodenflaeche + '  m²' : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Garten</div>
          <div class="lbl_regular">{{immo.flaeche.gartenflaeche ? immo.flaeche.gartenflaeche + '  m²' : '---'}}</div>
        </div>
      </div>
      <div class="data_box_grid" style="margin-bottom: 0;">
        <div class="flex_vertical">
          <div class="lbl_italic_light">Balkone & Terrassen</div>
          <div class="lbl_regular">{{immo.flaeche.balkonTerrasseFlaeche ? immo.flaeche.balkonTerrasseFlaeche + '  m²' : '---'}}</div>
        </div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Sonstige Fläche</div>
          <div class="lbl_regular">{{immo.flaeche.sonstflaeche ? immo.flaeche.sonstflaeche + '  m²' : '---'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Objekt Ausstattung -->
<div id="body_ausstattung" *ngIf="!isEdit && !showObjectInfo">
  <div class="data_box" style="padding: 50px 85px;">
    <div class="lbl_title_big_color" style="margin-bottom: 40px;">Ausstattung</div>
    <div class="grid_info">
      <div class="column">
        <div class="lbl_title_big_color lbl_small_title">Außen</div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Ausrichtung Balkon und Terrasse</div>
          <div class="lbl_regular">{{immo.getAusrichtBalkonTerasse()}}</div>
        </div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Ausbaustufe</div>
          <div class="lbl_regular">{{immo.getAusbaustufe()}}</div>
        </div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Bauweise</div>
          <div class="lbl_regular">{{immo.getBauweise()}}</div>
        </div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Dachform</div>
          <div class="lbl_regular">{{immo.getDachform()}}</div>
        </div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Energietyp</div>
          <div class="lbl_regular">{{immo.getEnergietyp()}}</div>
        </div>
        <div class="flex_vertical_2">
          <div class="lbl_italic_light">Stellplatzart</div>
          <div class="lbl_regular">{{immo.getStellplatzart()}}</div>
        </div>
        <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px;">Heizungsart</div>
        <div class="flex_vertical">
          <div class="lbl_italic_light">Heizung</div>
          <div class="lbl_regular">{{immo.getHeizungsart()}}</div>
        </div>
      </div>
      <div class="column">
        <div *ngIf="immo.getAusstattungByCategory(1)">
          <div class="lbl_title_big_color lbl_small_title">Bad</div>
          <div *ngFor="let boden of immo.getAusstattungByCategory(1)" class="lbl_regular" style="margin-top: 10px;">{{boden.name}}</div>
        </div>
        <div *ngIf="immo.getAusstattungByCategory(3)">
          <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px;">Küche</div>
          <div *ngFor="let boden of immo.getAusstattungByCategory(3)" class="lbl_regular" style="margin-top: 10px;">{{boden.name}}</div>
        </div>
        <div *ngIf="immo.getAusstattungByCategory(4)">
          <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px;">Boden</div>
          <div *ngFor="let boden of immo.getAusstattungByCategory(4)" class="lbl_regular" style="margin-top: 10px;">{{boden.name}}</div>
        </div>
      </div>
      <div class="column">
        <div *ngIf="immo.getAusstattungByCategory(2)">
          <div class="lbl_title_big_color lbl_small_title">Sonstige</div>
          <div *ngFor="let boden of immo.getAusstattungByCategory(2)" class="lbl_regular" style="margin-top: 10px;">{{boden.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="data_box" style="padding: 50px 85px; margin-bottom: 200px;">
    <div class="lbl_title_big_color" style="margin-bottom: 40px;">Ausstattung Beschreibung</div>
    <div class="lbl_regular text_box">{{immo.ausstattungBeschreibung ? immo.ausstattungBeschreibung : '---'}}</div>
  </div>
</div>
<!-- Objekt Informationen bearbeiten -->
<div id="body_info_edit" *ngIf="isEdit && showObjectInfo">
  <div class="data_box">
    <app-basic
      [immoForm]="immoForm"
      [submitted]="submitted"
      [nutzungsarten]="nutzungsarten"
      [objektarten]="objektarten"
      [vermarktungsarten]="vermarktungsarten"
      [bundeslaender]="bundeslaender"
      [bebaubarNach]="bebaubarNach"
    ></app-basic>
  </div>
  <div class="data_box">
    <app-price
      [immoForm]="immoForm"
      [submitted]="submitted"
    ></app-price>
  </div>
  <div class="data_box">
    <app-areas
      [immoForm]="immoForm"
      [submitted]="submitted"
    ></app-areas>
  </div>
  <div class="data_box">
    <app-description
      [immoForm]="immoForm"
      [submitted]="submitted"
    ></app-description>
  </div>
  <div class="data_box">
    <app-attachments
      [immoForm]="immoForm"
      [submitted]="submitted"
      [isDetails]="true"
      (setTempEmitter)="saveAttachementsTemp($event)"
    ></app-attachments>
  </div>
</div>
<div id="body_info_edit" *ngIf="isEdit && !showObjectInfo">
  <div class="data_box">
    <app-furnishing
      [immoForm]="immoForm"
      [submitted]="submitted"
    ></app-furnishing>
  </div>
</div>
