import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private notification = new Subject<any>();
  notification$ = this.notification.asObservable();

  private openDialogQuery = new Subject<any>();
  openDialogQuery$ = this.openDialogQuery.asObservable();
  private closeDialogQuery = new Subject<any>();
  closeDialogQuery$ = this.closeDialogQuery.asObservable();

  constructor() { }

  showNotification(data: any) {
    this.notification.next(data);
  }

  openQuery(data: any) {
    this.openDialogQuery.next(data);
  }
  closeQuery(data: any) {
    this.closeDialogQuery.next(data);
  }
}
