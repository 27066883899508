import { BebaubarNach } from "../ressource/BebaubarNach";
import { Erschliessung } from "../ressource/Erschliessung";
import { Zustand } from "../ressource/Zustand";
import { Verkaufsstatus } from "../ressource/Verkaufsstatus";
import { ImmoRessource } from "../ressource/ImmoRessource";

export class ZustandImmobilie {
    constructor(
        public baujahr: string,
        public zustand: ImmoRessource,
        public istAltbau: number,
        public bebaubarNach: ImmoRessource,
        public erschliessung: ImmoRessource,
        public verkaufstatus: ImmoRessource,
        public mietdauer: number,
        public minMietDauer?: number,
        public maxMietDauer?: number,
    ){}
}
