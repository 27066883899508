<div id="login_container">
    <app-login-form *ngIf="showLogin"
        (showResetEmitter)="showLogin = false"
    ></app-login-form>
    <app-login-forgot-password-form *ngIf="!showLogin"
        (showLoginEmitter)="showLogin = true"
    ></app-login-forgot-password-form>
    <div id="login_image"></div>
</div>
<div id="logo_container" (click)="goToLink()">
    <div class="lbl_regular logo_lbl">Powered by</div>
    <img id="logo_waymark" src="../../../assets/waymarklogo.svg">
</div>