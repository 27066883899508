<div id="login_container">
  <div *ngIf="invalidToken" class="lbl_bold not_authorized" style="font-size: 68px;">Not Authorized</div>
  <div *ngIf="tokenExpired" class="expired_container">
      <div class="lbl_bold" style="font-size: 166px;">401</div>
      <div class="lbl_bold">Link abgelaufen.</div>
  </div>
  <div *ngIf="!invalidToken && !tokenExpired" >
    <form class="login_form" *ngIf="!tokenExpired && !invalidToken && !success" [formGroup]="form">
      <div class="lbl_bold lbl_title">Neues Passwort</div>
      <div class="input_container" [ngClass]="submitted && form.controls.password_1.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/password.svg">
        <input type="{{showPassword_1 ? 'text' : 'password'}}" placeholder="Passwort" formControlName="password_1" (ngModelChange)="accessDenied = false">
        <img (click)="showPassword_1 = !showPassword_1" class='icon_eye' [src]="showPassword_1 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
      </div>
      <div class="input_container" [ngClass]="submitted && form.controls.password_2.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/password.svg">
        <input type="{{showPassword_2 ? 'text' : 'password'}}" placeholder="Passwort wiederholen" formControlName="password_2" (ngModelChange)="accessDenied = false">
        <img (click)="showPassword_2 = !showPassword_2" class='icon_eye' [src]="showPassword_2 ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
      </div>
      <label class="lbl_bold" style="width: 92%; margin-bottom: 50px;">Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten. Es muss aus min. 8 Zeichen bestehen.</label>
      <div class="btn_submit" (click)="onSubmit()">Ändern</div>
    </form>
    <div *ngIf="success" class="login_form">
      <div class="lbl_bold lbl_title">Passwort geändert</div>
      <div class="btn_submit" style="margin-top: 50px;" (click)="toLogin()">Zum Login</div>
    </div>
  </div>
  <div id="login_image"></div>
</div>

<div id="logo_container" (click)="goToLink()">
  <div class="lbl_regular logo_lbl">Powered by</div>
  <img id="logo_waymark" src="../../../assets/waymarklogo.svg">
</div>

