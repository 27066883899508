<div>
  <div class="lbl_title_big_color lbl_title">Austattung</div>
    <div class="content">
      <form [formGroup]="immoForm" class="grid">
        <div class="column">
          <div class="lbl_title_big_color lbl_small_title">Außen</div>
          <div class="lbl_bold dropdown_title_lbl">Ausrichtung Balkon und Terrasse</div>
          <div #dropdown_1 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : (f.ausricht_balkon_terrasse.value ? 'dropdown_valid' : '')" (click)="showDropOne = !showDropOne">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.ausricht_balkon_terrasse.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.ausricht_balkon_terrasse.value ? f.ausricht_balkon_terrasse.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of ausrichtungenBalkonTerrasse" class="dropdown_body_item lbl_regular" (click)="f.ausricht_balkon_terrasse.setValue(ressource); showDropOne = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Ausbaustufe</div>
          <div #dropdown_2 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropTwo ? 'dropdown_header_active' : (f.ausbaustufe.value ? 'dropdown_valid' : '')" (click)="showDropTwo = !showDropTwo">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.ausbaustufe.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.ausbaustufe.value ? f.ausbaustufe.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTwo ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropTwo ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of ausbaustufen" class="dropdown_body_item lbl_regular" (click)="setRessource('ausbaustufe', ressource); showDropTwo = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Bausweise</div>
          <div #dropdown_3 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropThree ? 'dropdown_header_active' : (f.bauweise.value ? 'dropdown_valid' : '')" (click)="showDropThree = !showDropThree">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.bauweise.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.bauweise.value ? f.bauweise.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropThree ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropThree ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of bauweisen" class="dropdown_body_item lbl_regular" (click)="setRessource('bauweise', ressource); showDropThree = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Dachform</div>
          <div #dropdown_4 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropFour ? 'dropdown_header_active' : (f.dachform.value ? 'dropdown_valid' : '')" (click)="showDropFour = !showDropFour">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.dachform.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.dachform.value ? f.dachform.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropFour ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropFour ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of dachformen" class="dropdown_body_item lbl_regular" (click)="setRessource('dachform', ressource); showDropFour = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Energietyp</div>
          <div #dropdown_5 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropFive ? 'dropdown_header_active' : (f.energietyp.value ? 'dropdown_valid' : '')" (click)="showDropFive = !showDropFive">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.energietyp.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.energietyp.value ? f.energietyp.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropFive ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropFive ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of energietypen" class="dropdown_body_item lbl_regular" (click)="setRessource('energietyp', ressource); showDropFive = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Stellplatzart</div>
          <div #dropdown_6 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropSix ? 'dropdown_header_active' : (f.stellplatzart.value ? 'dropdown_valid' : '')" (click)="showDropSix = !showDropSix">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.stellplatzart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.stellplatzart.value ? f.stellplatzart.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropSix ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropSix ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of stellplatzarten" class="dropdown_body_item lbl_regular" (click)="setRessource('stellplatzart', ressource); showDropSix = false">{{ressource.name}}</div>
            </div>
          </div>
          <div>
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Bad</div>
            <div *ngFor="let ausstattung of austattungenBad">
              <div class="flex_slider">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="ausstattungsKategorien.length > 0">
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">{{ausstattungsKategorien[0].name}}</div>
            <div *ngFor="let ausstattung of ausstattungen">
              <div class="flex_slider" *ngIf="ausstattung.categorie_id == ausstattungsKategorien[0].id">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div>
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Küche</div>
            <div *ngFor="let ausstattung of austattungenKueche">
              <div class="flex_slider">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="ausstattungsKategorien.length > 0">
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">{{ausstattungsKategorien[2].name}}</div>
            <div *ngFor="let ausstattung of ausstattungen">
              <div class="flex_slider" *ngIf="ausstattung.categorie_id == ausstattungsKategorien[2].id">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="lbl_bold dropdown_title_lbl">Möbeliert</div>
          <div #dropdown_10 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropTen ? 'dropdown_header_active' : (f.moebliert.value ? 'dropdown_valid' : '')" (click)="showDropTen = !showDropTen">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.moebliert.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.moebliert.value ? f.moebliert.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTen ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropTen ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of moeblierungen" class="dropdown_body_item lbl_regular" (click)="f.moebliert.setValue(ressource); showDropTen = false">{{ressource.name}}</div>
            </div>
          </div>
        </div>
        <div class="column">
          <div>
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Boden</div>
            <div *ngFor="let ausstattung of austattungenBoden">
              <div class="flex_slider">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="ausstattungsKategorien.length > 0">
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">{{ausstattungsKategorien[3].name}}</div>
            <div *ngFor="let ausstattung of ausstattungen">
              <div class="flex_slider" *ngIf="ausstattung.categorie_id == ausstattungsKategorien[3].id">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Heizung</div>
          <div class="lbl_bold dropdown_title_lbl">Heizungsart</div>
          <div #dropdown_8 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropEight ? 'dropdown_header_active' : (f.heizungsart.value ? 'dropdown_valid' : '')" (click)="showDropEight = !showDropEight">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.heizungsart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.heizungsart.value ? f.heizungsart.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropEight ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropEight ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of heizungsarten" class="dropdown_body_item lbl_regular" (click)="setRessource('heizungsart', ressource); showDropEight = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Befeuerung</div>
          <div #dropdown_9 class="dropdown_container">
            <div class="dropdown_header" [ngClass]="showDropNine ? 'dropdown_header_active' : (f.befeuerung.value ? 'dropdown_valid' : '')" (click)="showDropNine = !showDropNine">
                <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.befeuerung.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.befeuerung.value ? f.befeuerung.value.name : 'Auswählen...'}}</div>
                <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropNine ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
            </div>
            <div class="dropdown_body" [ngClass]="showDropNine ? 'dropdown_body_open' : ''">
                <div *ngFor="let ressource of befeuerungen" class="dropdown_body_item lbl_regular" (click)="setRessource('befeuerung', ressource); showDropNine = false">{{ressource.name}}</div>
            </div>
          </div>
          <div class="lbl_bold dropdown_title_lbl">Hallenhöhe in m</div>
          <div class="input_container" [ngClass]="f.hallenhoehe.value != null ? 'inputfield_valid' : ''">
              <input type="number" placeholder="z.B. 4.2" step="0.01" min="0.01" formControlName="hallenhoehe">
          </div>
        </div>
        <div class="column">
          <div>
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Sonstiges</div>
            <div *ngFor="let ausstattung of austattungenSonstige">
              <div class="flex_slider">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="ausstattungsKategorien.length > 0">
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">{{ausstattungsKategorien[1].name}}</div>
            <div *ngFor="let ausstattung of ausstattungen">
              <div class="flex_slider" *ngIf="ausstattung.categorie_id == ausstattungsKategorien[1].id">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="handleAusstattung(ausstattung)">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </form>
    </div>
  <form [formGroup]="immoForm">
    <div class="lbl_bold dropdown_title_lbl">Sonstige Angaben</div>
    <textarea #textField
      id="textField"
      class="text_area"
      [ngClass]="!f.sonstige_angaben_austattung.value ? 'text_area_placeholder' : ''"
      [ngStyle]="{'border-color': f.sonstige_angaben_austattung.value ? 'var(--color-6)' : ''}"
      placeholder="Geben Sie hier sonstige Beschreibungen zum Objekt ein."
      formControlName="sonstige_angaben_austattung"></textarea>
    <!-- <div #textField id="textField" class="text_area" contenteditable (input)="setDiscription(textField.innerHTML)"></div> -->
  </form>
</div>



<!-- Test -->
          <!-- <div *ngFor="let categorie of ausstattungsKategorien">
            <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">{{categorie.name}}</div>
            <div *ngFor="let ausstattung of ausstattungen">
              <div class="flex_slider" *ngIf="ausstattung.categorie_id == categorie.id">
                <div class="lbl_regular">{{ausstattung.name}}</div>
                <div class="slider_container" (click)="ausstattung.is_selected = !ausstattung.is_selected">
                  <div class="slider_still" [ngClass]="ausstattung.is_selected ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="ausstattung.is_selected ? 'slider_on' : 'slider_off'"></div>
                </div>
              </div>
            </div>
          </div> -->



    <!-- <div class="column">

    </div>
    <div class="column">

    </div> -->
<!--
      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Boden</div>
      <div class="flex_slider">
        <div class="lbl_regular">Dielen</div>
        <div class="slider_container" (click)="f.dielen.setValue(!f.dielen.value)">
          <div class="slider_still" [ngClass]="f.dielen.value ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.dielen.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Doppelboden</div>
        <div class="slider_container" (click)="f.doppelboden.setValue(!f.doppelboden.value)">
          <div class="slider_still" [ngClass]="f.doppelboden.value ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.doppelboden.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div><div class="flex_slider">
        <div class="lbl_regular">Estrich</div>
        <div class="slider_container" (click)="f.estrich.setValue(!f.estrich.value)">
          <div class="slider_still" [ngClass]="f.estrich.value ? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.estrich.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div><div class="flex_slider">
        <div class="lbl_regular">Fertigparkett</div>
        <div class="slider_container" (click)="f.fertigparkett.setValue(!f.fertigparkett.value)">
          <div class="slider_still" [ngClass]="f.fertigparkett.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.fertigparkett.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div><div class="flex_slider">
        <div class="lbl_regular">Fließen</div>
        <div class="slider_container" (click)="f.fliesen.setValue(!f.fliesen.value)">
          <div class="slider_still" [ngClass]="f.fliesen.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.fliesen.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div><div class="flex_slider">
        <div class="lbl_regular">Granit</div>
        <div class="slider_container" (click)="f.granit.setValue(!f.granit.value)">
          <div class="slider_still" [ngClass]="f.granit.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.granit.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Kunststoff</div>
        <div class="slider_container" (click)="f.kunststoff.setValue(!f.kunststoff.value)">
          <div class="slider_still" [ngClass]="f.kunststoff.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kunststoff.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Laminat</div>
        <div class="slider_container" (click)="f.laminat.setValue(!f.laminat.value)">
          <div class="slider_still" [ngClass]="f.laminat.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.laminat.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Linoleum</div>
        <div class="slider_container" (click)="f.linoleum.setValue(!f.linoleum.value)">
          <div class="slider_still" [ngClass]="f.linoleum.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.linoleum.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Marmor</div>
        <div class="slider_container" (click)="f.marmor.setValue(!f.marmor.value)">
          <div class="slider_still" [ngClass]="f.marmor.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.marmor.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Parkett</div>
        <div class="slider_container" (click)="f.parkett.setValue(!f.parkett.value)">
          <div class="slider_still" [ngClass]="f.parkett.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.parkett.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Stein</div>
        <div class="slider_container" (click)="f.stein.setValue(!f.stein.value)">
          <div class="slider_still" [ngClass]="f.stein.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.stein.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Teppich</div>
        <div class="slider_container" (click)="f.teppich.setValue(!f.teppich.value)">
          <div class="slider_still" [ngClass]="f.teppich.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.teppich.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Terrakotta</div>
        <div class="slider_container" (click)="f.terrakotta.setValue(!f.terrakotta.value)">
          <div class="slider_still" [ngClass]="f.terrakotta.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.terrakotta.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
    </div> -->
    <!-- <div class="column">

      <div class="lbl_title_big_color lbl_small_title">Weitere Räumlichkeiten und Nutzung</div>
      <div class="flex_slider">
        <div class="lbl_regular">WG-geeignet</div>
        <div class="slider_container" (click)="f.wg_geeignet.setValue(!f.wg_geeignet.value)">
          <div class="slider_still" [ngClass]="f.wg_geeignet.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.wg_geeignet.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Räume veränderbar</div>
        <div class="slider_container" (click)="f.raeume_veraenderbar.setValue(!f.raeume_veraenderbar.value)">
          <div class="slider_still" [ngClass]="f.raeume_veraenderbar.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.raeume_veraenderbar.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Abstellraum</div>
        <div class="slider_container" (click)="f.abstellraum.setValue(!f.abstellraum.value)">
          <div class="slider_still" [ngClass]="f.abstellraum.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.abstellraum.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Fahrradraum</div>
        <div class="slider_container" (click)="f.fahrradraum.setValue(!f.fahrradraum.value)">
          <div class="slider_still" [ngClass]="f.fahrradraum.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.fahrradraum.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Sauna</div>
        <div class="slider_container" (click)="f.saune.setValue(!f.saune.value)">
          <div class="slider_still" [ngClass]="f.saune.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.saune.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Swimmingpool</div>
        <div class="slider_container" (click)="f.swimmingpool.setValue(!f.swimmingpool.value)">
          <div class="slider_still" [ngClass]="f.swimmingpool.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.swimmingpool.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Wasch- und Trockenraum</div>
        <div class="slider_container" (click)="f.wasch_und_trockeraum.setValue(!f.wasch_und_trockeraum.value)">
          <div class="slider_still" [ngClass]="f.wasch_und_trockeraum.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.wasch_und_trockeraum.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Wintergarten</div>
        <div class="slider_container" (click)="f.wintergarten.setValue(!f.wintergarten.value)">
          <div class="slider_still" [ngClass]="f.wintergarten.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.wintergarten.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Innenausstattung</div>
      <div class="flex_slider">
        <div class="lbl_regular">Klimatisiert</div>
        <div class="slider_container" (click)="f.klimatisiert.setValue(!f.klimatisiert.value)">
          <div class="slider_still" [ngClass]="f.klimatisiert.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.klimatisiert.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Möbliert</div>
        <div class="slider_container" (click)="f.moebiliert.setValue(!f.moebiliert.value)">
          <div class="slider_still" [ngClass]="f.moebiliert.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.moebiliert.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Rolladen</div>
        <div class="slider_container" (click)="f.rolladen.setValue(!f.rolladen.value)">
          <div class="slider_still" [ngClass]="f.rolladen.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.rolladen.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Technik</div>
      <div class="flex_slider">
        <div class="lbl_regular">DVBT</div>
        <div class="slider_container" (click)="f.dvbt.setValue(!f.dvbt.value)">
          <div class="slider_still" [ngClass]="f.dvbt.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.dvbt.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Kabel/Sat</div>
        <div class="slider_container" (click)="f.kabel_sat.setValue(!f.kabel_sat.value)">
          <div class="slider_still" [ngClass]="f.kabel_sat.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kabel_sat.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">DV-Verkabelung</div>
        <div class="slider_container" (click)="f.dv_verkabelung.setValue(!f.dv_verkabelung.value)">
          <div class="slider_still" [ngClass]="f.dv_verkabelung.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.dv_verkabelung.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Sicherheit: Alarmanlage</div>
        <div class="slider_container" (click)="f.alarmanlage.setValue(!f.alarmanlage.value)">
          <div class="slider_still" [ngClass]="f.alarmanlage.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.alarmanlage.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Sicherheit: Kamera</div>
        <div class="slider_container" (click)="f.kamera.setValue(!f.kamera.value)">
          <div class="slider_still" [ngClass]="f.kamera.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kamera.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Sicherheit: Polizeiruf</div>
        <div class="slider_container" (click)="f.polizeiruf.setValue(!f.polizeiruf.value)">
          <div class="slider_still" [ngClass]="f.polizeiruf.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.polizeiruf.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Bad</div>
      <div class="flex_slider">
        <div class="lbl_regular">Dusche</div>
        <div class="slider_container" (click)="f.dusche.setValue(!f.dusche.value)">
          <div class="slider_still" [ngClass]="f.dusche.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.dusche.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Wanne</div>
        <div class="slider_container" (click)="f.wanne.setValue(!f.wanne.value)">
          <div class="slider_still" [ngClass]="f.wanne.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.wanne.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Fenster</div>
        <div class="slider_container" (click)="f.bad_fenster.setValue(!f.bad_fenster.value)">
          <div class="slider_still" [ngClass]="f.bad_fenster.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.bad_fenster.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Bidet</div>
        <div class="slider_container" (click)="f.bidet.setValue(!f.bidet.value)">
          <div class="slider_still" [ngClass]="f.bidet.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.bidet.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Pissoir</div>
        <div class="slider_container" (click)="f.pissoir.setValue(!f.pissoir.value)">
          <div class="slider_still" [ngClass]="f.pissoir.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.pissoir.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Küche</div>
      <div #dropdown_7 class="dropdown_container">
        <div class="dropdown_header" [ngClass]="showDropSeven ? 'dropdown_header_active' : (f.nutzungsart.value ? 'dropdown_valid' : '')" (click)="showDropSeven = !showDropSeven">
            <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.nutzungsart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.nutzungsart.value ? f.nutzungsart.value.name : 'Auswählen...'}}</div>
            <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropSeven ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
        </div>
        <div class="dropdown_body" [ngClass]="showDropSeven ? 'dropdown_body_open' : ''">
            <div *ngFor="let ressource of energietypen" class="dropdown_body_item lbl_regular" (click)="setRessource('ausrichtung_balkon_terrasse', ressource); showDropSeven = false">{{ressource.name}}</div>
        </div>
      </div>
    </div>
    <div class="column">

      <div class="lbl_title_big_color lbl_small_title">Barrierefreiheit</div>
      <div class="flex_slider">
        <div class="lbl_regular">Barrierefrei</div>
        <div class="slider_container" (click)="f.barrierefrei.setValue(!f.dielbarrierefreien.value)">
          <div class="slider_still" [ngClass]="f.barrierefrei.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.barrierefrei.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Fahrstuhl Personen</div>
        <div class="slider_container" (click)="f.fahrstuhl_personen.setValue(!f.fahrstuhl_personen.value)">
          <div class="slider_still" [ngClass]="f.fahrstuhl_personen.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.fahrstuhl_personen.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Fahrstuhl Lasten</div>
        <div class="slider_container" (click)="f.fahrstuhl_lasten.setValue(!f.fahrstuhl_lasten.value)">
          <div class="slider_still" [ngClass]="f.fahrstuhl_lasten.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.fahrstuhl_lasten.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Rampe</div>
        <div class="slider_container" (click)="f.rampe.setValue(!f.rampe.value)">
          <div class="slider_still" [ngClass]="f.rampe.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.rampe.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Rollstuhlgerecht</div>
        <div class="slider_container" (click)="f.rollstuhlgerecht.setValue(!f.rollstuhlgerecht.value)">
          <div class="slider_still" [ngClass]="f.rollstuhlgerecht.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.rollstuhlgerecht.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Seniorengerecht</div>
        <div class="slider_container" (click)="f.seniorengerecht.setValue(!f.seniorengerecht.value)">
          <div class="slider_still" [ngClass]="f.seniorengerecht.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.seniorengerecht.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Heizung</div>
      <div class="lbl_bold dropdown_title_lbl">Heizungsart</div>
      <div #dropdown_8 class="dropdown_container">
        <div class="dropdown_header" [ngClass]="showDropEight ? 'dropdown_header_active' : (f.heizungsart.value ? 'dropdown_valid' : '')" (click)="showDropEight = !showDropEight">
            <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.heizungsart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.heizungsart.value ? f.heizungsart.value.name : 'Auswählen...'}}</div>
            <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropEight ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
        </div>
        <div class="dropdown_body" [ngClass]="showDropEight ? 'dropdown_body_open' : ''">
            <div *ngFor="let ressource of heizungsarten" class="dropdown_body_item lbl_regular" (click)="setRessource('heizungsart', ressource); showDropEight = false">{{ressource.name}}</div>
        </div>
      </div>
      <div class="lbl_bold dropdown_title_lbl">Befeuerung</div>
      <div #dropdown_9 class="dropdown_container">
        <div class="dropdown_header" [ngClass]="showDropNine ? 'dropdown_header_active' : (f.befeuerung.value ? 'dropdown_valid' : '')" (click)="showDropNine = !showDropNine">
            <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.befeuerung.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.befeuerung.value ? f.befeuerung.value.name : 'Auswählen...'}}</div>
            <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropNine ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
        </div>
        <div class="dropdown_body" [ngClass]="showDropNine ? 'dropdown_body_open' : ''">
            <div *ngFor="let ressource of befeuerungen" class="dropdown_body_item lbl_regular" (click)="setRessource('befeuerung', ressource); showDropNine = false">{{ressource.name}}</div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Kamin</div>
        <div class="slider_container" (click)="f.kamin.setValue(!f.kamin.value)">
          <div class="slider_still" [ngClass]="f.kamin.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kamin.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>

      <div class="lbl_title_big_color lbl_small_title" style="margin-top: 40px">Gewerbe</div>
      <div class="flex_slider">
        <div class="lbl_regular">Bar</div>
        <div class="slider_container" (click)="f.bar.setValue(!f.bar.value)">
          <div class="slider_still" [ngClass]="f.bar.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.bar.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Gäste-WC</div>
        <div class="slider_container" (click)="f.gaeste_wc.setValue(!f.gaeste_wc.value)">
          <div class="slider_still" [ngClass]="f.gaeste_wc.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.gaeste_wc.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Gastterasse</div>
        <div class="slider_container" (click)="f.gasterrasse.setValue(!f.gasterrasse.value)">
          <div class="slider_still" [ngClass]="f.gasterrasse.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.gasterrasse.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Hebebühne</div>
        <div class="slider_container" (click)="f.hebebuehne.setValue(!f.hebebuehne.value)">
          <div class="slider_still" [ngClass]="f.hebebuehne.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.hebebuehne.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Hotelrestaurant</div>
        <div class="slider_container" (click)="f.hotelrestaurant.setValue(!f.hotelrestaurant.value)">
          <div class="slider_still" [ngClass]="f.hotelrestaurant.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.hotelrestaurant.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Kantine, Cafeteria</div>
        <div class="slider_container" (click)="f.kantine.setValue(!f.kantine.value)">
          <div class="slider_still" [ngClass]="f.kantine.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kantine.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Kran</div>
        <div class="slider_container" (click)="f.kran.setValue(!f.kran.value)">
          <div class="slider_still" [ngClass]="f.kran.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.kran.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Teeküche</div>
        <div class="slider_container" (click)="f.teekueche.setValue(!f.diteekuechelen.value)">
          <div class="slider_still" [ngClass]="f.teekueche.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.teekueche.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Sporteinrichtung</div>
        <div class="slider_container" (click)="f.sporteinrichtung.setValue(!f.sporteinrichtung.value)">
          <div class="slider_still" [ngClass]="f.sporteinrichtung.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.sporteinrichtung.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="flex_slider">
        <div class="lbl_regular">Wellnessbereich</div>
        <div class="slider_container" (click)="f.wellnessbereich.setValue(!f.wellnessbereich.value)">
          <div class="slider_still" [ngClass]="f.wellnessbereich.value? '' : 'slider_still_on'"></div>
          <div class="slider_move" [ngClass]="f.wellnessbereich.value ? 'slider_on' : 'slider_off'"></div>
        </div>
      </div>
      <div class="lbl_bold dropdown_title_lbl">Hallenhöhe in m</div>
      <div class="input_container" [ngClass]="f.hallenhoehe.value != null ? 'inputfield_valid' : ''">
          <input type="number" placeholder="z.B. 4.2" formControlName="hallenhoehe">
      </div>
    </div> -->
