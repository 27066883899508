<app-immo-administration-titlebar [title]="!showCreate && !showDetails ? 'Immobilien' : (showCreate ? 'Immobilie anlegen' : 'Immobilie Detailansicht')"></app-immo-administration-titlebar>
<div class="view_container">
    <div *ngIf="!showCreate && !showDetails">
        <div class="title_container">
            <div id="btn_container_tabs">
                <div class="btn_tab" [ngClass]="filter.tabFilter == 1 ? 'btn_tab_active' : ''" (click)="filter.tabFilter = 1; filterImmobilien()">
                  <div *ngIf="filter.tabFilter == 1" id="btn_tab_1">{{counterOffen}}</div>
                  Online
                </div>
                <div class="btn_tab" [ngClass]="filter.tabFilter == 2 ? 'btn_tab_active' : ''" (click)="filter.tabFilter = 2; filterImmobilien()">
                  <div *ngIf="filter.tabFilter == 2" id="btn_tab_2">{{counterEntwurf}}</div>
                  Entwurf
                </div>
                <div class="btn_tab" [ngClass]="filter.tabFilter == 3 ? 'btn_tab_active' : ''" (click)="filter.tabFilter = 3; filterImmobilien()">
                  <div *ngIf="filter.tabFilter == 3" id="btn_tab_3">{{counterVerkauft}}</div>
                  Abgeschlossen
                </div>
            </div>
            <div class="btn_submit" (click)="openCreate()"><span class="btn_submit_icon">+</span> Neue Immobilie hinzufügen</div>
        </div>
        <div class="filter_container">
            <div class="filter">
                <div class="lbl_bold dropdown_title_lbl">Nutzungsart</div>
                <div #dropdown_1 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : ''" (click)="showDropOne = !showDropOne">
                        <div class="lbl_regular dropdown_header_lbl" [ngClass]="filter.selectedValue_1 ? '' : 'dropdown_header_lbl_placeholder'">{{filter.selectedValue_1 ? filter.selectedValue_1.name : 'Alle anzeigen'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
                        <div class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_1 == null ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_1 = null; showDropOne = false; filterImmobilien()">Alle anzeigen</div>
                        <div *ngFor="let value of nutzungsarten" class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_1?.id == value.id ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_1 = value; showDropOne = false; filterImmobilien()">{{value.name}}</div>
                    </div>
                </div>
            </div>
            <div class="filter">
                <div class="lbl_bold dropdown_title_lbl">Vermarktungsart</div>
                <div #dropdown_2 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropTwo ? 'dropdown_header_active' : ''" (click)="showDropTwo = !showDropTwo">
                        <div class="lbl_regular dropdown_header_lbl" [ngClass]="filter.selectedValue_2 ? '' : 'dropdown_header_lbl_placeholder'">{{filter.selectedValue_2 ? filter.selectedValue_2.name : 'Alle anzeigen'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTwo ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropTwo ? 'dropdown_body_open' : ''">
                      <div class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_2 == null ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_2 = null; showDropTwo = false; filterImmobilien()">Alle anzeigen</div>
                      <div *ngFor="let value of vermarktungsarten" class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_2?.id == value.id ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_2 = value; showDropTwo = false; filterImmobilien()">{{value.name}}</div>
                    </div>
                </div>
            </div>
            <div class="filter">
                <div class="lbl_bold dropdown_title_lbl">Objektart</div>
                <div #dropdown_3 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropThree ? 'dropdown_header_active' : ''" (click)="showDropThree = !showDropThree">
                        <div class="lbl_regular dropdown_header_lbl" [ngClass]="filter.selectedValue_3 ? '' : 'dropdown_header_lbl_placeholder'">{{filter.selectedValue_3 ? filter.selectedValue_3.name : 'Alle anzeigen'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropThree ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropThree ? 'dropdown_body_open' : ''">
                      <div class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_3 == null ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_3 = null; showDropThree = false; filterImmobilien()">Alle anzeigen</div>
                      <div *ngFor="let value of objektarten" class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_3?.id == value.id ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_3 = value; showDropThree = false; filterImmobilien()">{{value.name}}</div>
                    </div>
                </div>
            </div>
            <div class="filter">
              <div class="lbl_bold dropdown_title_lbl">Standort</div>
              <div #dropdown_4 class="dropdown_container">
                  <div class="dropdown_header" [ngClass]="showDropFour ? 'dropdown_header_active' : ''" (click)="showDropFour = !showDropFour">
                      <div class="lbl_regular dropdown_header_lbl" [ngClass]="filter.selectedValue_4 ? '' : 'dropdown_header_lbl_placeholder'">{{filter.selectedValue_4 ? filter.selectedValue_4.name : 'Alle anzeigen'}}</div>
                      <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropFour ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                  </div>
                  <div class="dropdown_body" [ngClass]="showDropFour ? 'dropdown_body_open' : ''">
                    <div class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_4 == null ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_4 = null; showDropFour = false; filterImmobilien()">Alle anzeigen</div>
                    <div *ngFor="let value of bundeslaender" class="dropdown_body_item lbl_regular" [ngClass]="filter.selectedValue_4?.id == value.id ? 'dropdown_body_item_active' : ''" (click)="filter.selectedValue_4 = value; showDropFour = false; filterImmobilien()">{{value.name}}</div>
                  </div>
              </div>
          </div>
        </div>
        <app-immo-list
            [immos]="immobilienFiltered"
            [sliceTo]="sliceTo"
            [sliceFrom]="sliceFrom"
            [pageInfo]="pageInfo"
            [filter]="filter"
            [amount]="immobilienFiltered.length"
            (openDetailsEmitter)="openDetails($event)"
            (editEmitter)="openEdit($event)"
            (deleteEmitter)="deleteAction($event)"
            (sortEmitter)="sortImmoblien($event)"
            (pageEmitter)="pageHandling($event)"
            (changeStatusEmitter)="changeStatus($event)"
            (copyEmitter)="copyImmobilie($event)"
            (searchEmitter)="searchAction($event)"
        ></app-immo-list>
    </div>
    <app-immo-create *ngIf="showCreate"
        [immoForm]="immoForm"
        [page]="pageCreate"
        [submitted]="submitted"
        [selectedAusstattung]="selectedAusstattung"
        [nutzungsarten]="nutzungsarten"
        [objektarten]="objektarten"
        [vermarktungsarten]="vermarktungsarten"
        [bundeslaender]="bundeslaender"
        [bebaubarNach]="bebaubarNach"
        (pageHandlingCreateEmitter)="pageHandlingCreate($event)"
        (cancelEmitter)="cancelCreate()"
        (createEmitter)="createImmo()"
        (saveAsDraftEmitter)="saveAsDraft()"
    ></app-immo-create>
    <app-immo-details *ngIf="showDetails"
      [immo]="selectedImmo"
      [immoForm]="immoForm"
      [submitted]="submitted"
      [isEdit]="isEdit"
      [nutzungsarten]="nutzungsarten"
      [objektarten]="objektarten"
      [vermarktungsarten]="vermarktungsarten"
      [bundeslaender]="bundeslaender"
      [bebaubarNach]="bebaubarNach"
      (closeDetailsEmitter)="closeDetails()"
      (updateEmitter)="updateImmobilie()"
      (resetFormGroupEmitter)="fillForm()"
      (deleteEmitter)="deleteAction($event)"
      (changeToEditEmitter)="isEdit = true"
    ></app-immo-details>
</div>
