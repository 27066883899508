import { Title } from "../Title";

export class TitleFactory {
    static jsonFactory(rawData: any): Title[] {
        let datas: Title[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Title {
        return new Title(
            rawData.title_id,
            rawData.title,
        );
    }
}