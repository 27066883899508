import { ImmoRessource } from "../ressource/ImmoRessource";
import { Bundesland } from "./Bundesland";

export class Geo {
    constructor(
        public postalCode: number,
        public bundesland: ImmoRessource,
        public latitude: number,
        public longitude: number,
        public city: string,
        public street: string,
        public housNumber: string,
        public bezirk?: string
    ){}
}
