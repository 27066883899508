import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public showLogin = true;

  constructor() { }

  ngOnInit(): void {}

  goToLink() {
    window.open('https://software-entwicklung-graz.at/', '_blank')
  }
}
