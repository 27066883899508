import { ImmoRessource } from "../ImmoRessource";

export class RessourceFactory {
  static jsonFactory(rawData: any, ressource?: string): ImmoRessource[] {
    let datas: ImmoRessource[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data, ressource));
    }
    return datas;
  }

  static jsonFactoryOne(rawData: any, ressource?: string): ImmoRessource {
    if(rawData && ressource && ressource == 'ausstattung'){
      return new ImmoRessource(
        rawData.ausstattung_id,
        rawData.ausstattung,
        rawData.value,
        rawData.ausstattung_kategorie_id,
      );
    } else if(rawData) {
      return new ImmoRessource(
        rawData[Object.keys(rawData)[0]],
        rawData[Object.keys(rawData)[1]],
        rawData[Object.keys(rawData)[2]],
      );
    }
  }
}
