<div>
    <div class="lbl_title_big_color lbl_title">Basis-Information</div>
    <form [formGroup]="immoForm" class="grid">
        <div>
            <div class="datensatz">
                <div class="lbl_title_big_color lbl_small_title">Objekt-Details</div>
                <div class="lbl_bold dropdown_title_lbl">Objekttitel*</div>
                <div class="input_container" [ngClass]="f.objekt_titel.invalid ? '' : 'inputfield_valid'">
                    <input type="text" placeholder="Ihr individueller Titel für das Objekt" formControlName="objekt_titel">
                </div>
                <div class="lbl_bold dropdown_title_lbl">Interne Objektnummer*</div>
                <div class="input_container" [ngClass]="f.objektnr.invalid ? '' : 'inputfield_valid'">
                    <input type="text" placeholder="z.B. 0000000001" formControlName="objektnr">
                </div>
            </div>
            <div class="datensatz">
                <div class="lbl_title_big_color lbl_small_title">Objekt-Kategorie</div>
                <div class="lbl_bold dropdown_title_lbl">Nutzungsart*</div>
                <div #dropdown_1 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : (f.nutzungsart.value ? 'dropdown_valid' : '')" (click)="showDropOne = !showDropOne">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.nutzungsart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.nutzungsart.value ? f.nutzungsart.value.name : 'Auswählen...'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
                        <div *ngFor="let item of nutzungsarten" class="dropdown_body_item lbl_regular" (click)="setNutzungsart(item); showDropOne = false;">{{item.name}}</div>
                    </div>
                </div>
                <div class="lbl_bold dropdown_title_lbl">Vermarktungsart*</div>
                <div #dropdown_2 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="!f.nutzungsart.value ? 'dropwdown_disabled' : (showDropTwo ? 'dropdown_header_active' : (f.vermarktungsart.value ? 'dropdown_valid' : ''))" (click)="!f.nutzungsart.value ? null : showDropTwo = !showDropTwo">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.vermarktungsart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.vermarktungsart.value ? f.vermarktungsart.value.name : 'Auswählen...'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTwo ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropTwo ? 'dropdown_body_open' : ''">
                        <div *ngFor="let item of vermarktungsarten" class="dropdown_body_item lbl_regular" (click)="f.vermarktungsart.setValue(item); showDropTwo = false;">{{item.name}}</div>
                    </div>
                </div>
                <div *ngIf="f.vermarktungsart.value?.id == 2">
                  <div class="lbl_bold dropdown_title_lbl">Mietdauer (in Jahren)</div>
                  <div class="input_container" [ngClass]="f.mietdauer.value != null ? 'inputfield_valid' : ''">
                      <input type="number" min="1" step="1" placeholder="z.B. 3" formControlName="mietdauer">
                  </div>
                  <!-- <div class="lbl_bold dropdown_title_lbl">Minimale Mietdauer (in Monate)</div>
                  <div class="input_container" [ngClass]="f.min_mietdauer.value != null ? 'inputfield_valid' : ''">
                      <input type="number" min="1" step="1" placeholder="z.B. 12" formControlName="min_mietdauer">
                  </div> -->
                  <!-- <div class="lbl_bold dropdown_title_lbl">Maximale Mietdauer (in Monate)</div>
                  <div class="input_container" [ngClass]="f.max_mietdauer.value != null ? 'inputfield_valid' : ''">
                      <input type="number" min="1" step="1" placeholder="z.B. 24" formControlName="max_mietdauer">
                  </div> -->
                </div>
                <div class="lbl_bold dropdown_title_lbl">Objektart*</div>
                <div #dropdown_3 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="!f.vermarktungsart.value ? 'dropwdown_disabled' : (showDropTwo ? 'dropdown_header_active' : (f.objektart.value ? 'dropdown_valid' : ''))" (click)="!f.vermarktungsart.value ? null : showDropThree = !showDropThree">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.objektart.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.objektart.value ? f.objektart.value.name : 'Auswählen...'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropThree ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropThree ? 'dropdown_body_open' : ''">
                        <div *ngFor="let item of objektartenFiltered" class="dropdown_body_item lbl_regular" (click)="f.objektart.setValue(item); showDropThree = false">{{item.name}}</div>
                    </div>
                </div>
                <div class="lbl_bold dropdown_title_lbl">Erschließung</div>
                <div #dropdown_8 class="dropdown_container">
                  <div class="dropdown_header" [ngClass]="showDropEight ? 'dropdown_header_active' : (f.erschliessung.value ? 'dropdown_valid' : '')" (click)="showDropEight = !showDropEight">
                      <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.erschliessung.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.erschliessung.value ? f.erschliessung.value.name : 'Auswählen...'}}</div>
                      <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropEight ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                  </div>
                  <div class="dropdown_body" [ngClass]="showDropEight ? 'dropdown_body_open' : ''">
                      <div *ngFor="let item of erschliessungen" class="dropdown_body_item lbl_regular" (click)="f.erschliessung.setValue(item); showDropEight = false">{{item.name}}</div>
                  </div>
                </div>
                <div class="lbl_bold dropdown_title_lbl">Bebaubar nach</div>
                <div #dropdown_7 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropSeven ? 'dropdown_header_active' : (f.bebaubar_nach.value ? 'dropdown_valid' : '')" (click)="showDropSeven = !showDropSeven">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.bebaubar_nach.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.bebaubar_nach.value ? f.bebaubar_nach.value.name : 'Auswählen...'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropSeven ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropSeven ? 'dropdown_body_open' : ''">
                        <div *ngFor="let item of bebaubarNach" class="dropdown_body_item lbl_regular" (click)="f.bebaubar_nach.setValue(item); showDropSeven = false">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="datensatz">
                <div class="lbl_title_big_color lbl_small_title">Standortinformationen</div>
                <div class="lbl_bold dropdown_title_lbl">Bundesland*</div>
                <div #dropdown_6 class="dropdown_container">
                  <div class="dropdown_header" [ngClass]="showDropSix ? 'dropdown_header_active' : (f.bundesland.value ? 'dropdown_valid' : '')" (click)="showDropSix = !showDropSix">
                      <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.bundesland.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.bundesland.value ? f.bundesland.value.name : 'Auswählen...'}}</div>
                      <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropSix ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                  </div>
                  <div class="dropdown_body" [ngClass]="showDropSix ? 'dropdown_body_open' : ''">
                      <div *ngFor="let item of bundeslaender" class="dropdown_body_item lbl_regular" (click)="this.f.bundesland.setValue(item); showDropSix = false;">{{item.name}}</div>
                  </div>
                </div>
                <!-- <div class="lbl_bold dropdown_title_lbl">Bundesland</div>
                <div class="input_container" [ngClass]="f.bundesland.value != null ? 'inputfield_valid' : ''">
                    <input type="text" placeholder="z.B. Steiermark" formControlName="bundesland">
                </div> -->
                <!-- <div class="lbl_bold dropdown_title_lbl">Bezirk</div>
                <div class="input_container" [ngClass]="f.bezirk.value != null ? 'inputfield_valid' : ''">
                    <input type="text" placeholder="z.B. Graz-Umgebung" formControlName="bezirk">
                </div> -->
                <div class="grid">
                    <div>
                        <div class="lbl_bold dropdown_title_lbl">Straße</div>
                        <div class="input_container" [ngClass]="f.street.value != null ? 'inputfield_valid' : ''">
                            <input type="text" placeholder="z.B. Musterstraße" formControlName="street">
                        </div>
                    </div>
                    <div>
                        <div class="lbl_bold dropdown_title_lbl">Hausnummer</div>
                        <div class="input_container" [ngClass]="f.housnumber.value != null ? 'inputfield_valid' : ''">
                            <input type="text" placeholder="z.B. 8" formControlName="housnumber">
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div>
                        <div class="lbl_bold dropdown_title_lbl">PLZ*</div>
                        <div class="input_container"  [ngClass]="f.postal_code.value != null ? 'inputfield_valid' : ''">
                            <input type="number" placeholder="z.B. 8020" formControlName="postal_code">
                        </div>
                    </div>
                    <div>
                        <div class="lbl_bold dropdown_title_lbl">Ort</div>
                        <div class="input_container"  [ngClass]="f.city.value != null ? 'inputfield_valid' : ''">
                            <input type="text" placeholder="z.B. Graz" formControlName="city">
                        </div>
                    </div>
                </div>
                <div class="grid">
                  <div>
                      <div class="lbl_bold dropdown_title_lbl">Breitengrad</div>
                      <div class="input_container"  [ngClass]="f.latitude.value != null ? 'inputfield_valid' : ''">
                          <input type="number" placeholder="z.B. 47.01388° N" formControlName="latitude">
                      </div>
                  </div>
                  <div>
                      <div class="lbl_bold dropdown_title_lbl">Längengrad</div>
                      <div class="input_container"  [ngClass]="f.longitude.value != null ? 'inputfield_valid' : ''">
                          <input type="text" placeholder="z.B. 15.39868° O" formControlName="longitude">
                      </div>
                  </div>
              </div>
            </div>
            <div class="datensatz">
                <div class="lbl_title_big_color lbl_small_title">Zustand</div>
                <div class="lbl_bold dropdown_title_lbl">Zustand</div>
                <div #dropdown_4 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropFour ? 'dropdown_header_active' : (f.zustand.value ? 'dropdown_valid' : '')" (click)="showDropFour = !showDropFour">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.zustand.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.zustand.value ? f.zustand.value.name : 'Auswählen...'}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropFour ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropFour ? 'dropdown_body_open' : ''">
                        <div *ngFor="let item of zustaende" class="dropdown_body_item lbl_regular" (click)="f.zustand.setValue(item); showDropFour = false;">{{item.name}}</div>
                    </div>
                </div>
                <div class="lbl_bold dropdown_title_lbl">Baujahr</div>
                    <div class="input_container" [ngClass]="f.baujahr.value != null ? 'inputfield_valid' : ''">
                        <input type="number" placeholder="z.B. 2022" formControlName="baujahr">
                </div>
                <div class="lbl_bold dropdown_title_lbl">Gebäudetyp</div>
                <div #dropdown_5 class="dropdown_container">
                    <div class="dropdown_header" [ngClass]="showDropFive ? 'dropdown_header_active' : (f.ist_altbau.value != null ? 'dropdown_valid' : '')" (click)="showDropFive = !showDropFive">
                        <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.ist_altbau.value != null ? '' : 'dropdown_header_lbl_placeholder'">{{f.ist_altbau.value == true ? 'Altbau' : (f.ist_altbau.value == false ? 'Neubau' : 'Auswählen...')}}</div>
                        <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropFive ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                    </div>
                    <div class="dropdown_body" [ngClass]="showDropFive ? 'dropdown_body_open' : ''">
                        <div class="dropdown_body_item lbl_regular" (click)="f.ist_altbau.setValue(true); showDropFive = false;">Altbau</div>
                        <div class="dropdown_body_item lbl_regular" (click)="f.ist_altbau.setValue(false); showDropFive = false;">Neubau</div>
                    </div>
                </div>
                <!-- <div>
                  <div class="lbl_regular" [ngStyle]="{'opacity': f.firstRegistrationDate.value ? '1' : '0'}">Erstzulassung in MM/JJJJ *</div>
                  <div appCustomDateFormat1>
                      <input #dateInput2 class="inputfield" [ngClass]="submitted && (f.firstRegistrationDate.value == null || f.firstRegistrationDate.errors?.validateDateInput?.valid == false) ? 'inputfield_invalid' : ''" [ngStyle]="{'width': '78%'}" placeholder="Erstzulassung in MM/JJJJ *" type="datetime" [matDatepicker]="picker1" [formControl]="f.firstRegistrationDate" formControlName="firstRegistrationDate">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1
                                      startView="multi-year"
                      ></mat-datepicker>
                  </div>
                </div> -->
            </div>
        </div>
    </form>
</div>
