<div class="list_element" [ngStyle]="{'background-color': even ? '#f5f5f5d6' : ''}">
    <div class="img_container" [ngStyle]="{'border-color': immo.thumbnail ? 'transparent' : ''}">
      <img *ngIf="immo.thumbnail" class="img" [src]="immo.thumbnail">
      <div *ngIf="!immo.thumbnail" class="lbl_regular">Kein Bild</div>
    </div>
    <div class="lbl_regular">{{cutStr(immo.objektTitel, 75)}}</div>
    <div class="lbl_regular">{{immo.nutzungsart.name}}</div>
    <div class="lbl_regular">{{immo.vermarktungsart.name}}</div>
    <div class="lbl_regular">{{immo.objektart.name}}</div>
    <div class="lbl_regular">{{immo.geo.postalCode}} {{immo.geo.city}}</div>
    <div class="lbl_regular">{{immo.preise.preis_per_anfrage ? 'per Anfrage' : (immo.getPrice() ? (immo.getPrice() | currency: 'EUR': '€': '1.2-2') : '---')}}</div>
    <!-- <div class="lbl_regular">
      {{immo.vermarktungsart.id == 2 ?
        (immo.preise.nettokaltmiete ? (immo.preise.nettokaltmiete | currency: 'EUR': '€': '1.2-2') + ' / M.' : '---') :
        (immo.preise.kaufpreis ? (immo.preise.kaufpreis | currency: 'EUR': '€': '1.2-2') : '---')
      }}</div> -->
    <div class="lbl_regular">{{immo.flaeche.gesamtflaeche ? immo.flaeche.gesamtflaeche + 'm²' : '---'}}</div>
    <div class="lbl_regular">{{immo.created | date: 'dd.MM.YYYY'}}</div>
    <div #action_box class="actions">
      <img class="icon_dots" [ngClass]="showActions ? 'action_open' : ''" src="../../../../../assets/navdots.svg" (click)="showActions = !showActions">
      <div class="action_box" *ngIf="showActions">
        <div class="action_item" (click)="openDetailsEmitter.emit(immo); showActions = false">
          <img class="action_icon" src="../../../../../assets/eyeShow.svg">
          <div class="action_lbl lbl_regular">Details</div>
        </div>
        <div class="action_item" (click)="showActionStatus = true">
          <img class="action_icon" src="../../../../../assets/changestatus.svg">
          <div class="action_lbl lbl_regular">Status wechseln</div>
        </div>
        <div class="action_item" (click)="editEmitter.emit(immo)">
          <img class="action_icon" src="../../../../../assets/edit.svg">
          <div class="action_lbl lbl_regular">Bearbeiten</div>
        </div>
        <div class="action_item" (click)="copyEmitter.emit(immo)">
          <img class="action_icon" src="../../../../../assets/document-black.svg" style="transform: translate(-1px, 0px);">
          <div class="action_lbl lbl_regular">Kopieren</div>
        </div>
        <div class="action_item" (click)="deleteEmitter.emit(immo); showActions = false">
          <img class="action_icon" src="../../../../../assets/delete.svg">
          <div class="action_lbl lbl_regular">Löschen</div>
        </div>
      </div>
      <div class="action_box_status" *ngIf="showActions && showActionStatus">
        <div class="action_item" (click)="changeStatus(1); showActions = false; showActionStatus = false;">
          <img class="action_icon" src="../../../../../assets/changestatus.svg">
          <div class="action_lbl lbl_regular">Offen</div>
        </div>
        <div class="action_item" (click)="changeStatus(2); showActions = false; showActionStatus = false;">
          <img class="action_icon" src="../../../../../assets/changestatus.svg">
          <div class="action_lbl lbl_regular">Entwurf</div>
        </div>
        <div class="action_item" (click)="changeStatus(3); showActions = false; showActionStatus = false;">
          <img class="action_icon" src="../../../../../assets/changestatus.svg">
          <div class="action_lbl lbl_regular">Abgeschlossen</div>
        </div>
      </div>
    </div>
</div>
