import { Company } from "./Company";
import { Title } from "../ressource/Title";
import { Role } from "../ressource/Role";
import { Salutation } from "../ressource/Salutation";
import { Platform } from "../ressource/Platform";

export class User {
    constructor(
        public userId: number,
        public company: Company,
        public platforms: Platform[],
        public salutation: Salutation,
        public firstName: string,
        public lastName: string,
        public email: string,
        public phone: string,
        public roles: Role[],
        public title: Title
    ) {}

    public getJson(): any {
        let platforms: any[] = [];
        this.platforms.forEach(p => {
            platforms.push({platform_id: p.platformId});
        });
        let roles: any[] = [];
        this.roles.forEach(role => {
            roles.push({role_id: role.roleId});
        });
        let body = {
            user_id: this.userId,
            company: {
                company_id: this.company.companyId,
                company_name: this.company.companyName,
                street: this.company.street,
                city: this.company.city,
                postal_code: this.company.postalCode,
                house_number: this.company.houseNumber as String,
                email: this.company.email,
                phone: this.company.phone,
                atu_number: this.company.atu,
                url: this.company.url
            },
            platforms: platforms,
            salutation: {
                salutation_id: this.salutation.salutationId
            },
            title: {
                title_id: this.title.titleId
            },
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phone,
            roles: roles
        }
        return body;
    }

    getInitials(): string {
      return this.firstName.substring(0,1) + this.lastName.substring(0,1);
    }
}
