import { Preise } from "../Preise";

export class PreiseFactory {
    static jsonFactory(rawData: any): Preise[] {
        let datas: Preise[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Preise {
        return new Preise(
            rawData.is_qm,
            rawData.nettokaltmiete,
            rawData.nebenkosten,
            rawData.heizkosten,
            rawData.mietzuschlaege,
            rawData.provisionbrutto,
            rawData.kaution,
            (rawData.kaufpreis && rawData.kaufpreis == -1) ? null : rawData.kaufpreis,
            rawData.kaufpreisbrutto,
            rawData.monatlichekostenbrutto,
            rawData.kaufpreis_pro_qm,
            rawData.erschliessungskosten,
            rawData.mehrwertsteuer,
            (rawData.kaufpreis && rawData.kaufpreis == -1) ? true : false
        );
    }
}
