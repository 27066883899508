<div id="menu_container">
    <div>
      <div class="lbl_title_big_color lbl_title">IMMOBASE</div>
      <!-- <div id="item_dashboard" [ngClass]="isActive(1) ? 'itme_menu_active' : ''" (click)="loadPage('/dashboard')">
          <div [ngClass]="isActive(1) ? 'icon_dashboard_active' : 'icon_dashboard'"></div>
          <div class="lbl_bold" [ngClass]="isActive(1) ? 'lbl_active' : ''">Dashboard</div>
      </div> -->
      <div id="item_immo" [ngClass]="isActive(2) ? 'itme_menu_active' : ''" (click)="reload('/immobilien')">
          <!-- <img class="icon_menu" [src]="isActive(2) ? '../../../assets/menu/immofill.svg' : '../../../assets/menu/immofillblack.svg'"> -->
          <div [ngClass]="isActive(2) ? 'icon_immo_active' : 'icon_immo'"></div>
          <div class="lbl_bold" [ngClass]="isActive(2) ? 'lbl_active' : ''">Immobilien</div>
      </div>
      <!-- <div id="item_interface" [ngClass]="isActive(3) ? 'itme_menu_active' : ''" (click)="loadPage('/interface')">
          <img class="icon_menu" [src]="isActive(3) ? '../../../assets/menu/interfacefill.svg' : '../../../assets/menu/interfaceblack.svg'">
          <div [ngClass]="isActive(3) ? 'icon_interface_active' : 'icon_interface'"></div>
          <div class="lbl_bold" [ngClass]="isActive(3) ? 'lbl_active' : ''">Schnittstellen</div>
      </div> -->
      <div id="item_profile" [ngClass]="isActive(4) ? 'itme_menu_active' : ''" (click)="reload('/profile')">
          <!-- <img class="icon_menu" [src]="isActive(4) ? '../../../assets/menu/userfill.svg' : '../../../assets/menu/userblack.svg'"> -->
          <div [ngClass]="isActive(4) ? 'icon_profile_active' : 'icon_profile'"></div>
          <div class="lbl_bold" [ngClass]="isActive(4) ? 'lbl_active' : ''">Profil</div>
      </div>
    </div>
    <div id="menu_footer_container">
      <div class="btn_submit" onclick="location.href='mailto:support@waymark.at'">Support kontaktieren</div>
      <div id="logo_container" (click)="goToLink()">
          <div class="lbl_regular">Powered by</div>
          <img id="logo_waymark" src="../../../assets/waymarklogo.svg">
      </div>
      <label class="lbl_italic_light">{{appVersion}}</label>
    </div>
</div>
