export class Attachment {
  constructor(
    public anhang_id: number,
    public immobilie_id: number,
    public anhang_title: string,
    public format: string,
    public anhang_gruppe_id: number,
    public sort_value: number,
    public anhang?: string
  ){}

  getJson(): any {
    return {
      "immobilie_id" : this.immobilie_id,
      "anhang_titel" : this.anhang_title,
      "format" : this.format,
      "anhang_gruppe_id" : this.anhang_gruppe_id,
      "sort_value" : this.sort_value,
      "anhang" : this.anhang.split(',')[1]
    }
  }
}
