import { Zusatz } from './../../buisness-object/immobilie/Zusatz';
import { ZustandImmobilie } from './../../buisness-object/immobilie/ZustandImmobilie';
import { Flaeche } from './../../buisness-object/immobilie/Flaeche';
import { Preise } from './../../buisness-object/immobilie/Preise';
import { environment } from 'src/environments/environment';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { Immobilie } from 'src/app/buisness-object/immobilie/Immobilie';
import { ImmoService } from 'src/app/service/immo/immo.service';
import { RessourceService } from 'src/app/service/ressource/ressource.service';
import { Geo } from 'src/app/buisness-object/immobilie/Geo';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';
import { PageHandler } from 'src/app/helper/PagerHandler';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Filter } from 'src/app/helper/Filter';
import { GlobalVariables } from 'src/app/helper/GlobalVariables';

@Component({
  selector: 'app-immo-administration',
  templateUrl: './immo-administration.component.html',
  styleUrls: ['./immo-administration.component.scss']
})
export class ImmoAdministrationComponent implements OnInit, AfterViewInit, OnDestroy {
  public immobilien: Immobilie[] = [];
  public immobilienFiltered: Immobilie[] = [];
  public searchTemp: Immobilie[] = [];
  public nutzungsarten: ImmoRessource[] = [];
  public selectedNutzungsart: ImmoRessource;
  public verkaufsstatuse: ImmoRessource[] = [];
  public selectedVerkaufsstatuse: ImmoRessource;
  public objektarten: ImmoRessource[] = [];
  public vermarktungsarten: ImmoRessource[] = [];
  public selectedObjektarten: ImmoRessource;
  public immoForm: UntypedFormGroup;
  public pageCreate = 1;
  public submitted = false;
  public selectedAusstattung: ImmoRessource[] = [];
  public bundeslaender: ImmoRessource[] = [];
  public bebaubarNach: ImmoRessource[] = [];
  public dialogQuerySubsription: Subscription;
  public selectedImmo: Immobilie;
  public filter: Filter = new Filter(1,['Datum','Objekttitel','Preis','Größe']);
  public showCreate = false;
  public isEdit = false;
  public showDetails = false;
  public showDropOne = false;
  public showDropTwo = false;
  public showDropThree = false;
  public showDropFour = false;
  @ViewChild('dropdown_1') dropdown_1 : ElementRef;
  @ViewChild('dropdown_2') dropdown_2 : ElementRef;
  @ViewChild('dropdown_3') dropdown_3 : ElementRef;
  @ViewChild('dropdown_4') dropdown_4 : ElementRef;
  public listener: () => void;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public pathSub: Subscription;
  public routeSub: Subscription;
  public counterOffen: number;
  public counterEntwurf: number;
  public counterVerkauft: number;
  public formSubscription: Subscription;

  constructor(
    private iService: ImmoService,
    private rService: RessourceService,
    private rendener: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private dService: DialogService,
    private activateRoute: ActivatedRoute,
    private locationRoute: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
    this.configFormGroup();
    this.setDialogSubscription();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngAfterViewInit(): void {
    this.listener = this.rendener.listen('window','click',(e:Event) => {
      if(this.showDropOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropOne = false;
      if(this.showDropTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropTwo = false;
      if(this.showDropThree && !this.dropdown_3.nativeElement.contains(e.target)) this.showDropThree = false;
      if(this.showDropFour && !this.dropdown_4.nativeElement.contains(e.target)) this.showDropFour = false;
    });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.pathSub) this.pathSub.unsubscribe();
    if(this.routeSub) this.routeSub.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.formSubscription) this.formSubscription.unsubscribe();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  routeListener() {
    this.pathSub = this.activateRoute.url.subscribe((url) => {
      if(url && url[1] && url[1].path == 'anlegen'){
        this.openCreate();
      }
    });
    this.routeSub = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params.immobilieId)){
        for(const immo of this.immobilien){
          if(immo.immobilieId == Number(params.immobilieId)){
            this.openDetails(immo);
          }
        }
      }
    })
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete'){
          this.deleteImmobilie(value.submit_value);
        } else if(value.typ == 'cancel_create') {
          this.cancelCreate();
        }
      }
    });
  }

  getDataServerside() {
    forkJoin({
      one: this.iService.getImmos(),
      two: this.rService.getRessource('nutzungsart'),
      three: this.rService.getRessource('objektart'),
      four: this.rService.getRessource('vermarktungsart'),
      five: this.rService.getRessource('verkaufsstatus'),
      six: this.rService.getRessource('bundesland'),
      seven: this.rService.getRessource('bebaubar_nach'),
    }).subscribe((result) => {
      if(result.one){
        this.immobilien = result.one;
        this.immobilienFiltered = result.one;
        this.nutzungsarten = result.two;
        this.objektarten = result.three;
        this.vermarktungsarten = result.four;
        this.verkaufsstatuse = result.five;
        this.bundeslaender = result.six;
        this.bebaubarNach = result.seven;
        this.getCounters();
        this.getThumbnails();
        this.filterImmobilien();
        this.routeListener();
        //this.sortImmoblien(this.filter.selectedSortValue);
        this.sortRessources();
      }
    });
  }

  getCounters() {
    this.counterOffen = 0;
    this.counterEntwurf = 0;
    this.counterVerkauft = 0;
    for(let immo of this.immobilien){
      if(immo.category == 1) this.counterOffen++;
      else if(immo.category == 2) this.counterEntwurf++;
      else if(immo.category == 3) this.counterVerkauft++;
    }
  }

  getThumbnails() {
    for(let immo of this.immobilien){
      let index = immo.attachments.findIndex(doc => doc.anhang_gruppe_id == 1);
      if(!immo.thumbnail && index > -1){
        this.iService.getThumbnail(immo.attachments[index].anhang_id).subscribe((result) => {
          if(result){
            immo.thumbnail = 'data:image/png;base64,'+result.trim();
          }
        })
      }
    }
  }

  sortRessources() {
    this.sortRessourcesAlphabetical(this.nutzungsarten);
    this.sortRessourcesAlphabetical(this.objektarten);
    this.sortRessourcesAlphabetical(this.vermarktungsarten);
    this.sortRessourcesAlphabetical(this.verkaufsstatuse);
    this.sortRessourcesAlphabetical(this.bundeslaender);
    this.sortRessourcesAlphabetical(this.bebaubarNach);
  }

  sortRessourcesAlphabetical(data: any) {
    data.sort((a,b) => {
      if(a.name > b.name) return 1;
      if(a.name < b.name) return -1;
      return 0;
    });
  }

  setPages() {
    this.pageHandler = new PageHandler(10, this.immobilienFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  async filterImmobilien() {
    if(this.filter.tabFilter == 1) this.filterStatus(1);
    else if(this.filter.tabFilter == 2) this.filterStatus(2);
    else if(this.filter.tabFilter == 3) this.filterStatus(3);
    this.filterValueNutzungsart(this.filter.selectedValue_1);
    this.filterValueVerkaufsart(this.filter.selectedValue_2);
    this.filterValueObjektart(this.filter.selectedValue_3);
    this.filterValueStandort(this.filter.selectedValue_4);
    this.sortImmoblien(this.filter.selectedSortValue)
    this.setPages();
    this.searchTemp = this.immobilienFiltered;
  }
  filterStatus(status: number) {
    this.immobilienFiltered = [];
    this.immobilienFiltered = this.immobilien.filter((immobillie) => {
      return immobillie.category == status;
    });
  }
  filterValueNutzungsart(value: any){
    if(value){
      let temp = this.immobilienFiltered;
      this.immobilienFiltered = [];
      this.immobilienFiltered = temp.filter((immoblillien) => {
        return immoblillien.nutzungsart.id == value.id;
      })
    }
  }
  filterValueVerkaufsart(value: any){
    if(value){
      let temp = this.immobilienFiltered;
      this.immobilienFiltered = [];
      this.immobilienFiltered = temp.filter((immoblillien) => {
        return immoblillien.vermarktungsart.id == value.id;
      })
    }
  }
  filterValueObjektart(value: any){
    if(value){
      let temp = this.immobilienFiltered;
      this.immobilienFiltered = [];
      this.immobilienFiltered = temp.filter((immoblillien) => {
        return immoblillien.objektart.id == value.id;
      })
    }
  }
  filterValueStandort(value: any){
    if(value){
      let temp = this.immobilienFiltered;
      this.immobilienFiltered = [];
      this.immobilienFiltered = temp.filter((immoblillien) => {
        return immoblillien.geo.bundesland == value.id;
      });
    }
  }
  sortImmoblien(value: any) {
    this.filter.selectedSortValue = value;
    if(value && this.filter.sortAscend){
      if(value.id == 2){
        this.immobilienFiltered.sort((a,b) => {
          if(a.objektTitel > b.objektTitel) return 1;
          if(a.objektTitel < b.objektTitel) return -1;
          return 0;
        })
      } else if(value.id == 3){
        this.immobilienFiltered.sort((a,b) => {
          if((a.vermarktungsart.id == 1 ? a.preise.kaufpreis : a.preise.nettokaltmiete) > (b.vermarktungsart.id == 1 ? b.preise.kaufpreis : b.preise.nettokaltmiete)) return 1;
          if((a.vermarktungsart.id == 1 ? a.preise.kaufpreis : a.preise.nettokaltmiete) < (b.vermarktungsart.id == 1 ? b.preise.kaufpreis : b.preise.nettokaltmiete)) return -1;
          return 0;
        })
      } else if(value.id == 1){
        this.immobilienFiltered.sort((a,b) => {
          if(a.modified < b.modified) return 1;
          if(a.modified > b.modified) return -1;
          return 0;
        })
      } else if(value.id == 4){
        this.immobilienFiltered.sort((a,b) => {
          if(a.flaeche.gesamtflaeche > b.flaeche.gesamtflaeche) return 1;
          if(a.flaeche.gesamtflaeche < b.flaeche.gesamtflaeche) return -1;
          return 0;
        })
      }
    } else {
      if(value.id == 2){
        this.immobilienFiltered.sort((a,b) => {
          if(a.objektTitel < b.objektTitel) return 1;
          if(a.objektTitel > b.objektTitel) return -1;
          return 0;
        })
      } else if(value.id == 3){
        this.immobilienFiltered.sort((a,b) => {
          if((a.vermarktungsart.id == 1 ? a.preise.kaufpreis : a.preise.nettokaltmiete) < (b.vermarktungsart.id == 1 ? b.preise.kaufpreis : b.preise.nettokaltmiete)) return 1;
          if((a.vermarktungsart.id == 1 ? a.preise.kaufpreis : a.preise.nettokaltmiete) > (b.vermarktungsart.id == 1 ? b.preise.kaufpreis : b.preise.nettokaltmiete)) return -1;
          return 0;
        })
      } else if(value.id == 1){
        this.immobilienFiltered.sort((a,b) => {
          if(a.modified > b.modified) return 1;
          if(a.modified < b.modified) return -1;
          return 0;
        })
      } else if(value.id == 4){
        this.immobilienFiltered.sort((a,b) => {
          if(a.flaeche.gesamtflaeche < b.flaeche.gesamtflaeche) return 1;
          if(a.flaeche.gesamtflaeche > b.flaeche.gesamtflaeche) return -1;
          return 0;
        })
      }
    }
  }

  configFormGroup() {
    this.immoForm = this.formBuilder.group({
      immobilien_id: [null],
      category: [2, Validators.required],
      objekt_titel: ['', Validators.required],
      objektnr: ['', Validators.required],
      nutzungsart: [null, Validators.required],
      vermarktungsart: [null, Validators.required],
      objektart: [null, Validators.required],
      bebaubar_nach: [null],
      zustand: [null],
      baujahr: [null],
      ist_altbau: [null],
      erschliessung: [null],
      verkaufsstatus: [null],
      min_mietdauer: [null],
      max_mietdauer: [null],
      mietdauer: [null],
      ausstattung: [[]],
      status: [1],
      attachments: [[]],
      //Geo
      bundesland: [null, Validators.required],
      //bezirk: [null],
      street: [null],
      housnumber: [null],
      postal_code: [null, Validators.required],
      city: [null],
      latitude: [null],
      longitude: [null],
      //Preise
      preisangabe_quadratmeter: [false],
      preis_per_anfrage: [false],
      netto_kaltemiete: [null, this.validateNumberTwoDecimals],
      nebenkosten: [null],
      heitzkosten: [null],
      mehrwertsteuer: [null],
      mietzuschlag: [null, this.validateNumberTwoDecimals],
      provision: [null, this.validateNumberTwoDecimals],
      kaution: [null, this.validateNumberTwoDecimals],
      kaufpreis: [{value:null, disabled: false}, this.validateNumberTwoDecimals],
      kaufpreisbrutto: [null, this.validateNumberTwoDecimals],
      monatlichekostenbrutto: [{value:null, disabled: false}, this.validateNumberTwoDecimals],
      kaufpreis_pro_qm: [null, this.validateNumberTwoDecimals],
      erschliessungskosten: [null, this.validateNumberTwoDecimals],
      //Fläche
      anzahl_zimmer: [null],
      anzahl_badezimmer: [null],
      anzahl_logia: [null],
      anzahl_schlafzimmer: [null],
      anzahl_sep_wc: [null],
      anzahl_stellplaetze: [null],
      gesamtflaeche: [null],
      wohnflaeche: [null],
      beheizbare_flaeche: [null],
      grundstuecksflaeche: [null],
      nutzflaeche: [null],
      sonstige_flaeche: [null],
      kellerflaeche: [null],
      dachbodenflaeche: [null],
      gartenflaeche: [null],
      logia: [false],
      balkone_terrassen: [false],
      bt_flaeche: [null],
      anzahl_balkone: [null],
      anzahl_terrassen: [null],
      //Austattung
      ausricht_balkon_terrasse: [null],
      angeschl_gastronomie: [null],
      moebliert: [null],
      ausbaustufe: [null],
      bauweise: [null],
      dachform: [null],
      energietyp: [null],
      stellplatzart: [null],
      dielen: [false],
      doppelboden: [false],
      estrich: [false],
      fertigparkett: [false],
      fliesen: [false],
      granit: [false],
      kunststoff: [false],
      laminat: [false],
      linoleum: [false],
      marmor: [false],
      parkett: [false],
      stein: [false],
      teppich: [false],
      terrakotta: [false],
      wg_geeignet: [false],
      raeume_veraenderbar: [false],
      abstellraum: [false],
      fahrradraum: [false],
      saune: [false],
      swimmingpool: [false],
      wasch_und_trockeraum: [false],
      wintergarten: [false],
      klimatisiert: [false],
      moebiliert: [false],
      rolladen: [false],
      dvbt: [false],
      kabel_sat: [false],
      dv_verkabelung: [false],
      alarmanlage: [false],
      kamera: [false],
      polizeiruf: [false],
      dusche: [false],
      wanne: [false],
      bad_fenster: [false],
      bidet: [false],
      pissoir: [false],
      kueche: [null],
      barrierefrei: [false],
      fahrstuhl_personen: [false],
      fahrstuhl_lasten: [false],
      rampe: [false],
      rollstuhlgerecht: [false],
      seniorengerecht: [false],
      heizungsart: [null],
      befeuerung: [null],
      kamin: [false],
      bar: [false],
      gaeste_wc: [false],
      gasterrasse: [false],
      hebebuehne: [false],
      hotelrestaurant: [false],
      kantine: [false],
      kran: [false],
      teekueche: [false],
      sporteinrichtung: [false],
      wellnessbereich: [false],
      hallenhoehe: [null],
      //Beschreibungen
      kurzbeschreibung: [null],
      lagebeschreibung: [null],
      objektbeschreibung: [null],
      sonstige_angaben: [null],
      sonstige_angaben_austattung: [null],
      //Anhänge
      bilder: [null],
      dokumente: [null]
    });
  }

  get f() {
    return this.immoForm.controls;
  }

  fillForm() {
    this.configFormGroup();
    this.f.immobilien_id.setValue(this.selectedImmo.immobilieId)
    this.f.category.setValue(this.selectedImmo.category);
    this.f.objekt_titel.setValue(this.selectedImmo.objektTitel);
    this.f.objektnr.setValue(this.selectedImmo.objektnr);
    this.f.nutzungsart.setValue(this.selectedImmo.nutzungsart);
    this.f.vermarktungsart.setValue(this.selectedImmo.vermarktungsart);
    this.f.objektart.setValue(this.selectedImmo.objektart);
    this.f.ausstattung.setValue(JSON.parse(JSON.stringify(this.selectedImmo.ausstattung)));
    this.f.attachments.setValue(this.selectedImmo.attachments);
    //Geo
    this.f.bundesland.setValue(this.selectedImmo.geo.bundesland);
    //this.f.bezirk.setValue(this.selectedImmo.geo.bezirk);
    this.f.street.setValue(this.selectedImmo.geo.street);
    this.f.housnumber.setValue(this.selectedImmo.geo.housNumber);
    this.f.postal_code.setValue(this.selectedImmo.geo.postalCode);
    this.f.city.setValue(this.selectedImmo.geo.city);
    this.f.latitude.setValue(this.selectedImmo.geo.latitude);
    this.f.longitude.setValue(this.selectedImmo.geo.longitude);
    //Preise
    this.f.preisangabe_quadratmeter.setValue(this.selectedImmo.preise.isQm);
    this.f.preis_per_anfrage.setValue(this.selectedImmo.preise.preis_per_anfrage);
    this.f.nebenkosten.setValue(this.selectedImmo.preise.nebenkosten);
    this.f.heitzkosten.setValue(this.selectedImmo.preise.heizkosten);
    this.f.mietzuschlag.setValue(this.selectedImmo.preise.mietzuschlaege);
    this.f.kaution.setValue(this.selectedImmo.preise.kaution);
    this.f.provision.setValue(this.selectedImmo.preise.provisionbrutto);
    this.f.kaufpreis.setValue(this.selectedImmo.preise.kaufpreis);
    this.f.kaufpreisbrutto.setValue(this.selectedImmo.preise.kaufpreisbrutto);
    this.f.monatlichekostenbrutto.setValue(this.selectedImmo.preise.monatlichekostenbrutto);
    this.f.kaufpreis_pro_qm.setValue(this.selectedImmo.preise.kaufpreis_pro_qm);
    this.f.erschliessungskosten.setValue(this.selectedImmo.preise.erschliessungskosten);
    this.f.mehrwertsteuer.setValue(this.selectedImmo.preise.mehrwertsteuer);
    if(this.immoForm.controls.monatlichekostenbrutto.value && (
      this.immoForm.controls.nebenkosten.value ||
      this.immoForm.controls.heitzkosten.value ||
      this.immoForm.controls.mietzuschlag.value ||
      this.immoForm.controls.mehrwertsteuer.value
    )){
      let mieteNetto = this.immoForm.controls.monatlichekostenbrutto.value - this.immoForm.controls.mehrwertsteuer.value;
      mieteNetto = mieteNetto -
        this.immoForm.controls.nebenkosten.value -
        this.immoForm.controls.heitzkosten.value -
        this.immoForm.controls.mietzuschlag.value;
      this.immoForm.controls.netto_kaltemiete.setValue(mieteNetto);
      this.selectedImmo.preise.nettokaltmiete = mieteNetto;
    }
    //Fläche
    this.f.wohnflaeche.setValue(this.selectedImmo.flaeche.wohnflaeche);
    this.f.nutzflaeche.setValue(this.selectedImmo.flaeche.nutzflaeche);
    this.f.gesamtflaeche.setValue(this.selectedImmo.flaeche.gesamtflaeche);
    this.f.grundstuecksflaeche.setValue(this.selectedImmo.flaeche.grundstuecksflaeche);
    this.f.sonstige_flaeche.setValue(this.selectedImmo.flaeche.sonstflaeche);
    this.f.anzahl_zimmer.setValue(this.selectedImmo.flaeche.anzahlZimmer);
    this.f.anzahl_schlafzimmer.setValue(this.selectedImmo.flaeche.anzahlSchlafzimmer);
    this.f.anzahl_badezimmer.setValue(this.selectedImmo.flaeche.anzahlBadezimmer);
    this.f.anzahl_sep_wc.setValue(this.selectedImmo.flaeche.anzahlSep_wc);
    this.f.anzahl_balkone.setValue(this.selectedImmo.flaeche.anzahlBalkone);
    this.f.anzahl_terrassen.setValue(this.selectedImmo.flaeche.anzahlTerrassen);
    this.f.anzahl_logia.setValue(this.selectedImmo.flaeche.anzahlLogia);
    this.f.bt_flaeche.setValue(this.selectedImmo.flaeche.balkonTerrasseFlaeche);
    this.f.gartenflaeche.setValue(this.selectedImmo.flaeche.gartenflaeche);
    this.f.kellerflaeche.setValue(this.selectedImmo.flaeche.kellerflaeche);
    this.f.dachbodenflaeche.setValue(this.selectedImmo.flaeche.dachbodenflaeche);
    this.f.beheizbare_flaeche.setValue(this.selectedImmo.flaeche.beheizbare_flaeche);
    // this.f.anzahl_stellplaetze.setValue(this.selectedImmo.flaeche.anzahlStellplaetze);
    // this.f.balkone_terrassen.setValue(this.selectedImmo.flaeche.balkone_terrassen);
    //Zustand
    this.f.baujahr.setValue(this.selectedImmo.zustand.baujahr);
    this.f.zustand.setValue(this.selectedImmo.zustand.zustand);
    this.f.ist_altbau.setValue(this.selectedImmo.zustand.istAltbau);
    this.f.bebaubar_nach.setValue(this.selectedImmo.zustand.bebaubarNach);
    this.f.erschliessung.setValue(this.selectedImmo.zustand.erschliessung);
    this.f.verkaufsstatus.setValue(this.selectedImmo.zustand.verkaufstatus);
    this.f.min_mietdauer.setValue(this.selectedImmo.zustand.minMietDauer);
    this.f.max_mietdauer.setValue(this.selectedImmo.zustand.maxMietDauer);
    this.f.mietdauer.setValue(this.selectedImmo.zustand.mietdauer);
    //Zusatz
    this.f.kueche.setValue(this.selectedImmo.zusatz.kueche);
    this.f.heizungsart.setValue(this.selectedImmo.zusatz.heizungsart);
    this.f.befeuerung.setValue(this.selectedImmo.zusatz.befeuerung);
    this.f.stellplatzart.setValue(this.selectedImmo.zusatz.stellplatzart);
    this.f.ausricht_balkon_terrasse.setValue(this.selectedImmo.zusatz.ausricht_balkon_terrasse);
    this.f.moebliert.setValue(this.selectedImmo.zusatz.moebliert);
    this.f.dachform.setValue(this.selectedImmo.zusatz.dachform);
    this.f.bauweise.setValue(this.selectedImmo.zusatz.bauweise);
    this.f.ausbaustufe.setValue(this.selectedImmo.zusatz.ausbaustufe);
    this.f.energietyp.setValue(this.selectedImmo.zusatz.energietyp);
    this.f.angeschl_gastronomie.setValue(this.selectedImmo.zusatz.angeschl_gastronomie);
    this.f.hallenhoehe.setValue(this.selectedImmo.zusatz.hallenhoehe);
    //Beschreibungen
    this.f.lagebeschreibung.setValue(this.selectedImmo.lageBescreibung);
    this.f.sonstige_angaben_austattung.setValue(this.selectedImmo.ausstattungBeschreibung);
    this.f.objektbeschreibung.setValue(this.selectedImmo.objektbeschreibung);
    this.f.sonstige_angaben.setValue(this.selectedImmo.sonstigeAngaben);
    this.f.kurzbeschreibung.setValue(this.selectedImmo.kurzBeschreibung);
  }

  onUnload = e => {
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }

  openCreate() {
    this.pageCreate = 1;
    this.configFormGroup();
    this.formSubscription = this.immoForm.valueChanges.subscribe(change => {
      GlobalVariables.is_create_process = true;
      window.addEventListener('beforeunload', this.onUnload);
      this.formSubscription.unsubscribe();
    })
    this.locationRoute.replaceState('/immobilien/anlegen');
    this.showCreate = true;
  }
  cancelCreate() {
    if(this.selectedImmo){
      this.deleteImmobilie(this.selectedImmo.immobilieId, true);
    }
    this.configFormGroup();
    this.formSubscription.unsubscribe();
    GlobalVariables.is_create_process = false;
    window.removeEventListener('beforeunload', this.onUnload);
    this.selectedAusstattung = [];
    this.showCreate = false;
    this.locationRoute.replaceState('/immobilien');
  }

  async pageHandlingCreate(next: boolean) {
    if(next){
      if(environment.developer){
        this.pageCreate++;
      } else {
        switch (this.pageCreate) {
          case 1: this.createImmo(); this.pageCreate++; break;
          case 6: this.immoForm.controls.category.setValue(1); this.createImmo(); break;
          default: this.pageCreate++; break;
        }
      }
    } else {
      this.pageCreate--;
    }
    this.submitted = false;
  }

  isValidPrice(): boolean {
    return true;
  }

  openDetails(immo: Immobilie) {
    this.selectedImmo = immo;
    this.fillForm();
    this.locationRoute.replaceState('/immobilien?immobilieId=' + immo.immobilieId);
    this.isEdit = false;
    this.showCreate = false;
    this.showDetails = true;
  }
  closeDetails() {
    this.selectedImmo = null;
    this.configFormGroup();
    this.locationRoute.replaceState('/immobilien');
    this.showDetails = false;
  }

  openEdit(immo: Immobilie) {
    this.selectedImmo = immo;
    this.fillForm();
    this.isEdit = true;
    this.showDetails = true;
  }

  createImmoObject(): Immobilie {
    return new Immobilie(
      this.selectedImmo ? this.selectedImmo.immobilieId : 0,
      Number(localStorage.getItem('user_id')),
      this.f.category.value, //1:offen,2:entwurf,3:abgeschlossen,
      this.f.vermarktungsart.value,
      this.f.objektart.value,
      this.f.nutzungsart.value,
      this.f.objekt_titel.value,
      new Date().getTime(),
      new Date().getTime(),
      new Geo(
        this.f.postal_code.value,
        this.f.bundesland.value,
        this.f.latitude.value,
        this.f.longitude.value,
        this.f.city.value,
        this.f.street.value,
        this.f.housnumber.value,
      ),
      new Preise(
        this.f.preisangabe_quadratmeter.value,
        this.f.netto_kaltemiete.value,
        this.f.nebenkosten.value,
        this.f.heitzkosten.value,
        this.f.mietzuschlag.value,
        this.f.provision.value,
        this.f.kaution.value,
        this.f.vermarktungsart.value.id == 1 ? (this.f.preis_per_anfrage.value == true ? -1 : this.f.kaufpreis.value) : this.f.kaufpreis.value,
        this.f.kaufpreisbrutto.value,
        this.f.vermarktungsart.value.id == 2 ? (this.f.preis_per_anfrage.value == true ? -1 : this.f.monatlichekostenbrutto.value) : this.f.monatlichekostenbrutto.value,
        this.f.kaufpreis_pro_qm.value,
        this.f.erschliessungskosten.value,
        this.f.mehrwertsteuer.value,
        this.f.preis_per_anfrage.value
      ),
      new Flaeche(
        this.f.wohnflaeche.value,
        this.f.nutzflaeche.value,
        this.f.gesamtflaeche.value,
        this.f.grundstuecksflaeche.value,
        this.f.sonstige_flaeche.value,
        this.f.anzahl_zimmer.value,
        this.f.anzahl_schlafzimmer.value,
        this.f.anzahl_badezimmer.value,
        this.f.anzahl_sep_wc.value,
        this.f.anzahl_balkone.value,
        this.f.anzahl_terrassen.value,
        this.f.anzahl_logia.value,
        this.f.bt_flaeche.value,
        this.f.gartenflaeche.value,
        this.f.kellerflaeche.value,
        this.f.dachbodenflaeche.value,
        this.f.beheizbare_flaeche.value,
      ),
      new ZustandImmobilie(
        this.f.baujahr.value,
        this.f.zustand.value,
        this.f.ist_altbau.value,
        this.f.bebaubar_nach.value,
        this.f.erschliessung.value,
        this.f.verkaufsstatus.value,
        this.f.min_mietdauer.value,
        this.f.max_mietdauer.value,
      ),
      new Zusatz(
        this.f.kueche.value,
        this.f.heizungsart.value,
        this.f.befeuerung.value,
        this.f.stellplatzart.value,
        this.f.ausricht_balkon_terrasse.value,
        this.f.moebliert.value,
        this.f.dachform.value,
        this.f.bauweise.value,
        this.f.ausbaustufe.value,
        this.f.energietyp.value,
        this.f.angeschl_gastronomie.value,
        this.f.hallenhoehe.value,
      ),
      this.f.ausstattung.value,
      this.f.lagebeschreibung.value,
      this.f.sonstige_angaben_austattung.value,
      this.f.objektbeschreibung.value,
      this.f.sonstige_angaben.value,
      this.f.objektnr.value,
      this.f.kurzbeschreibung.value,
      this.f.attachments.value,
    );
  }

  createImmo() {
    let immo = this.createImmoObject();
    if(!this.selectedImmo){
      this.iService.createImmo(immo).subscribe((result) => {
        if(result){
          this.selectedImmo = result;
          this.immoForm.controls.immobilien_id.setValue(result.immobilieId);
          this.setPages();
        }
      })
    } else {
      this.iService.updateImmo(immo).subscribe((result) => {
        if(result){
          this.immobilienFiltered = this.immobilien;
          if(this.pageCreate == 6){
            this.immobilien.push(result);
            this.immobilienFiltered.push(result);
            this.getCounters();
            this.openDetails(result)
          } else if(this.isEdit) {
            for(let a of result.attachments){
              if(a.anhang_gruppe_id == 1){
                this.iService.getThumbnail(a.anhang_id).subscribe((data) => {
                  if(data){
                    result.thumbnail = 'data:image/png;base64,'+data.trim();
                  }
                });
                break;
              }
            }
            let index = this.immobilien.findIndex(immobilien => immobilien.immobilieId == result.immobilieId);
            if(index > -1) this.immobilien[index] = result;
            this.filterImmobilien();
            this.openDetails(result);
          }
          if(this.pageCreate == 6){
            this.dService.showNotification({
              title: 'Erfolgreich',
              message: 'Immobilie erstellt.',
              success: true
            });
          }
        }
      })
    }
  }

  deleteAction(immobilie: Immobilie) {
    this.dService.openQuery(
      {
        title: 'Immobilie wirklich löschen?',
        message: 'Sind sie sicher, dass Sie diese Immobilie unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete',
        submit_value: immobilie.immobilieId,
      }
    );
  }
  deleteImmobilie(id: number, isCreate?: boolean){
    this.iService.deleteImmo(id).subscribe((success) => {
      if(success){
        let index = this.immobilien.findIndex(immo => immo.immobilieId == id);
        if(index != -1){
          this.immobilien.splice(index , 1);
        }
        index = this.immobilienFiltered.findIndex(immo => immo.immobilieId == id);
        if(index != -1){
          this.immobilienFiltered.splice(index , 1);
        }
        this.selectedImmo = null;
        this.showDetails = false;
        this.getCounters();
        this.setPages();
        if(isCreate == undefined){
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Immobilie gelöscht',
            success: true
          });
        }
      }
    })
  }

  updateImmobilie() {
    if(this.immoForm.valid){
      this.createImmo();
    }
  }

  saveAsDraft() {
    let immo = this.createImmoObject();
    immo.category = 2;
    if(this.pageCreate == 1){
      this.iService.createImmo(immo).subscribe((result) => {
        if(result){
          this.immobilien.push(result);
          this.immobilienFiltered = this.immobilien;
          this.getCounters();
          this.filterImmobilien();
          this.openDetails(result)
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Immobilie als Entwurf gespeichert.',
            success: true
          });
        }
      })
    } else {
      immo.immobilieId = this.selectedImmo.immobilieId;
      this.iService.updateImmo(immo).subscribe((result) => {
        if(result){
          for(let a of result.attachments){
            if(a.anhang_gruppe_id == 1){
              this.iService.getThumbnail(a.anhang_id).subscribe((data) => {
                if(data){
                  result.thumbnail = 'data:image/png;base64,'+data.trim();
                }
              });
              break;
            }
          }
          this.immobilien.push(result);
          this.immobilienFiltered = this.immobilien;
          this.getCounters();
          this.filterImmobilien();
          this.openDetails(result);
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Immobilie als Entwurf gespeichert.',
            success: true
          });
        }
      })
    }
  }

  validateNumberTwoDecimals(c: UntypedFormControl) {
    var regex = /^\d+(\.\d{0,2})?$/g;
    if (c.value > 0 && !regex.test(c.value)) {
      return {
        valid_number: {
          valid: false
        }
      };
    }
  }

  changeStatus(data: any) {
    let index = this.immobilien.findIndex(i => i.immobilieId == data.id);
    let immo = this.immobilien[index];
    immo.category = data.status;
    this.iService.updateImmo(immo).subscribe((result) => {
      if(result){
        this.immobilien[index].category = data.status;
        this.getCounters();
        this.filterImmobilien();
      }
    })
  }

  copyImmobilie(immo: Immobilie) {
    this.iService.copyImmo(immo).subscribe((result) => {
      if(result){
        result.category = 2;
        this.iService.updateImmo(result).subscribe((result_2) => {
          this.immobilien.push(result_2);
          this.filter.tabFilter = 2;
          this.filterImmobilien();
          this.getCounters();
          this.openEdit(result_2);
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Immobilie kopiert.',
            success: true
          });
        })
      }
    })
  }

  searchAction(searchValue: string) {
    if(searchValue && searchValue.length > 0){
      this.immobilienFiltered = [];
      this.immobilienFiltered = this.searchTemp.filter((immo) => {
        return immo.objektTitel.toLowerCase().includes(searchValue.toLowerCase()) ||
               String(immo.vermarktungsart.id == 2 ? immo.preise.nettokaltmiete : immo.preise.kaufpreis).toLowerCase().includes(searchValue.toLowerCase()) ||
               String(immo.flaeche.gesamtflaeche).toLowerCase().includes(searchValue.toLowerCase()) ||
               String(immo.geo.postalCode).toLowerCase().includes(searchValue.toLowerCase()) ||
               String(immo.geo.city).toLowerCase().includes(searchValue.toLowerCase());
      });
    } else {
      this.filterImmobilien();
    }
    this.setPages();
  }
}
