import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./dialog-password.component.scss']
})
export class DialogPasswordComponent implements OnInit {
  public showPasswordOne = false;
  public showPasswordTwo = false;
  public submitted = false;
  public passwordForm: UntypedFormGroup;

  @Output() closeEmitter = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uService: UserService
  ) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password_new: ['',[Validators.required, Validators.minLength(8)]],
      password_old: ['',[Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if(this.passwordForm.valid){
      this.uService.updatePassword(this.passwordForm.controls.password_new.value, this.passwordForm.controls.password_old.value).subscribe((success) => {
        if(success){
          this.closeEmitter.emit(true);
        }
      })
    }
  }
}
