<app-interface-titlebar [title]="'Schnittstellen'"></app-interface-titlebar>
<div class="view_container">
    <div class="grid">
        <div class="data_box_container">
            <div class="lbl_title_big_color" [ngStyle]="{'margin-bottom': '30px'}">Ihre Schnittstellen</div>
            <div *ngFor="let platform of platforms" class="flex_slider">
                <div class="lbl_regular">{{platform.name}}</div>
                <div class="slider_container" (click)="handleSelectPlatform(platform)">
                    <div class="slider_still" [ngClass]="isPlatformSelected(platform) ? '' : 'slider_still_on'"></div>
                    <div class="slider_move" [ngClass]="isPlatformSelected(platform) ? 'slider_on' : 'slider_off'"></div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="data_box_container">
                <div class="flex_slider" [ngStyle]="{'margin-bottom': '30px'}">
                    <div class="lbl_title_big_color">Website</div>
                    <div class="lbl_color_box_yellow">Status 1</div>
                </div>
                <div class="lbl_data_color">Matiuasdf.at</div>
            </div>
            <div class="data_box_container">
                <div class="lbl_title_big_color" [ngStyle]="{'margin-bottom': '30px'}">API Key</div>
                <div class="lbl_regular">26db975ed7ebe6bd00f6d7bf1094074930f1ae8285aed0432b28439</div>
                <div class="lbl_data_color">Key kopieren</div>
            </div>
        </div>
    </div>
</div>
