<div class="list_container">
    <div class="list_header">
        <div class="paging_container">
            <div *ngIf="sliceFrom == 0" style="width: 36px;"></div>
            <img *ngIf="sliceFrom != 0" class="icon_arrow_left" src="../../../../assets/arrow.svg" (click)="pageEmitter.emit(false)">
            <div class="lbl_regular">{{pageInfo}}</div>
            <div *ngIf="sliceTo == amount" style="width: 36px;"></div>
            <img *ngIf="sliceTo != amount" class="icon_arrow_right" src="../../../../assets/arrow.svg" (click)="pageEmitter.emit(true)">
        </div>
        <div class="btn_header_container">
            <img class="icon_search" src="../../../../assets/searchblue.svg" (click)="openSearchAction()">
            <input #search id="search" *ngIf="showSearchInput" type="text" placeholder="Suche..." (input)="searchEmitter.emit(search.value);">
            <div class="btn_tab" [ngClass]="filter.sortAscend ? 'btn_tab_active' : ''" (click)="filter.sortAscend = true; sortEmitter.emit(filter.selectedSortValue)">Aufsteigend</div>
            <div class="btn_tab" [ngClass]="!filter.sortAscend ? 'btn_tab_active' : ''" (click)="filter.sortAscend = false; sortEmitter.emit(filter.selectedSortValue)">Absteigend</div>
            <div #dropdown_1 class="dropdown_container">
                <div class="dropdown_header" [ngClass]="showDropTwo ? 'dropdown_header_active' : ''" (click)="showDropTwo = !showDropTwo">
                    <div class="lbl_regular dropdown_header_lbl">{{filter.selectedSortValue.name}}</div>
                    <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTwo ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                </div>
                <div class="dropdown_body" [ngClass]="showDropTwo ? 'dropdown_body_open' : ''">
                    <div *ngFor="let option of filter.sortOptions" class="dropdown_body_item lbl_regular" [ngClass]="option.id == filter.selectedSortValue?.id ? 'dropdown_body_item_active' : ''" (click)="sortEmitter.emit(option); showDropTwo = false;">{{option.name}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="list_title_container">
        <div class="lbl_bold">Bild</div>
        <div class="lbl_bold">Objekttitel</div>
        <div class="lbl_bold">Nutzungsart</div>
        <div class="lbl_bold">Vermarktungsart</div>
        <div class="lbl_bold">Objektart</div>
        <div class="lbl_bold">Standort</div>
        <div class="lbl_bold">Preis</div>
        <div class="lbl_bold">Größe</div>
        <div class="lbl_bold">Inseriert am</div>
        <div></div>
    </div>
    <div class="list_items_container">
      <app-immo-element *ngFor="let immo of immos | slice:sliceFrom:sliceTo; let even = even"
        [immo]="immo"
        [even]="even"
        (openDetailsEmitter)="openDetailsEmitter.emit($event)"
        (editEmitter)="editEmitter.emit($event)"
        (deleteEmitter)="deleteEmitter.emit($event)"
        (changeStatusEmitter)="changeStatusEmitter.emit($event)"
        (copyEmitter)="copyEmitter.emit($event)"
      ></app-immo-element>
      <div *ngIf="immos.length == 0" class="no_immos lbl_italic_light">Keine Immobilien</div>
    </div>
</div>
