import { RessourceFactory } from "../../ressource/factory/RessourceFactory";
import { Geo } from "../Geo";

export class GeoFactory {
    static jsonFactory(rawData: any): Geo[] {
        let datas: Geo[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Geo {
        return new Geo(
            rawData.postal_code,
            RessourceFactory.jsonFactoryOne(rawData.bundesland,'bundesland'),
            rawData.latitude,
            rawData.longitude,
            rawData.city,
            rawData.street,
            rawData.house_number,
        );
    }
}
