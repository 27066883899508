<div class="preloaders" *ngIf="isLoading">
    <div class="loader-wrapper loader-wrapper--10">
      <div class="loader loader--10">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      </div>
    </div>
</div>
