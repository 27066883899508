import { AttachmentFactory } from "../../attachment/factory/AttachmentFactory";
import { NutzungsartFactory } from "../../ressource/factory/NutzungsartFactory";
import { ObjektartFacotry } from "../../ressource/factory/ObjektartFactory";
import { RessourceFactory } from "../../ressource/factory/RessourceFactory";
import { VermarktungsartFactory } from "../../ressource/factory/VermarktungsartFactory";
import { Immobilie } from "../Immobilie";
import { FlaecheFactory } from "./FlaecheFactory";
import { GeoFactory } from "./GeoFactory";
import { PreiseFactory } from "./PreiseFactory";
import { ZusatzFactory } from "./ZusatzFactory";
import { ZustandImmobilieFactory } from "./ZustandFactory";

export class ImmobilieFactory {
    static jsonFactory(rawData: any): Immobilie[] {
        let datas: Immobilie[] = [];
        for(let data of rawData){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }

    static jsonFactoryOne(rawData: any): Immobilie {
      let immo =  new Immobilie(
            rawData.immobilie_id,
            rawData.user_id,
            rawData.category,
            RessourceFactory.jsonFactoryOne(rawData.vermarktungsart),
            RessourceFactory.jsonFactoryOne(rawData.objektart),
            RessourceFactory.jsonFactoryOne(rawData.nutzungsart),
            rawData.objekttitel,
            rawData.created,
            rawData.modified,
            GeoFactory.jsonFactoryOne(rawData.geo),
            PreiseFactory.jsonFactoryOne(rawData.preise),
            FlaecheFactory.jsonFactoryOne(rawData.flaechen),
            ZustandImmobilieFactory.jsonFactoryOne(rawData.zustand),
            ZusatzFactory.jsonFactoryOne(rawData.zusatz),
            RessourceFactory.jsonFactory(rawData.ausstattungen, 'ausstattung'),
            rawData.lage_beschreibung,
            rawData.ausstattung_beschreibung,
            rawData.objektbeschreibung,
            rawData.sonstige_angaben,
            rawData.objektnr,
            rawData.short_description,
            AttachmentFactory.jsonFactory(rawData.anhaenge),
      );
      if(immo.zustand.verkaufstatus?.id == 3) immo.category = 3;
      return immo;
    }
}
