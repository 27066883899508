import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-login-forgot-password-form',
  templateUrl: './login-forgot-password-form.component.html',
  styleUrls: ['./login-forgot-password-form.component.scss']
})
export class LoginForgotPasswordFormComponent implements OnInit {
  public resetForm: UntypedFormGroup;
  public submitted = false;
  public accessDenied = false;
  public requestIsSend = false;

  @Output() showLoginEmitter = new EventEmitter<undefined>();

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private lService: LoginService
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if(this.resetForm.invalid){
      return;
    } else {
      this.lService.emailResetPassword(this.resetForm.controls.email.value).subscribe((success) => {
        if(success){
          this.requestIsSend = true;
        } else {
          this.accessDenied = true;
        }
      });
    }
  }

  toLogin() {
    this.showLoginEmitter.emit();
  }
}
