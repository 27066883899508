<div id="dialog_window">
  <div id="dialog_container">
      <div class="lbl_bold">Status ändern</div>
      <div class="radio_btn_container">
        <div class="radio_container" >
          <div class="checkboxi" [ngClass]="true ? 'checkboxi_checked' : 'checkboxi_unchecked'"></div>
          <div class="lbl_regular">test</div>
        </div>
      </div>
      <div class="btn_container">
        <div class="btn_submit" style="margin-bottom: 10px;" (click)="submit()">Speichern</div>
      </div>
  </div>
</div>
