import { UntypedFormGroup } from '@angular/forms';
import { Immobilie } from './../../../buisness-object/immobilie/Immobilie';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';
import { Attachment } from 'src/app/buisness-object/attachment/Attachment';
import { AttachmentService } from 'src/app/service/attachment/attachment.service';
import { ImmoService } from 'src/app/service/immo/immo.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-immo-details',
  templateUrl: './immo-details.component.html',
  styleUrls: ['./immo-details.component.scss']
})
export class ImmoDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() immo: Immobilie;
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() isEdit: boolean;
  @Input() nutzungsarten: ImmoRessource[];
  @Input() objektarten: ImmoRessource[];
  @Input() vermarktungsarten: ImmoRessource[];
  @Input() bundeslaender: ImmoRessource[];
  @Input() bebaubarNach: ImmoRessource[];
  @Output() closeDetailsEmitter = new EventEmitter<undefined>();
  @Output() updateEmitter = new EventEmitter<undefined>();
  @Output() resetFormGroupEmitter = new EventEmitter<undefined>();
  @Output() changeToEditEmitter = new EventEmitter<undefined>();
  @Output() deleteEmitter = new EventEmitter<undefined>();


  public showObjectInfo = true;
  public showEditObjectInfo = false;
  public showEditObjectAustattung = false;
  public attachementsGroups: ImmoRessource[] = [];
  public titelBild: Attachment;
  public innenansichten: Attachment[] = [];
  public ausenansichten: Attachment[] = [];
  public grundrisse: Attachment[] = [];
  public karteUndPlaene: Attachment[] = [];
  public bilder: Attachment[] = [];
  public documents: Attachment[] = [];
  public formSubscriptionAttachments: Subscription;
  public attachementsTemp: Attachment[] = [];
  public udpatedImmo = false;

  constructor(
    private aService: AttachmentService,
    private iService: ImmoService,
  ) { }

  ngOnInit(): void {
    this.getAttachementsDataServerside();
    this.formSubscriptionAttachments = this.immoForm.get('attachments').valueChanges.subscribe((changes) => {
      this.mergeTempData();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.udpatedImmo){
      this.mergeTempData();
      this.udpatedImmo = false;
    }
  }

  ngOnDestroy(): void {
    if(this.formSubscriptionAttachments) this.formSubscriptionAttachments.unsubscribe();
  }

  mergeTempData() {
    if(this.attachementsTemp){
      for(let a of this.immoForm.controls.attachments.value){
        for(let b of this.attachementsTemp){
          if(a.anhang_id == b.anhang_id){
            a.anhang = b.anhang;
          }
        }
      }
    }
    this.orderAttachements();
  }

  async getAttachementsDataServerside() {
    for(let attachement of this.immoForm.controls.attachments.value){
      if(attachement.anhang_gruppe_id != 7 && attachement.anhang_gruppe_id != 1){
        await this.iService.getThumbnail(attachement.anhang_id).subscribe((result) => {
          if(result){
            attachement.anhang = 'data:'+ this.getDocTyp(attachement.format) +';base64,' + result.trim();
          }
        })
      } else {
        await this.aService.getAttachment(attachement.anhang_id).subscribe((result) => {
          if(result){
            attachement.anhang = 'data:'+ this.getDocTyp(attachement.format) +';base64,' + result.anhang.anhang.trim();
          }
        })
      }
    }
    this.orderAttachements();
  }
  getDocTyp(format: string): string {
    if(format == 'png') return 'image/png';
    else if(format == 'jpg') return 'image/jpeg';
    else if(format == 'docx') return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    else if(format == 'xlsx') return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    else return '';
  }

  orderAttachements() {
    this.titelBild = null;
    this.innenansichten = [];
    this.ausenansichten = [];
    this.grundrisse = [];
    this.karteUndPlaene = [];
    this.bilder = [];
    this.documents = [];
    for(let document of this.immoForm.controls.attachments.value){
      switch (document.anhang_gruppe_id) {
        case 1: this.titelBild = document; break;
        case 2: this.innenansichten.push(document); break;
        case 3: this.ausenansichten.push(document); break;
        case 4: this.grundrisse.push(document); break;
        case 5: this.karteUndPlaene.push(document); break;
        case 6: this.bilder.push(document); break;
        case 7: this.documents.push(document); break;
        default: break;
      }
    }
  }

  getThumbnails() {
    for(let attachments of this.immoForm.controls.attachments.value){
      let index = attachments.findIndex(doc => doc.anhang_gruppe_id != 7);
      if(index > -1){
        this.iService.getThumbnail(attachments[index].anhang_id).subscribe((result) => {
          if(result){
            attachments.anhang = 'data:image/png;base64,'+result.trim();
          }
        })
      }
    }
  }

  openEdit() {
    this.isEdit = true;
    this.changeToEditEmitter.emit();
  }

  saveAttachementsTemp(attachements: Attachment[]) {
    this.attachementsTemp = [];
    for(let a of attachements){
      this.attachementsTemp.push(a);
    }
  }

  async closeEdit() {
    await this.createRemovedAttachements();
    await this.deleteAddedAttachments();
    this.immoForm.controls.attachments.setValue(this.attachementsTemp);
    this.attachementsTemp = [];
    this.isEdit = false;
    this.resetFormGroupEmitter.emit();
  }

  async createRemovedAttachements() {
    for(let a of this.attachementsTemp){
      let removed = true;
      for(let b of this.immoForm.controls.attachments.value){
        if(a.anhang_id == b.anhang_id){
          removed = false;
        }
      }
      if(removed){
        await this.aService.createAttachment(a).subscribe((result) => {
          if(result){
            if(result[result.length - 1].anhang_gruppe_id != 7 && result[result.length - 1].anhang_gruppe_id != 1){
              this.iService.getThumbnail(result[result.length - 1].anhang_id).subscribe((result) => {
                if(result){
                  a.anhang = 'data:'+ this.getDocTyp(a.format) +';base64,' + result.trim();
                  this.immoForm.controls.attachments.value.push(a);
                }
              })
            } else {
              this.aService.getAttachment(result[result.length - 1].anhang_id).subscribe((result) => {
                if(result){
                  a.anhang = 'data:'+ this.getDocTyp(a.format) +';base64,' + result.data.trim();
                  this.immoForm.controls.attachments.value.push(a);
                }
              })
            }
          }
        })
      }
    }
  }

  async deleteAddedAttachments() {
    for(let a of this.immoForm.controls.attachments.value){
      let added = true;
      for(let b of this.attachementsTemp){
        if(a.anhang_id == b.anhang_id){
          added = false;
        }
      }
      if(added){
        await this.aService.deleteAttachment(a.anhang_id).subscribe((success) => {})
      }
    }
  }

  updateImmo() {
    this.attachementsTemp = this.immoForm.controls.attachments.value;
    this.udpatedImmo = true;
    this.updateEmitter.emit()
  }

  async export(data: Attachment) {
    let type = '';
    if(data.format == 'png' || data.format == 'jpeg' || data.format == 'jpg') type = 'image/';
    else if(data.format == 'docx' || data.format == 'xlsx') type = 'application/';
    const a = document.createElement('a');
    const byteCharacters = atob(data.anhang.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
       byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const filedata = new Uint8Array(byteNumbers);
    a.style.display = 'none';
    const blob = new Blob([filedata], { type: type+data.format });
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute('download', data.anhang_title + '.' + data.format);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
