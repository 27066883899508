import { KuecheFacotry } from "../../ressource/factory/KuecheFactory";
import { RessourceFactory } from "../../ressource/factory/RessourceFactory";
import { Zusatz } from "../Zusatz";

export class ZusatzFactory {
  static jsonFactory(rawData: any): Zusatz[] {
    let datas: Zusatz[] = [];
    for(let data of rawData){
        datas.push(this.jsonFactoryOne(data));
    }
    return datas;
}

static jsonFactoryOne(rawData: any): Zusatz {
    return new Zusatz(
        RessourceFactory.jsonFactoryOne(rawData.kueche, 'kueche'),
        RessourceFactory.jsonFactoryOne(rawData.heizungsart, 'heizungsart'),
        RessourceFactory.jsonFactoryOne(rawData.befeuerung, 'befeuerung'),
        RessourceFactory.jsonFactoryOne(rawData.stellplatzart, 'stellplatzart'),
        RessourceFactory.jsonFactoryOne(rawData.ausricht_balkon_terrasse, 'ausricht_balkon_terrasse'),
        RessourceFactory.jsonFactoryOne(rawData.moebliert, 'moebliert'),
        RessourceFactory.jsonFactoryOne(rawData.dachform, 'dachform'),
        RessourceFactory.jsonFactoryOne(rawData.bauweise, 'bauweise'),
        RessourceFactory.jsonFactoryOne(rawData.ausbaustufe, 'ausbaustufe'),
        RessourceFactory.jsonFactoryOne(rawData.energietyp, 'energietyp'),
        RessourceFactory.jsonFactoryOne(rawData.angeschl_gastronomie, 'angeschl_gastronomie'),
        rawData.hallenhoehe
    );
}
}
