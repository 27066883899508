<app-profile-titlebar [title]="!isEdit ? 'Profil' : 'Profile bearbeiten'"></app-profile-titlebar>
<div class="view_container">
    <div class="title_container_user">
        <div class="user_title_container">
            <div class="lbl_bold user_shortcut">{{user ? user.getInitials() : '--'}}</div>
            <div class="">
                <div class="lbl_bold data_box_title" [ngStyle]="{'margin-bottom': '0'}">{{user?.firstName}} {{user?.lastName}}</div>
                <div class="lbl_regular">{{getRolename(user)}}</div>
            </div>
        </div>
        <div *ngIf="isEdit" class="title_btn_container">
            <div class="btn_submit" (click)="updateUser()">Speichern</div>
            <div class="btn_lbl" (click)="cancelEdit()">Abbrechen</div>
        </div>
        <div *ngIf="!isEdit" class="btn_lbl" (click)="editUser()">Profil bearbeiten</div>
    </div>
    <div class="body_container_user">
        <div *ngIf="!isEdit" class="data_box_container">
            <div class="lbl_title_big_color">Persönliche Daten</div>
            <div class="lbl_regular_data">{{user?.salutation.salutation}}</div>
            <div class="lbl_regular_data">{{user?.title.title}}</div>
            <div class="lbl_regular_data" [ngStyle]="{'margin-bottom': '20px'}">{{user?.firstName}} {{user?.lastName}}</div>
            <div class="lbl_title_small_color">Kontakt</div>
            <div class="lbl_regular_data">Telefonnummer: <span class="lbl_data_color">{{user?.phone ? user.phone : '---'}}</span></div>
            <div class="lbl_regular_data" [ngStyle]="{'margin-bottom': '20px'}">E-mail : <span class="lbl_data_color">{{user?.email}}</span></div>
            <div class="btn_lbl" (click)="showPasswordDialog = true">Passwort ändern</div>
        </div>
        <div *ngIf="!isEdit" class="data_box_container">
            <div class="lbl_title_big_color">Unternehmen</div>
            <div class="lbl_regular_data">{{user?.company.companyName}}</div>
            <div class="lbl_regular_data" [ngStyle]="{'margin-bottom': '20px'}">{{user?.company.atu}}</div>
            <div class="lbl_title_small_color">Anschrift</div>
            <div class="lbl_regular_data">{{user?.company.street}}</div>
            <div class="lbl_regular_data" [ngStyle]="{'margin-bottom': '20px'}">{{user?.company.postalCode}} {{user?.company.city}}</div>
            <div class="lbl_title_small_color">Kontakt</div>
            <div class="lbl_regular_data">Webiste: <span class="lbl_data_color" style="cursor: pointer;" (click)="goToLink(user.company.url)">{{user?.company.url ? user.company.url : '---'}}</span></div>
            <div class="lbl_regular_data">Telefonnummer: <span class="lbl_data_color">{{user?.company.phone ? user.company.phone : '---'}}</span></div>
            <div class="lbl_regular_data">E-mail : <span class="lbl_data_color">{{user?.company.email}}</span></div>
        </div>
        <form *ngIf="isEdit" [formGroup]="userForm" class="data_box_container">
            <div class="lbl_bold data_box_title">Persöhnliche Daten</div>
            <div class="lbl_bold dropdown_title_lbl">Anrede</div>
            <div #dropdown_2 class="dropdown_container">
                <div class="dropdown_header" [ngClass]="showDropTwo ? 'dropdown_header_active' : ''" (click)="showDropTwo = !showDropTwo">
                    <div class="dropdown_header_lbl lbl_regular" [ngClass]="f.salutation.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.salutation.value ? f.salutation.value.salutation : 'Anrede auswählen'}}</div>
                    <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropTwo ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                </div>
                <div class="dropdown_body" [ngClass]="showDropTwo ? 'dropdown_body_open' : ''">
                    <div *ngFor="let salu of salutations" class="dropdown_body_item lbl_regular" (click)="f.salutation.setValue(salu); showDropTwo = false;">{{salu.salutation}}</div>
                </div>
            </div>
            <div class="lbl_bold dropdown_title_lbl">Titel</div>
            <div #dropdown_1 class="dropdown_container">
                <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : ''" (click)="showDropOne = !showDropOne">
                    <div class="lbl_regular dropdown_header_lbl" [ngClass]="f.title.value ? '' : 'dropdown_header_lbl_placeholder'">{{f.title.value ? f.title.value.title : 'Titel auswählen'}}</div>
                    <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../../assets/arrow.svg">
                </div>
                <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
                    <div *ngFor="let title of titles" class="dropdown_body_item lbl_regular" (click)="f.title.setValue(title); showDropOne = false;">{{title.title}}</div>
                </div>
            </div>
            <div class="flex">
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">Vorname*</div>
                    <div class="input_container" [ngClass]="submitted && f.first_name.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="Vorname" formControlName="first_name">
                    </div>
                </div>
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">Nachname*</div>
                    <div class="input_container" [ngClass]="submitted && f.last_name.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="Nachname" formControlName="last_name">
                    </div>
                </div>
            </div>
            <div class="lbl_bold dropdown_title_lbl">Telefonnummer*</div>
            <div class="input_container" [ngClass]="submitted && f.user_phone.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="Telefonnummer" formControlName="user_phone">
            </div>
            <div class="lbl_bold dropdown_title_lbl">E-Mail*</div>
            <div class="input_container" [ngClass]="submitted && f.email.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="E-Mail" formControlName="email">
            </div>
        </form>
        <div *ngIf="isEdit && user.roles[0].roleId == 1" class="data_box_container_disabled">
          <div class="overlay"></div>
          <div class="lbl_title_big_color lbl_color_disabled">Unternehmen</div>
          <div class="lbl_regular_data lbl_color_disabled">{{user?.company.companyName}}</div>
          <div class="lbl_regular_data lbl_color_disabled" [ngStyle]="{'margin-bottom': '20px'}">{{user?.company.atu}}</div>
          <div class="lbl_title_small_color lbl_color_disabled">Anschrift</div>
          <div class="lbl_regular_data lbl_color_disabled">{{user?.company.street}}</div>
          <div class="lbl_regular_data lbl_color_disabled" [ngStyle]="{'margin-bottom': '20px'}">{{user?.company.postalCode}} {{user?.company.city}}</div>
          <div class="lbl_title_small_color lbl_color_disabled">Kontakt</div>
          <div class="lbl_regular_data lbl_color_disabled">Webiste: <span class="lbl_data_color lbl_color_disabled">{{user?.company.url ? user.company.url : '---'}}</span></div>
          <div class="lbl_regular_data lbl_color_disabled">Telefonnummer: <span class="lbl_data_color lbl_color_disabled">{{user?.company.phone ? user.company.phone : '---'}}</span></div>
          <div class="lbl_regular_data lbl_color_disabled">E-mail : <span class="lbl_data_color lbl_color_disabled">{{user?.company.email}}</span></div>
      </div>
        <div *ngIf="isEdit && user.roles[0].roleId == 2" [formGroup]="userForm" class="data_box_container">
            <div class="lbl_bold data_box_title">Unternehmen</div>
            <div class="lbl_bold dropdown_title_lbl">Unternehmensname</div>
            <div class="input_container" [ngClass]="submitted && f.company_name.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="Unternehmenname eingeben..." formControlName="company_name">
            </div>
            <div class="lbl_bold dropdown_title_lbl">ATU-Nummber</div>
            <div class="input_container" [ngClass]="submitted && f.atu.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="ATU-Nummber eingeben..." formControlName="atu">
            </div>
            <div class="flex">
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">Straße</div>
                    <div class="input_container" [ngClass]="submitted && f.street.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="Straße eingeben..." formControlName="street">
                    </div>
                </div>
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">Hausnummer</div>
                    <div class="input_container" [ngClass]="submitted && f.house_number.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="Ort eingeben..." formControlName="house_number">
                    </div>
                </div>
            </div>
            <div class="flex">
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">PLZ</div>
                    <div class="input_container" [ngClass]="submitted && f.postal_code.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="PLZ eingeben..." formControlName="postal_code">
                    </div>
                </div>
                <div class="small_input_container">
                    <div class="lbl_bold dropdown_title_lbl">Ort</div>
                    <div class="input_container" [ngClass]="submitted && f.city.invalid ? 'inputfield_invalid' : ''">
                        <input type="text" placeholder="Ort eingeben..." formControlName="city">
                    </div>
                </div>
            </div>
            <div class="lbl_bold dropdown_title_lbl">Telefonnummer</div>
            <div class="input_container" [ngClass]="submitted && f.phone_company.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="Telefonnummer eingeben..." formControlName="phone_company">
            </div>
            <div class="lbl_bold dropdown_title_lbl">E-Mail</div>
            <div class="input_container" [ngClass]="submitted && f.email_company.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="E-Mail eingeben..." formControlName="email_company">
            </div>
            <div class="lbl_bold dropdown_title_lbl">URL</div>
            <div class="input_container" [ngClass]="submitted && f.url.invalid ? 'inputfield_invalid' : ''">
                <input type="text" placeholder="E-Mail eingeben..." formControlName="url">
            </div>
        </div>
    </div>
</div>
<app-dialog-password *ngIf="showPasswordDialog"
    (closeEmitter)="passwordReseted($event)"
></app-dialog-password>
