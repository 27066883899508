import { Attachment } from "../attachment/Attachment";
import { ImmoRessource } from "../ressource/ImmoRessource";
import { Flaeche } from "./Flaeche";
import { Geo } from "./Geo";
import { Preise } from "./Preise";
import { Zusatz } from "./Zusatz";
import { ZustandImmobilie } from "./ZustandImmobilie";

export class Immobilie {
    private noValue = '---';
    public thumbnail: string;
    constructor(
        public immobilieId: number,
        public userId: number,
        public category: number,
        public vermarktungsart: ImmoRessource,
        public objektart: ImmoRessource,
        public nutzungsart: ImmoRessource,
        public objektTitel: string,
        public created: number,
        public modified: number,
        public geo: Geo,
        public preise: Preise,
        public flaeche: Flaeche,
        public zustand: ZustandImmobilie,
        public zusatz: Zusatz,
        public ausstattung: ImmoRessource[],
        public lageBescreibung: string,
        public ausstattungBeschreibung: string,
        public objektbeschreibung: string,
        public sonstigeAngaben: string,
        public objektnr: string,
        public kurzBeschreibung: string,
        public attachments: Attachment[]
    ){
      if(this.vermarktungsart.id == 1){
        this.preise.preis_per_anfrage = this.preise.kaufpreis < 0 ? true : false;
      }
      else if(this.vermarktungsart.id == 2){
        this.preise.preis_per_anfrage = this.preise.monatlichekostenbrutto < 0 ? true : false;
      }
    }

    getPrice(): number {
      if(this.vermarktungsart.id == 1) return this.preise.kaufpreis;
      else if(this.vermarktungsart.id == 2) return this.preise.monatlichekostenbrutto;
      else return 0;
    }

    getJson(isUpdate?: boolean): any {
      let ausstattungen: any[] = [];
      if(this.ausstattung){
        this.ausstattung.forEach(item => {
          ausstattungen.push({
            ausstattung_id: item.id,
            ausstattung_kategorie_id: item.categorie_id
          })
        });
      }
      let body = {
        immobilie_id: isUpdate ? this.immobilieId : null,
        category: this.category,
        vermarktungsart: {
          vermarktungsart_id: this.vermarktungsart.id
        },
        objektart: {
          objektart_id: this.objektart.id
        },
        nutzungsart: {
          nutzungsart_id: this.nutzungsart.id
        },
        objekttitel: this.objektTitel,
        geo: {
          postal_code: this.geo.postalCode,
          bundesland: {
            bundesland_id: this.geo.bundesland.id
          },
          latitude: this.geo.latitude,
          longitude: this.geo.longitude,
          city: this.geo.city,
          street: this.geo.street,
          house_number: this.geo.housNumber,
        },
        preise: {
          is_qm: this.preise.isQm,
          nebenkosten: this.preise.nebenkosten,
          heizkosten: this.preise.heizkosten,
          mietzuschlaege: this.preise.mietzuschlaege,
          provisionbrutto: this.preise.provisionbrutto,
          kaution: this.preise.kaution,
          kaufpreis: this.preise.kaufpreis,
          kaufpreisbrutto: this.preise.kaufpreisbrutto,
          monatlichekostenbrutto: this.preise.monatlichekostenbrutto,
          kaufpreis_pro_qm: this.preise.kaufpreis_pro_qm,
          erschliessungskosten: this.preise.erschliessungskosten,
          mehrwertsteuer: this.preise.mehrwertsteuer,
        },
        flaechen: {
          wohnflaeche: this.flaeche.wohnflaeche,
          nutzflaeche: this.flaeche.nutzflaeche,
          gesamtflaeche: this.flaeche.gesamtflaeche,
          grundstuecksflaeche: this.flaeche.grundstuecksflaeche,
          sonstflaeche: this.flaeche.sonstflaeche,
          anzahl_zimmer: this.flaeche.anzahlZimmer,
          anzahl_schlafzimmer: this.flaeche.anzahlSchlafzimmer,
          anzahl_badezimmer: this.flaeche.anzahlBadezimmer,
          anzahl_sep_wc: this.flaeche.anzahlSep_wc,
          anzahl_balkone: this.flaeche.anzahlBalkone,
          anzahl_terrassen: this.flaeche.anzahlTerrassen,
          anzahl_logia: this.flaeche.anzahlLogia,
          balkon_terrasse_flaeche: this.flaeche.balkonTerrasseFlaeche,
          gartenflaeche: this.flaeche.gartenflaeche,
          kellerflaeche: this.flaeche.kellerflaeche,
          dachbodenflaeche: this.flaeche.dachbodenflaeche,
        },
        zustand: {
          baujahr: this.zustand.baujahr,
          zustand: {
            zustand_id: this.zustand.zustand?.id,
          },
          ist_altbau: this.zustand.istAltbau,
          bebaubar_nach: {
            bebaubar_nach_id: this.zustand.bebaubarNach?.id,
          },
          erschliessung: {
            erschliessung_id: this.zustand.erschliessung?.id,
          },
          verkaufsstatus: {
            verkaufsstatus_id: this.zustand.verkaufstatus?.id,
          },
          min_mietdauer: this.zustand.maxMietDauer,
          max_mietdauer: this.zustand.maxMietDauer,
        },
        zusatz: {
          kueche: {
            kueche_id: this.zusatz.kueche ? this.zusatz.kueche.id : null
          },
          heizungsart: {
            heizungsart_id: this.zusatz.heizungsart?.id
          },
          befeuerung: {
            befeuerung_id: this.zusatz.befeuerung?.id
          },
          stellplatzart: {
            stellplatzart_id: this.zusatz.stellplatzart?.id
          },
          ausricht_balkon_terrasse: {
            ausricht_balkon_terrasse_id: this.zusatz.ausricht_balkon_terrasse?.id
          },
          moebliert: {
            moebliert_id: this.zusatz.moebliert?.id
          },
          dachform: {
            dachform_id: this.zusatz.dachform?.id
          },
          bauweise: {
            bauweise_id: this.zusatz.bauweise?.id
          },
          ausbaustufe: {
            ausbaustufe_id: this.zusatz.ausbaustufe?.id
          },
          energietyp: {
            energietyp_id: this.zusatz.energietyp?.id
          },
          angeschl_gastronomie: {
            angeschl_gastronomie_id: this.zusatz.angeschl_gastronomie?.id
          },
          hallenhoehe: this.zusatz.hallenhoehe,
        },
        ausstattungen: ausstattungen,
        objektnr: this.objektnr,
        lage_beschreibung: this.lageBescreibung,
        ausstattung_beschreibung: this.ausstattungBeschreibung,
        objektbeschreibung: this.objektbeschreibung,
        sonstige_angaben: this.sonstigeAngaben,
        short_description: this.kurzBeschreibung
      }
      return body;
    }

    getZustandZustand(): string {
      return this.zustand.zustand ? this.zustand.zustand.name : this.noValue;
    }
    getBaujahr(): string {
      return this.zustand.baujahr ? this.zustand.baujahr : this.noValue;
    }
    getAlter(): string {
      return this.zustand.istAltbau ? (this.zustand.istAltbau == 1 ? 'Altbau' : 'Neubau') : this.noValue;
    }
    getAusrichtBalkonTerasse(): string {
      return this.zusatz.ausricht_balkon_terrasse ? (this.zusatz.ausricht_balkon_terrasse.name) : this.noValue;
    }
    getBefeuerung(): string {
      return this.zusatz.befeuerung ? (this.zusatz.befeuerung.name) : this.noValue;
    }
    getAusbaustufe(): string {
      return this.zusatz.ausbaustufe ? (this.zusatz.ausbaustufe.name) : this.noValue;
    }
    getHeizungsart(): string {
      return this.zusatz.heizungsart ? (this.zusatz.heizungsart.name) : this.noValue;
    }
    getStellplatzart(): string {
      return this.zusatz.stellplatzart ? (this.zusatz.stellplatzart.name) : this.noValue;
    }
    getBauweise(): string {
      return this.zusatz.bauweise ? (this.zusatz.bauweise.name) : this.noValue;
    }
    getDachform(): string {
      return this.zusatz.dachform ? (this.zusatz.dachform.name) : this.noValue;
    }
    getEnergietyp(): string {
      return this.zusatz.energietyp ? (this.zusatz.energietyp.name) : this.noValue;
    }

    getAusstattungByCategory(categoryId: number): ImmoRessource[] | null {
      let items = [];
      for(let item of this.ausstattung){
        if(item.categorie_id == categoryId) items.push(item);
      }
      if(items.length != 0){
        return items;
      } else {
        return null;
      }
    }
}
