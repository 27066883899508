import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { RessourceService } from 'src/app/service/ressource/ressource.service';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss']
})
export class AreasComponent implements OnInit {
  @Input() immoForm: UntypedFormGroup;
  @Input() submitted: boolean;

  constructor(
    private rService: RessourceService
  ) { }

  ngOnInit(): void {
    //this.getDataServerside();
  }

  getDataServerside() {
    forkJoin([
      this.rService.getRessource('ausrichtung_balkon_terrasse'),
      this.rService.getRessource('ausbaustufe'),
      this.rService.getRessource('bauweise'),
      this.rService.getRessource('dachform'),
      this.rService.getRessource('energietyp'),
      this.rService.getRessource('stellplatzart'),
    ]).subscribe(([one, two, three, four, five, six]:[any[], any[], any[], any[], any[], any[]]) => {
      if(one && two && three && four && five && six){
        //console.log(one);
      }
    });
  }

  get f() {
    return this.immoForm.controls;
  }
}
