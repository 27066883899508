<form id="login_form" [formGroup]="loginForm">
    <div class="lbl_bold lbl_title">Login</div>
    <div [ngStyle]="{'opacity': accessDenied ? '1' : '0'}" class="lbl_invalid">E-Mail oder Passwort falsch.</div>
    <div class="lbl_bold" [ngStyle]="{'color': submitted && loginForm.controls.email.invalid ? 'var(--invalid-color)' : ''}">E-Mail Adresse</div>
    <div class="input_container" [ngClass]="submitted && loginForm.controls.email.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/email.svg">
        <input type="text" placeholder="z.B. office@immobase.at" formControlName="email" (ngModelChange)="accessDenied = false">
    </div>
    <div class="lbl_bold" [ngStyle]="{'color': submitted && loginForm.controls.password.invalid ? 'var(--invalid-color)' : ''}">Passwort</div>
    <div class="input_container" [ngClass]="submitted && loginForm.controls.password.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/password.svg">
        <input type="{{showPassword ? 'text' : 'password'}}" placeholder="Geben Sie Ihr Passwort ein" formControlName="password" (ngModelChange)="accessDenied = false">
        <img (click)="showPassword = !showPassword" class='icon_eye' [src]="showPassword ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
    </div>
    <div class="btn_container_horizontal">
        <div class="btn_lbl" (click)="showResetEmitter.emit()">Passwort vergessen</div>
        <div class="btn_submit" (click)="onSubmit()">Einloggen</div>
    </div>
</form>
