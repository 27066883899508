import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public showPassword = false;
  public submitted = false;
  public accessDenied = false;
  public loginForm: UntypedFormGroup;

  public showDropOne = false;

  @Output() showResetEmitter = new EventEmitter<undefined>();

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor(
    private lService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.configFormGroup();
  }

  configFormGroup() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if(this.loginForm.invalid){
      return;
    }
    this.lService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value).subscribe((success) => {
      if(success){
        this.router.navigate(['immobilien']);
      } else {
        this.accessDenied = true;
      }
    });
  }
}
