import { environment } from 'src/environments/environment';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImmoRessource } from 'src/app/buisness-object/ressource/ImmoRessource';
import { Nutzungsart } from 'src/app/buisness-object/ressource/Nutzungsart';
import { Objektart } from 'src/app/buisness-object/ressource/Objektart';
import { Vermarktungsart } from 'src/app/buisness-object/ressource/Vermarktungsart';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-immo-create',
  templateUrl: './immo-create.component.html',
  styleUrls: ['./immo-create.component.scss']
})
export class ImmoCreateComponent implements OnInit, OnDestroy {
  @Input() immoForm: UntypedFormGroup;
  @Input() page: number;
  @Input() submitted: boolean;
  @Input() selectedAusstattung: ImmoRessource[];
  @Input() nutzungsarten: ImmoRessource[];
  @Input() objektarten: ImmoRessource[];
  @Input() vermarktungsarten: ImmoRessource[];
  @Input() bundeslaender: ImmoRessource[];
  @Input() bebaubarNach: ImmoRessource[];
  @Output() pageHandlingCreateEmitter = new EventEmitter<boolean>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() createEmitter = new EventEmitter<undefined>();
  @Output() saveAsDraftEmitter = new EventEmitter<undefined>();

  public isValidBasedata = false;
  public firstTouch = false;
  public formSub: Subscription;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    if(environment.developer) this.isValidBasedata = true;
    this.formSub = this.immoForm.valueChanges.subscribe(change => {
      this.isValidBasic();
      this.firstTouch = true;
    })
  }

  ngOnDestroy(): void {
    if(this.formSub) this.formSub.unsubscribe();
  }

  get f() {
    return this.immoForm.controls;
  }

  isValidBasic() {
    if(environment.developer){
      this.isValidBasedata = true;
      return;
    }
    if(this.f.objekt_titel.valid &&
      this.f.objektnr.valid &&
      this.f.nutzungsart.valid &&
      this.f.vermarktungsart.valid &&
      this.f.objektart.valid &&
      this.f.erschliessung.valid &&
      this.f.bebaubar_nach.valid &&
      this.f.bundesland.valid &&
      this.f.postal_code.valid){
        this.isValidBasedata = true;
    } else {
      this.isValidBasedata = false;
    }
  }

  paging(next: boolean) {
    this.pageHandlingCreateEmitter.emit(next);
    this.scrollToTop()
  }

  cancelCreateAction() {
    if(this.firstTouch){
      this.dService.openQuery(
        {
          title: 'Immobilie verwerfen?',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwerfen',
          typ: 'cancel_create',
          submit_value: null,
        }
      );
    } else {
      this.cancelEmitter.emit()
    }
  }

  scrollToTop() {
    if(this.scrollContainer){
      this.scrollContainer.nativeElement.scroll({behavior: 'auto', top: 0});
    }
  }
}
