import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import '@angular/common/locales/global/de';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginFormComponent } from './view/login/login-form/login-form.component';
import { LoginComponent } from './view/login/login.component';
import { LoadingScreenInterceptor } from './service/loading/LoadingInterceptor';
import { MenuComponent } from './view/menu/menu.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { LoadingComponent } from './view/loading/loading.component';
import { DialogQueryComponent } from './view/dialog/dialog-query/dialog-query.component';
import { DialogNotificationComponent } from './view/dialog/dialog-notification/dialog-notification.component';
import { LoginForgotPasswordFormComponent } from './view/login/login-forgot-password-form/login-forgot-password-form.component';
import { ImmoAdministrationComponent } from './view/immo-administration/immo-administration.component';
import { ImmoListComponent } from './view/immo-administration/immo-list/immo-list.component';
import { ImmoCreateComponent } from './view/immo-administration/immo-create/immo-create.component';
import { ImmoElementComponent } from './view/immo-administration/immo-list/immo-element/immo-element.component';
import { TitlebarComponent } from './view/titlebar/titlebar.component';
import { ProfileComponent } from './view/profile/profile.component';
import { InterfaceComponent } from './view/interface/interface.component';
import { DialogPasswordComponent } from './view/profile/dialog-password/dialog-password.component';
import { BasicComponent } from './view/immo-administration/immo-create/basic/basic.component';
import { PriceComponent } from './view/immo-administration/immo-create/price/price.component';
import { AreasComponent } from './view/immo-administration/immo-create/areas/areas.component';
import { FurnishingComponent } from './view/immo-administration/immo-create/furnishing/furnishing.component';
import { DescriptionComponent } from './view/immo-administration/immo-create/description/description.component';
import { AttachmentsComponent } from './view/immo-administration/immo-create/attachments/attachments.component';
import { ImmoDetailsComponent } from './view/immo-administration/immo-details/immo-details.component';
import { DndDirective } from './directive/dnd/dnd.directive';
import { DialogStatusComponent } from './view/immo-administration/dialog-status/dialog-status.component';
import { ImmoAdministrationTitlebarComponent } from './view/immo-administration/immo-administration-titlebar/immo-administration-titlebar.component';
import { DashboardTitlebarComponent } from './view/dashboard/dashboard-titlebar/dashboard-titlebar.component';
import { ProfileTitlebarComponent } from './view/profile/profile-titlebar/profile-titlebar.component';
import { InterfaceTitlebarComponent } from './view/interface/interface-titlebar/interface-titlebar.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    LoginForgotPasswordFormComponent,
    MenuComponent,
    DashboardComponent,
    LoadingComponent,
    DialogQueryComponent,
    DialogNotificationComponent,
    ImmoAdministrationComponent,
    ImmoListComponent,
    ImmoCreateComponent,

    ImmoElementComponent,
    TitlebarComponent,
    ProfileComponent,
    InterfaceComponent,
    DialogPasswordComponent,
    BasicComponent,
    PriceComponent,
    AreasComponent,
    FurnishingComponent,
    DescriptionComponent,
    AttachmentsComponent,
    ImmoDetailsComponent,
    DndDirective,
    DialogStatusComponent,
    ImmoAdministrationTitlebarComponent,
    DashboardTitlebarComponent,
    ProfileTitlebarComponent,
    InterfaceTitlebarComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
