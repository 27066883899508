import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Platform } from 'src/app/buisness-object/ressource/Platform';
import { User } from 'src/app/buisness-object/user/User';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { RessourceService } from 'src/app/service/ressource/ressource.service';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnInit {
  public user: User;
  public platforms: Platform[] = [];

  constructor(
    private uService: UserService,
    private rService: RessourceService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.getDataServerside();
  }

  getDataServerside() {
    forkJoin([
      this.uService.getUser(),
      this.rService.getRessource('platform'),
    ]).subscribe(([user, platforms]:[User, Platform[]]) => {
      if(user && platforms){
        this.user = user;
        this.platforms = platforms;
      }
    });
  }

  isPlatformSelected(platform: Platform): boolean {
    for(let p of this.user.platforms){
      if(platform.platformId == p.platformId){
        return true;
      }
    }
    return false;
  }

  handleSelectPlatform(platform: Platform) {
    for(let i = 0; i < this.user.platforms.length; i++){
      if(platform.platformId == this.user.platforms[i].platformId){
        this.user.platforms.splice(i, 1);
        return;
      }
    }
    this.user.platforms.push(platform);
    this.udpateUser();
  }

  udpateUser() {
    this.uService.updateUser(this.user).subscribe((user) => {
      if(user) {
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Schnittstelle aktiviert',
          success: true
        });
      }
    })
  }
}
