<form class="reset_form" [formGroup]="resetForm" *ngIf="!requestIsSend">
    <div class="lbl_bold lbl_title">Passwort vergessen</div>
    <div class="lbl_bold" [ngStyle]="{'color': submitted && resetForm.controls.email.invalid ? 'var(--invalid-color)' : ''}">E-Mail Adresse</div>
    <div class="input_container" [ngClass]="submitted && resetForm.controls.email.invalid ? 'input_container_invalid' : ''">
      <img class="icon" src="../../../../assets/email.svg">
      <input type="text" placeholder="E-Mail" formControlName="email">
    </div>
    <div class="btn_container_horizontal">
      <div class="lbl_italic btn_lbl" (click)="toLogin()">Zurück zum Login</div>
      <div class="btn_submit" (click)="onSubmit()">Passwort zurückzusetzen</div>
    </div>
</form>
<div class="reset_form" *ngIf="requestIsSend">
    <div class="lbl_bold lbl_title">Passwort vergessen</div>
    <div class="lbl_regular lbl_info" [ngStyle]="{'margin-bottom': '10%'}">Sie erhalten in Kürze eine E-Mail, mit der Sie Ihr <br> Passwort zurücksetzen können.</div>
    <div class="btn_container_horizontal" [ngStyle]="{'justify-content': 'flex-end'}">
      <div class="btn_submit" (click)="toLogin()">Zurück zum Login</div>
    </div>
</div>