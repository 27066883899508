import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  public fileOver: boolean;

  @HostBinding('class.fileover') fileover: boolean;
  @Output() fileDroppedEmitter = new EventEmitter<{name: string, data: string}>();

  constructor() { }

  //Dragover listener
  @HostListener('dragover', ['$event'])onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  //Drop listener
  @HostListener('drop', ['$event'])public onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileOver = false;
    const files = e.dataTransfer.files;
    if(files.length > 0 ){
      for(let file of files){
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileDroppedEmitter.emit({name: file.name, data: String(reader.result)});
        }
      }
    }
  }
}
